/* eslint-disable max-len */
// libraries
import React from 'react';
import {
    Container, Text, Title, Subtitle, Column,
} from './styles';

const PoliticPage: React.FC = () => (
    <Container>
        <Title>Política de Privacidade</Title>
        <Text>
            Na Idiomas Tec, privacidade e segurança são prioridades e nos comprometemos com a transparência do
            tratamento de dados pessoais dos nossos usuários/clientes. Por isso, esta presente Política de
            Privacidade estabelece como é feita a coleta, uso e transferência de informações de clientes ou outras
            pessoas que acessam ou usam nosso aplicativo.
            Ao utilizar nossos serviços, você entende que coletaremos e usaremos suas informações pessoais nas
            formas descritas nesta Política, sob as normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018),
            das disposições consumeristas da Lei Federal 8078/1990 e as demais normas do ordenamento jurídico
            brasileiro aplicáveis.
            Dessa forma, a Carlos Gomes de Oliveira, doravante denominada simplesmente como Idiomas Tec,
            inscrita no CNPJ sob o nº 41.750.051/0001-18, no papel de Controladora de Dados, obriga-se ao disposto
            na presente Política de Privacidade.
        </Text>
        <Subtitle>1. Quais dados coletamos sobre você e para qual finalidade?</Subtitle>
        <Text>
            Nosso aplicativo coleta e utiliza alguns dados pessoais seus, de forma a viabilizar a prestação de serviços
            e aprimorar a experiência de uso.
            {' '}
        </Text>
        <Subtitle>1.1. Dados pessoais fornecidos pelo titular</Subtitle>
        <Column>
            <Text>
                .Nome.
            </Text>
            <Text>
                .E-mail.
            </Text>
        </Column>
        <Subtitle>1.2. Dados pessoais coletados automaticamente</Subtitle>
        <Column>
            <Text>
                .Nome, no momento do cadastro, o nome é exigido para identificação pessoal no aplicativo.
            </Text>
            <Text>
                .E-mail, no momento do cadastro, o e-mail formecido torna-se o login para acesso ao aplicativo e
                para a recuperação de senhas perdidas/esquecidas.
            </Text>
        </Column>
        <Subtitle>2. Como coletamos os seus dados?</Subtitle>
        <Column>
            <Text>
                Nesse sentido, a coleta dos seus dados pessoais ocorre da seguinte forma:
            </Text>
            <Text>
                .Após baixar o aplicativo e realizar a instalação, o usuário fornece o nome e o e-mail no momento
                em que realiza seu cadastro, a fim de que posso começar a utilizar o aplicativo.
            </Text>
        </Column>
        <Subtitle>2.1. Consentimento</Subtitle>
        <Text>
            É a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento é a manifestação
            livre, informada e inequívoca pela qual você autoriza a Idiomas Tec a tratar seus dados.
            Assim, em consonância com a Lei Geral de Proteção de Dados, seus dados só serão coletados, tratados
            e armazenados mediante prévio e expresso consentimento.
            O seu consentimento será obtido de forma específica para cada finalidade acima descrita, evidenciando
            o compromisso de transparência e boa-fé da Idiomas Tec para com seus usuários/clientes, seguindo as
            regulações legislativas pertinentes.
            Ao utilizar os serviços da Idiomas Tec e fornecer seus dados pessoais, você está ciente e consentindo
            com as disposições desta Política de Privacidade, além de conhecer seus direitos e como exercê-los.
            A qualquer tempo e sem nenhum custo, você poderá revogar seu consentimento.
            É importante destacar que a revogação do consentimento para o tratamento dos dados pode implicar a
            impossibilidade da performance adequada de alguma funcionalidade do aplicativo que dependa da
            operação. Tais consequências serão informadas previamente.
        </Text>
        <Subtitle>3. Quais são os seus direitos?</Subtitle>
        <Column>
            <Text>
                A Idiomas Tec assegura a seus usuários/clientes seus direitos de titular previstos no artigo 18 da Lei Geral
                de Proteção de Dados. Dessa forma, você pode, de maneira gratuita e a qualquer tempo:
            </Text>
            <Text>
                .Confirmar a existência de tratamento de dados, de maneira simplificada ou em formato claro e
                completo.
            </Text>
            <Text>
                .Acessar seus dados, podendo solicitá-los em uma cópia legível sob forma impressa ou por meio
                eletrônico, seguro e idôneo.
            </Text>
            <Text>
                .Corrigir seus dados, ao solicitar a edição, correção ou atualização destes.
            </Text>
            <Text>
                .Limitar seus dados quando desnecessários, excessivos ou tratados em desconformidade com a
                legislação através da anonimização, bloqueio ou eliminação.
            </Text>
            <Text>
                .Solicitar a portabilidade de seus dados, através de um relatório de dados cadastrais que
                a Idiomas Tec trata a seu respeito.
            </Text>
            <Text>
                .Eliminar seus dados tratados a partir de seu consentimento, exceto nos casos previstos em lei.
            </Text>
            <Text>
                .Revogar seu consentimento, desautorizando o tratamento de seus dados.
            </Text>
            <Text>
                .Informar-se sobre a possibilidade de não fornecer seu consentimento e sobre as
                consequências da negativa.
            </Text>
        </Column>
        <Subtitle>4. Como você pode exercer seus direitos de titular?</Subtitle>
        <Column>
            <Text>
                Para exercer seus direitos de titular, você deve entrar em contato com a Idiomas Tec através dos
                seguintes meios disponíveis:
            </Text>
            <Text>
                .Link de comentários dentro da própria Loja da AppleStore
            </Text>
            <Text>
                .E-mail da Idiomas Tec
            </Text>
            <Text>
                De forma a garantir a sua correta identificação como titular dos dados pessoais objeto da solicitação, é
                possível que solicitemos documentos ou demais comprovações que possam comprovar sua identidade.
                Nessa hipótese, você será informado previamente.
            </Text>
        </Column>
        <Subtitle>
            5. Como e por quanto tempo seus dados serão armazenados?
        </Subtitle>
        <Column>
            <Text>
                Seus dados pessoais coletados pela Idiomas Tec serão utilizados e armazenados durante o tempo
                necessário para a prestação do serviço ou para que as finalidades elencadas na presente Política de
                Privacidade sejam atingidas, considerando os direitos dos titulares dos dados e dos controladores.
                De modo geral, seus dados serão mantidos enquanto a relação contratual entre você e a Idiomas
                Tec perdurar. Findado o período de armazenamento dos dados pessoais, estes serão excluídos de
                nossas bases de dados ou anonimizados, ressalvadas as hipóteses legalmente previstas no artigo 16 lei
                geral de proteção de dados, a saber:
            </Text>
            <Text>
                I – cumprimento de obrigação legal ou regulatória pelo controlador;
            </Text>
            <Text>
                II – estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;
            </Text>
            <Text>
                III – transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta
                Lei;
            </Text>
            <Text>
                IV – uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.
                Isto é, informações pessoais sobre você que sejam imprescindíveis para o cumprimento de
                determinações legais, judiciais e administrativas e/ou para o exercício do direito de defesa em processos
                judiciais e administrativos serão mantidas, a despeito da exclusão dos demais dados.
            </Text>
            <Text>
                O armazenamento de dados coletados pela Idiomas Tec reflete o nosso compromisso com a segurança
                e privacidade dos seus dados. Empregamos medidas e soluções técnicas de proteção aptas a garantir a
                confidencialidade, integridade e inviolabilidade dos seus dados. Além disso, também contamos com
                medidas de segurança apropriadas aos riscos e com controle de acesso às informações armazenadas.
            </Text>
        </Column>
        <Subtitle>
            6. O que fazemos para manter seus dados seguros?
        </Subtitle>
        <Column>
            <Text>
                Para mantermos suas informações pessoais seguras, usamos ferramentas físicas, eletrônicas e
                gerenciais orientadas para a proteção da sua privacidade.
                Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais coletados, o
                contexto e a finalidade do tratamento e os riscos que eventuais violações gerariam para os direitos e
                liberdades do titular dos dados coletados e tratados.
                Entre as medidas que adotamos, destacamos as seguintes:
            </Text>
            <Text>
                .apenas pessoas autorizadas terão acesso a seus dados pessoais;
            </Text>
            <Text>
                .o acesso a seus dados pessoais será feito somente após firmado o compromisso de
                confidencialidade;
            </Text>
            <Text>
                .seus dados pessoais serão armazenados em ambiente seguro e idôneo.
            </Text>
            <Text>
                A Idiomas Tec se compromete a adotar as melhores posturas para evitar incidentes de segurança.
                Contudo, é necessário destacar que nenhuma página virtual é inteiramente segura e livre de riscos. É
                possível que, apesar de todos os nossos protocolos de segurança, problemas de culpa exclusivamente
                de terceiros ocorram, como ataques cibernéticos de hackers, ou também em decorrência da negligência
                ou imprudência do próprio usuário/cliente.
                Em caso de incidentes de segurança que possam gerar risco ou dano relevante para você ou qualquer
                um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de
                Dados sobre o ocorrido, em consonância com as disposições da Lei Geral de Proteção de Dados.
            </Text>
        </Column>
        <Subtitle>
            7. Com quem seus dados podem ser compartilhados?
        </Subtitle>
        <Column>
            <Text>
                Tendo em vista a preservação de sua privacidade, a Idiomas Tec não compartilhará seus dados pessoais
                com nenhum terceiro não autorizado. Neste momento, a Idiomas Tec não conta com nenhum parceiro
                comercial.
            </Text>
            <Text>Além disso, também existem outras hipóteses em que seus dados poderão ser compartilhados, que são:</Text>
            <Text>
                I – Determinação legal, requerimento, requisição ou ordem judicial, com autoridades judiciais,
                administrativas ou governamentais competentes

            </Text>
            <Text>II – Caso de movimentações societárias, como fusão, aquisição e incorporação, de forma automática </Text>
            <Text>III – Proteção dos direitos da a Idiomas Tec em qualquer tipo de conflito, inclusive os de teor judicial. </Text>
        </Column>
        <Subtitle>
            7.1. Transferência internacional de dados
        </Subtitle>
        <Text>
            Alguns dos terceiros com quem compartilhamos seus dados podem ser localizados ou possuir instalações
            localizadas em países estrangeiros. Nessas condições, de toda forma, seus dados pessoais estarão
            sujeitos à Lei Geral de Proteção de Dados e às demais legislações brasileiras de proteção de dados
            Nesse sentido, a Idiomas Tec se compromete a sempre adotar eficientes padrões de segurança
            cibernética e de proteção de dados, nos melhores esforços de garantir e cumprir as exigências
            legislativas.
            Ao concordar com essa Política de Privacidade, você concorda com esse compartilhamento, que se dará
            conforme as finalidades descritas no presente instrumento.
        </Text>
        <Subtitle>
            8. Cookies ou dados de navegação
        </Subtitle>
        <Text>
            A Idiomas Tec não faz uso de Cookies, que são arquivos de texto enviados pela plataforma ao seu
            computador e que nele se armazenam, que contém informações relacionadas à navegação do site.
        </Text>
        <Subtitle>
            9. Alteração desta Política de Privacidade
        </Subtitle>
        <Text>
            A atual versão da Política de Privacidade foi formulada e atualizada pela última vez em: 01 de novembro
            de 2021.
            Reservamos o direito de modificar essa Política de Privacidade a qualquer tempo, principalmente em
            função da adequação a eventuais alterações feitas em nosso aplicativo ou em âmbito legislativo.
            Recomendamos que você a revise com frequência.
            Eventuais alterações entrarão em vigor a partir de sua publicação nas lojas em que o aplicativo esteja
            disponível e sempre lhe notificaremos acerca das mudanças ocorridas.
            Ao utilizar nossos serviços e fornecer seus dados pessoais após tais modificações, você as consente
        </Text>
        <Subtitle>
            10. Responsabilidade
        </Subtitle>
        <Text>
            A Idiomas Tec prevê a responsabilidade dos agentes que atuam nos processos de tratamento de dados,
            em conformidade com os artigos 42 ao 45 da Lei Geral de Proteção de Dados.
            Nos comprometemos em manter esta Política de Privacidade atualizada, observando suas disposições e
            zelando por seu cumprimento.
            Além disso, também assumimos o compromisso de buscar condições técnicas e organizativas
            seguramente aptas a proteger todo o processo de tratamento de dados.
            Caso a Autoridade Nacional de Proteção de Dados exija a adoção de providências em relação ao
            tratamento de dados realizado pela Idiomas Tec, comprometemo-nos a segui-las.
        </Text>
        <Subtitle>
            10.1 Isenção de responsabilidade
        </Subtitle>
        <Column>
            <Text>
                Conforme mencionado no Tópico 6, embora adotemos elevados padrões de segurança a fim de evitar
                incidentes, não há nenhuma página virtual inteiramente livre de riscos. Nesse sentido, a Idiomas Tec não
                se responsabiliza por:
            </Text>
            <Text>
                I – Quaisquer consequências decorrentes da negligência, imprudência ou imperícia dos usuários em
                relação a seus dados individuais. Garantimos e nos responsabilizamos apenas pela segurança dos
                processos de tratamento de dados e do cumprimento das finalidades descritas no presente instrumento.
                Destacamos que a responsabilidade em relação à confidencialidade dos dados de acesso é do usuário.
            </Text>
            <Text>
                II – Ações maliciosas de terceiros, como ataques de hackers, exceto se comprovada conduta culposa ou
                deliberada da Idiomas Tec. Destacamos que em caso de incidentes de segurança que possam gerar risco
                ou dano relevante para você ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados
                e a Autoridade Nacional de Proteção de Dados sobre o ocorrido e cumpriremos as providências
                necessárias.
            </Text>
            <Text>
                III – Inveracidade das informações inseridas pelo usuário/cliente nos registros necessários para a
                utilização dos serviços da Idiomas Tec; quaisquer consequências decorrentes de informações falsas ou
                inseridas de má-fé são de inteiramente responsabilidade do usuário/cliente.
            </Text>
        </Column>
        <Subtitle>
            11. Encarregado de Proteção de Dados
        </Subtitle>
        <Column>
            <Text>
                A Idiomas Tec disponibiliza o seguinte meio para que você possa entrar em contato conosco para exercer
                seus direitos de titular: link de comentários dentro da própria Loja da AppleStore e e-mail da Idiomas Tec.
                Caso tenha dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, você
                pode entrar em contato com o nosso Encarregado de Proteção de Dados Pessoais, através dos seguintes
                canais:
            </Text>
            <Text>
                .Nome: Carlos Gomes de Oliveira - CPF 256.661.058-41
            </Text>
            <Text>
                .E-mail: englishforprogrammers@gmail.com
            </Text>
        </Column>
    </Container>
);

export default PoliticPage;
