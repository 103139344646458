const colors = {
    basic: {
        black: '#000000',
        blue: '#0000ff',
        gray: '#808080',
        white: '#ffffff',
    },
    theme: {
        blue: '#1c8ef8',
        red: '#B90404',
        purpleDark: '#6100f2',
        purpleLight: '#ede0fc',
        smoke: '#dadfe3',
    },
};

export default colors;
