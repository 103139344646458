/* eslint-disable max-len */
/* eslint-disable no-plusplus */
// libraries
import React from 'react';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { CheckCircle, NavigateBefore, Send } from '@material-ui/icons';

// js
import defaultSound from '../../../assets/images/defaultSound.png';
import {
    ContainerAdjustIconEdit,
    ContainerOptions,
    InputNullSelectedPhoto,
    Logo,
    TitleOption,
} from '../modulesStyles';

// interfaces
import type { OrderWordCompletedPhraseInterface, DataModuleInterface } from '../modulesInterfaces';
import uploads from '../../../services/uploads';
import tasks from '../../../services/tasks';

const TaskOrderWordCompletedPhrase = (
    setIdModal: any,
    initialDataTaskOrderWordCompletedPhrase: OrderWordCompletedPhraseInterface,
    taskOrderWordCompletedPhrase: OrderWordCompletedPhraseInterface,
    setTaskOrderWordCompletedPhrase: any,
    dataModule: DataModuleInterface,
) => {
    // constants
    const token: any = String(localStorage.getItem('token') || '');
    const adjustMarginBottom = { marginBottom: 10 };
    const adjustButtonLogo = { marginBottom: 25, width: 200 };
    const adjustWidthSelectedActivities = { width: 200 };

    // methods
    const BackScreenModal = () => {
        setIdModal(1);
        setTaskOrderWordCompletedPhrase(initialDataTaskOrderWordCompletedPhrase);
    };

    const CreateTask = async () => {
        const formatTask: any = {
            ...taskOrderWordCompletedPhrase,
            idTask: taskOrderWordCompletedPhrase.id,
            idModule: dataModule?.id,
        };
        delete formatTask?.id;
        if (formatTask?.idTask) {
            const editTask: any = await tasks.EditTask(formatTask, token);
            if (editTask) {
                BackScreenModal();
            }
        } else {
            delete formatTask?.idTask;
            const createTask: any = await tasks.CreateTask(formatTask, token);
            if (createTask) {
                BackScreenModal();
            }
        }
    };

    const onChangeTitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskOrderWordCompletedPhrase, title: txt.target.value };
            setTaskOrderWordCompletedPhrase(newDataTask);
        }
    };

    const onChangeSubtitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskOrderWordCompletedPhrase, subtitle: txt.target.value };
            setTaskOrderWordCompletedPhrase(newDataTask);
        }
    };

    const onChangePhraseInitial = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskOrderWordCompletedPhrase, phraseInitial: txt.target.value };
            setTaskOrderWordCompletedPhrase(newDataTask);
        }
    };

    const onChangePhraseFinal = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskOrderWordCompletedPhrase, phraseFinal: txt.target.value };
            setTaskOrderWordCompletedPhrase(newDataTask);
        }
    };

    const onChangeAnswer = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskOrderWordCompletedPhrase, answer: txt.target.value };
            setTaskOrderWordCompletedPhrase(newDataTask);
        }
    };

    const onChangeOptionSkipTask = (txt: any) => {
        const newDataTask = { ...taskOrderWordCompletedPhrase, skip: txt.target.value };
        setTaskOrderWordCompletedPhrase(newDataTask);
    };

    const validationDisabled = () => {
        let valueObject = true;
        if (taskOrderWordCompletedPhrase?.id) {
            const ValidationLengthSelect = Object.values(taskOrderWordCompletedPhrase).filter((v) => v).length !== 9;
            if (!ValidationLengthSelect) {
                valueObject = false;
            }
        } else if (!taskOrderWordCompletedPhrase?.id) {
            const ValidationLengthSelect = Object.values(taskOrderWordCompletedPhrase).filter((v) => v !== '').length !== 8;
            if (!ValidationLengthSelect) {
                valueObject = false;
            }
        }
        return valueObject;
    };

    const validationBackground = () => {
        const valueObject = Object.values(taskOrderWordCompletedPhrase).filter((v) => v !== '');
        if (
            taskOrderWordCompletedPhrase?.id ? valueObject.length === 9 : valueObject.length === 8
        ) {
            return 'primary';
        }

        return 'inherit';
    };

    // renders
    const RenderSelectedCorrectOptions = (skipTask: boolean) => {
        if (!skipTask) {
            const SelectedAudio = async (event: any) => {
                if (event.target.files.length) {
                    const newDataFiles = {
                        eventFile: event.target.files[0],
                        eventName: event.target.files[0].name,
                        file: URL.createObjectURL(event.target.files[0]),
                    };
                    const upload: any = await uploads.UploadFile(newDataFiles.eventFile);
                    if (upload) {
                        setTaskOrderWordCompletedPhrase({ ...taskOrderWordCompletedPhrase, file: upload.result });
                        // eslint-disable-next-line no-param-reassign
                        event.target.value = null;
                    }
                }
            };

            return (
                <>
                    <Button
                        style={adjustButtonLogo}
                        title={taskOrderWordCompletedPhrase?.file}
                    >
                        <Logo src={defaultSound} />
                        {taskOrderWordCompletedPhrase?.file ? (
                            <ContainerAdjustIconEdit right="40px" style={adjustMarginBottom}>
                                <CheckCircle color="inherit" />
                            </ContainerAdjustIconEdit>
                        ) : null}
                        <InputNullSelectedPhoto
                            accept=".mp3,audio/*"
                            onChange={SelectedAudio}
                            type="file"
                        />
                    </Button>
                    <FormControl style={adjustWidthSelectedActivities}>
                        <InputLabel>Pular/Não pular</InputLabel>
                        <Select
                            value={taskOrderWordCompletedPhrase.skip}
                            onChange={onChangeOptionSkipTask}
                        >
                            <MenuItem value="true">Pular</MenuItem>
                            <MenuItem value="false">Não pular</MenuItem>
                        </Select>
                    </FormControl>
                </>
            );
        }

        return null;
    };

    const RenderInputsTitles = (label: string, value: string, onChange: any) => {
        const ValueActual = value;
        return (
            <>
                <ContainerOptions marginTop="10px" />
                <TextField
                    fullWidth
                    label={`${label} (${ValueActual.length}/200)`}
                    onChange={onChange}
                    value={value}
                />
            </>
        );
    };

    const EndIcon = () => (
        <Send />
    );

    const RenderIconGoBack = () => (
        <NavigateBefore />
    );

    return (
        <>
            <ContainerOptions>
                <TitleOption width="100%">Criação da Atividade - Ordene as palavras para preencher a lacuna</TitleOption>
            </ContainerOptions>
            {RenderInputsTitles('Título da atividade', taskOrderWordCompletedPhrase.title, onChangeTitle)}
            {RenderInputsTitles('Subtítulo da atividade', taskOrderWordCompletedPhrase.subtitle, onChangeSubtitle)}
            <ContainerOptions marginTop="10px">
                <TextField
                    style={{ width: '49%' }}
                    label={`Ex.: Quem é você? (${taskOrderWordCompletedPhrase.phraseInitial.length}/200)`}
                    onChange={onChangePhraseInitial}
                    value={taskOrderWordCompletedPhrase.phraseInitial}
                />
                <TextField
                    style={{ width: '49%' }}
                    label={`Ex.: Who _LACUNA_ you? (${taskOrderWordCompletedPhrase.phraseFinal.length}/200)`}
                    onChange={onChangePhraseFinal}
                    value={taskOrderWordCompletedPhrase.phraseFinal}
                />
            </ContainerOptions>
            <TextField
                style={{ width: '49%' }}
                label={`Ex.: _LACUNA_ (${taskOrderWordCompletedPhrase.answer.length}/200)`}
                onChange={onChangeAnswer}
                value={taskOrderWordCompletedPhrase.answer}
            />
            <ContainerOptions marginTop="10px" width="50%">
                {RenderSelectedCorrectOptions(false)}
            </ContainerOptions>
            <ContainerOptions marginTop="40px">
                <Button
                    color="primary"
                    startIcon={RenderIconGoBack()}
                    onClick={BackScreenModal}
                    variant="contained"
                >
                    Voltar
                </Button>
                <Button
                    color={validationBackground()}
                    disabled={validationDisabled()}
                    endIcon={EndIcon()}
                    onClick={CreateTask}
                    variant="contained"
                >
                    {taskOrderWordCompletedPhrase?.id ? 'Editar Atividade' : 'Criar Atividade'}
                </Button>
            </ContainerOptions>
        </>
    );
};

export default TaskOrderWordCompletedPhrase;
