/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-plusplus */
// libraries
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { MenuItem, Select, TextField } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { lightFormat } from 'date-fns';

// js
import {
    Container,
    ContainerBox,
    ContainerMore,
    ContainerOptions,
    InfoOption,
    LineSeparator,
    TitleBox,
    TitleOption,
} from './historicStyles';

// components
import ModalLoading from '../../components/ModalLoading';

// components (navigation)
import DrawerNavigation from '../../routes/DrawerNavigation/DrawerNavigation';

// types
import { ListHistories, ReverseCondition } from '../../services/histories';

// interfaces
const HistoricPage: React.FC = () => {
    // constants
    const token: any = String(localStorage.getItem('token') || '');

    // states
    const [allHistoric, setAllHistoric] = useState([]);
    const [search, setSearch] = useState('');
    const [activeLoading, setActiveLoading] = useState(false);

    // api
    const GetListHistoric = async () => {
        setActiveLoading(true);
        const listHistoric: any = await ListHistories(search, token);
        if (listHistoric?.status === 200) {
            setAllHistoric(listHistoric.result.list);
        }
        setActiveLoading(false);
    };

    // methods
    const reverterCondition = async (id: string, type: string) => {
        setActiveLoading(true);
        const listHistoric: any = await ReverseCondition(id, type, token);
        if (listHistoric?.status === 200) {
            GetListHistoric();
        }
        setActiveLoading(false);
    };

    function AdjustTypes(action: string) {
        if (action === 'create') {
            return 'Criação';
        }
        if (action === 'edit') {
            return 'Edição';
        }
        if (action === 'update') {
            return 'Atualização';
        }
        if (action === 'delete') {
            return 'Exclusão';
        }
    }

    function AdjustNextTypes(action: string) {
        if (action === 'create') {
            return 'Exclusão';
        }
        if (action === 'edit') {
            return 'Edição';
        }
        if (action === 'update') {
            return 'Atualização';
        }
        if (action === 'delete') {
            return 'Criação';
        }
    }

    const SearchHistoric = (txt: any) => {
        const infoSearch = txt.target.value;
        setSearch(infoSearch);
    };

    // useEffect
    useEffect(() => {
        GetListHistoric();
    }, []);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            GetListHistoric();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [search]);

    // renders
    const RenderListItems = (item: any) => {
        const {
            name, lastMethod, currentMethod, type, path, dateCreated,
        } = item;

        const dateInt = Number(dateCreated);
        const date = new Date(dateInt);
        const formatDate = lightFormat(date, 'dd/MM/yyyy');
        const formatHour = lightFormat(date, 'HH:mm');
        const concatDate = `${formatDate} às ${formatHour}`;

        const handleChange = async (event: any) => {
            const { id } = event.target.value;
            reverterCondition(id, type);
        };

        return (
            <>
                <ContainerOptions>
                    <InfoOption>{name}</InfoOption>
                    <InfoOption>
                        {path === '/users' ? 'Usuário' : 'Módulo'}
                    </InfoOption>
                    <InfoOption>{AdjustTypes(lastMethod)}</InfoOption>
                    <InfoOption>{AdjustNextTypes(currentMethod)}</InfoOption>
                    <ContainerMore>
                        <InfoOption width="100%">{concatDate}</InfoOption>
                        <Select value="" onChange={handleChange}>
                            <MenuItem value={{ ...item }}>
                                Reverter modificação
                            </MenuItem>
                        </Select>
                    </ContainerMore>
                </ContainerOptions>
                <LineSeparator />
            </>
        );
    };

    const RenderHistoricPage = () => {
        if (!localStorage.getItem('existUser')) {
            return <Redirect to="/" />;
        }
        return (
            <Container>
                <ModalLoading activeLoading={activeLoading} />
                <DrawerNavigation />
                <ContainerBox>
                    <TextField
                        fullWidth
                        label="Buscar por nome do item modificado"
                        onChange={SearchHistoric}
                        value={search}
                    />
                    <TitleBox>Lista de histórico</TitleBox>
                    <ContainerOptions>
                        <TitleOption>Título/Nome</TitleOption>
                        <TitleOption>Tipo</TitleOption>
                        <TitleOption>Ação anterior</TitleOption>
                        <TitleOption>Ação posterior</TitleOption>
                        <TitleOption>Data da modificação</TitleOption>
                    </ContainerOptions>
                    <LineSeparator />
                    <FlatList list={allHistoric} renderItem={RenderListItems} />
                </ContainerBox>
            </Container>
        );
    };

    return RenderHistoricPage();
};

export default HistoricPage;
