// libraries
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Redirect } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { getTime, setHours } from 'date-fns';
import { Search } from '@material-ui/icons';
import { Fab } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// js
import {
    Container,
    ContainerBox,
    ContainerOptions,
    InfoOption,
    LineSeparator,
    TitleBox,
    TitleOption,
    ContainerMore,
    ContainerDatePicker,
    Header,
} from './financeStyles';

// components (navigation)
import DrawerNavigation from '../../routes/DrawerNavigation/DrawerNavigation';
import functions from '../../utils/functions';

// types
import colors from '../../styles/colors';
import finance from '../../services/finance';

const FinancePage: React.FC = () => {
    // constants
    const token: any = String(localStorage.getItem('token') || '');

    // states
    const [allFinance, setAllFinance] = useState<Array<any>>([]);
    const [initialDate, setInitialDate] = useState(new Date(1609524128000));
    const [finalDate, setFinalDate] = useState(new Date());

    const GetAllStatistics = async () => {
        const listFinance: any = await finance.ListFinance(null, null, token);
        if (listFinance) {
            setAllFinance(listFinance?.result?.list || []);
        }
    };

    const onChangeInitialDate = (date: Date | null) => {
        setInitialDate(date || new Date());
    };

    const onChangeFinalDate = (date: Date | null) => {
        setFinalDate(date || new Date());
    };

    const ActionSearch = async () => {
        const formatInitial = getTime(setHours(initialDate, 1));
        const formatFinal = getTime(setHours(finalDate, 1));
        const listFinance: any = await finance.ListFinance(
            formatInitial,
            formatFinal,
            token,
        );
        if (listFinance) {
            setAllFinance(listFinance?.result?.list || []);
        }
    };

    // useEffect
    useEffect(() => {
        GetAllStatistics();
    }, []);

    // renders
    const RenderListItems = (item: any) => {
        const {
            title,
            currentSubscriptions,
            canceledSubscriptions,
            subscriptionTotals,
            totalBilling,
        } = item;
        return (
            <>
                <ContainerOptions>
                    <InfoOption>{title}</InfoOption>
                    <InfoOption>{currentSubscriptions}</InfoOption>
                    <InfoOption>{canceledSubscriptions}</InfoOption>
                    <InfoOption>{subscriptionTotals}</InfoOption>
                    <InfoOption>{`R$ ${functions.toPrice(totalBilling, 2)}`}</InfoOption>
                </ContainerOptions>
                <LineSeparator />
            </>
        );
    };

    const RenderUsersPage = () => {
        if (!localStorage.getItem('existUser')) {
            return <Redirect to="/" />;
        }
        const adjustStyleFab = { marginLeft: 20 };
        return (
            <Container>
                <DrawerNavigation />
                <ContainerBox>
                    <ContainerMore>
                        <ContainerDatePicker>
                            <Header fontWeight="bold" color={colors.basic.gray}>Início</Header>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    value={initialDate}
                                    onChange={onChangeInitialDate}
                                    maxDate={new Date()}
                                    invalidDateMessage="Data inválida!"
                                    autoOk
                                />
                            </MuiPickersUtilsProvider>
                        </ContainerDatePicker>
                        <ContainerDatePicker>
                            <Header fontWeight="bold" color={colors.basic.gray}>Fim</Header>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    value={finalDate}
                                    onChange={onChangeFinalDate}
                                    maxDate={new Date()}
                                    invalidDateMessage="Data inválida!"
                                    autoOk
                                />
                            </MuiPickersUtilsProvider>
                        </ContainerDatePicker>
                        <Fab
                            variant="extended"
                            color="primary"
                            onClick={ActionSearch}
                            style={adjustStyleFab}
                        >
                            <Search />
                            Pesquisar
                        </Fab>
                    </ContainerMore>
                    <TitleBox>Lista de assinaturas</TitleBox>
                    <ContainerOptions>
                        <TitleOption>Tipo de assinatura</TitleOption>
                        <TitleOption>Assinantes atuais</TitleOption>
                        <TitleOption>Assinaturas canceladas</TitleOption>
                        <TitleOption>Assinaturas totais</TitleOption>
                        <TitleOption>Faturamento total</TitleOption>
                    </ContainerOptions>
                    <LineSeparator />
                    <FlatList list={allFinance} renderItem={RenderListItems} />
                </ContainerBox>
            </Container>
        );
    };

    return RenderUsersPage();
};

export default FinancePage;
