import styled from 'styled-components';
import colors from '../../../../../../styles/colors';

export const TitleOption = styled.h1`
    color: ${colors.basic.black};
    font-size: 17px;
`;

export const Form = styled.div`
    margin-top: 20px;
`;

export const InputAudio = styled.input`
    display: none;
`;

export const Label = styled.label`
    padding: 20px 10px;
    width: 200px;
    background-color: #333;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    display: block;
    cursor: pointer;
    border-radius: 8px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;

export const ContainerButtonAudio = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: center;
`;

export const ButtonPlayer = styled.button`
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #333;
    color: #fff;
    text-transform: uppercase;
    display: block;
    cursor: pointer;
    margin-left: 10px;
`;
