// js
import colors from '../../styles/colors';

// functions
const ValidationBackgroundColorButton = (index: number): string => {
    if (document.URL.includes('/category') && index === 0) {
        return `${colors.theme.purpleLight}`;
    }
    if (document.URL.includes('/modules') && index === 1) {
        return `${colors.theme.purpleLight}`;
    }
    if (document.URL.includes('/users') && index === 2) {
        return `${colors.theme.purpleLight}`;
    }
    if (document.URL.includes('/plans') && index === 3) {
        return `${colors.theme.purpleLight}`;
    }
    if (document.URL.includes('/finance') && index === 4) {
        return `${colors.theme.purpleLight}`;
    }
    if (document.URL.includes('/historic') && index === 5) {
        return `${colors.theme.purpleLight}`;
    }
    if (document.URL.includes('/functionalities') && index === 6) {
        return `${colors.theme.purpleLight}`;
    }
    if (document.URL.includes('/emails') && index === 7) {
        return `${colors.theme.purpleLight}`;
    }

    return `${colors.basic.white}`;
};

const ValidationColorIcon = (index: number): string => {
    if (document.URL.includes('/category') && index === 0) {
        return `${colors.theme.purpleDark}`;
    }
    if (document.URL.includes('/modules') && index === 1) {
        return `${colors.theme.purpleDark}`;
    }
    if (document.URL.includes('/users') && index === 2) {
        return `${colors.theme.purpleDark}`;
    }
    if (document.URL.includes('/plans') && index === 3) {
        return `${colors.theme.purpleDark}`;
    }
    if (document.URL.includes('/finance') && index === 4) {
        return `${colors.theme.purpleDark}`;
    }
    if (document.URL.includes('/historic') && index === 5) {
        return `${colors.theme.purpleDark}`;
    }
    if (document.URL.includes('/functionalities') && index === 6) {
        return `${colors.theme.purpleDark}`;
    }
    if (document.URL.includes('/emails') && index === 7) {
        return `${colors.theme.purpleDark}`;
    }
    return `${colors.basic.gray}`;
};

const ValidationColorText = (index: number): string => {
    if (document.URL.includes('/category') && index === 0) {
        return `${colors.theme.purpleDark}`;
    }
    if (document.URL.includes('/modules') && index === 1) {
        return `${colors.theme.purpleDark}`;
    }
    if (document.URL.includes('/users') && index === 2) {
        return `${colors.theme.purpleDark}`;
    }
    if (document.URL.includes('/plans') && index === 3) {
        return `${colors.theme.purpleDark}`;
    }
    if (document.URL.includes('/finance') && index === 4) {
        return `${colors.theme.purpleDark}`;
    }
    if (document.URL.includes('/historic') && index === 5) {
        return `${colors.theme.purpleDark}`;
    }
    if (document.URL.includes('/functionalities') && index === 6) {
        return `${colors.theme.purpleDark}`;
    }
    if (document.URL.includes('/emails') && index === 7) {
        return `${colors.theme.purpleDark}`;
    }
    return `${colors.basic.black}`;
};

export {
    ValidationBackgroundColorButton,
    ValidationColorIcon,
    ValidationColorText,
};
