const isStaging = window.location.hostname.includes('staging');
const isBeta = window.location.hostname.includes('beta.adm.idiomastec.com');
const isProd = window.location.hostname.includes('adm.idiomastec');
// const getBaseUrl = () => {
//     if (isBeta) {
//         return 'https://beta.api.idiomastec.com';
//     }
//     if (isProd) {
//         return 'https://api.idiomastec.com';
//     }
//     if (isStaging) {
//         return 'https://staging.idiomastecapi.dotcoding.com.br';
//     }
//     return 'https://development.idiomastecapi.dotcoding.com.br';
// };
const getNameEnvironment = () => {
    if (isBeta) {
        return 'BETA';
    }
    if (isProd) {
        return '';
    }
    if (isStaging) {
        return 'STG';
    }
    return 'DEV';
};

const namesSystem = ['ipsis', 'mic', 'gregoBiblico', 'biblicalGreek', 'e4b', 'e4l', 'iMed', 'iNutri', 'e4keys', 'inglestec', 'e4ce', 'hebraicodabiblia', 'biblicalhebrew', 'griegobiblico', 'hebreodelabiblia'];

const pickerNamesSystem = [
    {
        id: 'ipsis',
        name: 'Ipsis',
    },
    {
        id: 'mic',
        name: 'MIC',
    },
    {
        id: 'gregoBiblico',
        name: 'Grego Bíblico',
    },
    {
        id: 'biblicalGreek',
        name: 'Biblical Greek',
    },
    {
        id: 'e4b',
        name: 'E4B',
    },
    {
        id: 'e4l',
        name: 'E4L',
    },
    {
        id: 'iMed',
        name: 'E4MED',
    },
    {
        id: 'iNutri',
        name: 'E4Nutrition',
    },
    {
        id: 'e4keys',
        name: 'E4Keys',
    },
    {
        id: 'inglestec',
        name: 'InglesTec',
    },
    {
        id: 'e4ce',
        name: 'E4CE',
    },
    {
        id: 'hebraicodabiblia',
        name: 'Hebraico da Bíblia',
    },
    {
        id: 'biblicalhebrew',
        name: 'Biblical Hebrew',
    },
    {
        id: 'griegobiblico',
        name: 'Griego Bíblico',
    },
    {
        id: 'hebreodelabiblia',
        name: 'Hebreo de la biblia',
    },
];
const baseUrl = 'https://api.idiomastec.com';
const nameEnvironment = getNameEnvironment();
export default {
    baseUrl,
    nameEnvironment,
    namesSystem,
    pickerNamesSystem,
};
