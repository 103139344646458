/* eslint-disable max-len */
/* eslint-disable no-plusplus */
// libraries
import React from 'react';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { CheckCircle, NavigateBefore, Send } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';

// js
import defaultSound from '../../../assets/images/defaultSound.png';
import { allMenuItems } from './taskConstants';
import {
    ContainerAdjustIconEdit,
    ContainerOptions,
    InputNullSelectedPhoto,
    Logo,
    TitleOption,
} from '../modulesStyles';

// interfaces
import type { DataModuleInterface, SelectedWordInterface } from '../modulesInterfaces';
import uploads from '../../../services/uploads';
import tasks from '../../../services/tasks';

const TaskSelectedWord = (
    setIdModal: any,
    initialDataTaskSelectedWord: SelectedWordInterface,
    taskSelectedWord: SelectedWordInterface,
    setTaskSelectedWord: any,
    dataModule: DataModuleInterface,
) => {
    // constants
    const token: any = String(localStorage.getItem('token') || '');
    const adjustMarginBottom = { marginBottom: 10 };
    const adjustButtonLogo = { marginBottom: 25, width: 200 };
    const adjustWidthSelectedActivities = { width: 200 };
    const marginDefault = { marginRight: 10 };

    // methods
    const BackScreenModal = () => {
        setIdModal(1);
        setTaskSelectedWord(initialDataTaskSelectedWord);
    };

    const CreateTask = async () => {
        const formatOptions = taskSelectedWord?.options?.map((e: any) => {
            const option = e;
            delete option.idWrapperTask;
            return option;
        });
        const formatTask: any = {
            ...taskSelectedWord,
            options: formatOptions,
            idTask: taskSelectedWord.id,
            idModule: dataModule?.id,
        };
        delete formatTask?.id;
        if (formatTask?.idTask) {
            const editTask: any = await tasks.EditTask(formatTask, token);
            if (editTask) {
                BackScreenModal();
            }
        } else {
            delete formatTask?.idTask;
            const createTask: any = await tasks.CreateTask(formatTask, token);
            if (createTask) {
                BackScreenModal();
            }
        }
    };

    const onChangeTitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskSelectedWord, title: txt.target.value };
            setTaskSelectedWord(newDataTask);
        }
    };

    const onChangeSubtitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskSelectedWord, subtitle: txt.target.value };
            setTaskSelectedWord(newDataTask);
        }
    };

    const onChangeOptionSkipTask = (txt: any) => {
        const newDataTask = { ...taskSelectedWord, skip: txt.target.value };
        setTaskSelectedWord(newDataTask);
    };

    const validationDisabled = () => {
        let valueObject = true;
        if (taskSelectedWord?.id) {
            const ValidationLength = Object.values(taskSelectedWord).filter((v) => v !== '').length !== 9;
            const ValidationOptionsLength = taskSelectedWord?.options.filter((v: any) => v.optionEnglish && v.optionPortuguese).length === taskSelectedWord?.options?.length;
            if (!ValidationLength && ValidationOptionsLength) {
                valueObject = false;
            }
        } else if (!taskSelectedWord?.id) {
            const ValidationLength = Object.values(taskSelectedWord).filter((v) => v !== '').length !== 8;
            if (!ValidationLength) {
                valueObject = false;
            }
        }
        return valueObject;
    };

    const validationBackground = () => {
        const valueObject = Object.values(taskSelectedWord).filter((v) => v !== '');
        const ValidationOptionsLength = taskSelectedWord?.options.filter((v: any) => v.optionEnglish && v.optionPortuguese).length === taskSelectedWord?.options?.length;
        if ((taskSelectedWord?.id ? valueObject.length === 9 : valueObject.length === 8) && ValidationOptionsLength) {
            return 'primary';
        }

        return 'inherit';
    };

    const onChangeTarget = (txt: any) => {
        const allOptions = [];
        for (let i = 0; i < Number(txt.target.value); i++) {
            const objOrigin = {
                optionEnglish: '',
                optionPortuguese: '',
                id: uuidv4(),
            };
            allOptions.push(objOrigin);
            if (allOptions.length === Number(txt.target.value)) {
                const orderNewDataOption = allOptions.sort((a, b) => {
                    if (a.id < b.id) return -1;
                    if (a.id > b.id) return 1;
                    return 0;
                });
                const newDataTask = {
                    ...taskSelectedWord,
                    target: Number(txt.target.value),
                    options: orderNewDataOption,
                    correct: orderNewDataOption.filter((v: any) => !v.optionEnglish || !v.optionPortuguese).length > 0 ? '' : taskSelectedWord?.correct,
                };
                setTaskSelectedWord(newDataTask);
            }
        }
    };

    const onChangeOptionCorrect = (txt: any) => {
        const newDataTask = { ...taskSelectedWord, correct: txt.target.value };
        setTaskSelectedWord(newDataTask);
    };

    // renders
    const RenderAllOptionsCorrect = () => {
        const AllOptionsEnglish = [] as any;
        const test = taskSelectedWord.options.map(
            (v: any) => AllOptionsEnglish.push(v.optionEnglish),
        );
        if (AllOptionsEnglish.length === test.length) {
            return AllOptionsEnglish.map((optionEnglish: any) => (
                <MenuItem value={optionEnglish}>{optionEnglish}</MenuItem>
            ));
        }

        return null;
    };

    const RenderSelectedCorrectOptions = (skipTask: boolean) => {
        const optionsEnglish = taskSelectedWord.options.filter((v: any) => v.optionEnglish !== '').length;
        const optionsPortuguese = taskSelectedWord.options.filter((v: any) => v.optionPortuguese !== '').length;
        const addAllOptions = optionsEnglish + optionsPortuguese;
        const allOptions = addAllOptions === taskSelectedWord.target * 2;

        if (addAllOptions > 0 && allOptions && !skipTask) {
            const SelectedPhoto = async (event: any) => {
                if (event.target.files.length) {
                    const newDataFiles = {
                        eventFile: event.target.files[0],
                        eventName: event.target.files[0].name,
                        file: URL.createObjectURL(event.target.files[0]),
                    };

                    const upload: any = await uploads.UploadFile(newDataFiles.eventFile);
                    if (upload) {
                        setTaskSelectedWord({ ...taskSelectedWord, file: upload.result });
                    }
                }
            };

            return (
                <>
                    <FormControl style={adjustWidthSelectedActivities}>
                        <InputLabel>Opção correta</InputLabel>
                        <Select value={taskSelectedWord.correct} onChange={onChangeOptionCorrect}>
                            {RenderAllOptionsCorrect()}
                        </Select>
                    </FormControl>
                    <Button
                        style={adjustButtonLogo}
                        title={taskSelectedWord?.file}
                    >
                        <Logo src={defaultSound} />
                        {taskSelectedWord?.file ? (
                            <ContainerAdjustIconEdit right="40px" style={adjustMarginBottom}>
                                <CheckCircle color="inherit" />
                            </ContainerAdjustIconEdit>
                        ) : null}
                        <InputNullSelectedPhoto
                            accept=".mp3,audio/*"
                            onChange={SelectedPhoto}
                            type="file"
                        />
                    </Button>
                </>
            );
        }
        if (addAllOptions > 0 && allOptions && skipTask) {
            return (
                <FormControl style={adjustWidthSelectedActivities}>
                    <InputLabel>Pular/Não pular</InputLabel>
                    <Select value={taskSelectedWord.skip} onChange={onChangeOptionSkipTask}>
                        <MenuItem value="true">Pular</MenuItem>
                        <MenuItem value="false">Não pular</MenuItem>
                    </Select>
                </FormControl>
            );
        }

        return null;
    };

    const RenderInputsOptions = (label: string, value: string, onChange: any) => {
        const ValueActual = value;
        return (
            <TextField
                fullWidth
                label={`${label} (${ValueActual.length}/50)`}
                onChange={onChange}
                style={marginDefault}
                value={value}
            />
        );
    };

    const RenderInputsTitles = (label: string, value: string, onChange: any) => {
        const ValueActual = value;
        return (
            <>
                <ContainerOptions marginTop="10px" />
                <TextField
                    fullWidth
                    label={`${label} (${ValueActual.length}/200)`}
                    onChange={onChange}
                    value={value}
                />
            </>
        );
    };

    const RenderInputsBody = () => {
        if (taskSelectedWord.options?.length) {
            return taskSelectedWord.options.map((item: any, index: number) => {
                const indexFormat = index + 1;
                const indexZero = indexFormat < 10 ? 0 : '';

                const onChangeOptionPortuguese = (txt: any) => {
                    if (txt.target.value.length <= 50) {
                        const [getOneOption] = taskSelectedWord.options.filter(
                            (v: any) => v.id === item.id,
                        );
                        if (getOneOption.id === item.id) {
                            const removeOneOption = taskSelectedWord.options.filter(
                                (v: any) => v.id !== item.id,
                            );
                            const optionModified = {
                                optionEnglish: getOneOption.optionEnglish,
                                optionPortuguese: txt.target.value,
                                id: getOneOption.id,
                            };
                            const newDataOption = [...removeOneOption, optionModified];
                            const orderNewDataOption = newDataOption.sort((a, b) => {
                                if (a.id < b.id) return -1;
                                if (a.id > b.id) return 1;
                                return 0;
                            });
                            setTaskSelectedWord({
                                ...taskSelectedWord,
                                options: orderNewDataOption,
                                correct: !txt.target.value?.length ? '' : taskSelectedWord?.correct,
                            });
                        }
                    }
                };

                const onChangeOptionEnglish = (txt: any) => {
                    if (txt.target.value.length <= 50) {
                        const [getOneOption] = taskSelectedWord.options.filter(
                            (v: any) => v.id === item.id,
                        );
                        if (getOneOption.id === item.id) {
                            const removeOneOption = taskSelectedWord.options.filter(
                                (v: any) => v.id !== item.id,
                            );
                            const optionModified = {
                                optionEnglish: txt.target.value,
                                optionPortuguese: getOneOption.optionPortuguese,
                                id: getOneOption.id,
                            };
                            const newDataOption = [...removeOneOption, optionModified];
                            const orderNewDataOption = newDataOption.sort((a, b) => {
                                if (a.id < b.id) return -1;
                                if (a.id > b.id) return 1;
                                return 0;
                            });
                            setTaskSelectedWord({
                                ...taskSelectedWord,
                                options: orderNewDataOption,
                                correct: !txt.target.value?.length ? '' : taskSelectedWord?.correct,
                            });
                        }
                    }
                };

                return (
                    <ContainerOptions marginTop="10px">
                        {RenderInputsOptions(`Opção ${indexZero}${indexFormat} português`, item.optionPortuguese, onChangeOptionPortuguese)}
                        {RenderInputsOptions(`Opção ${indexZero}${indexFormat} inglês`, item.optionEnglish, onChangeOptionEnglish)}
                    </ContainerOptions>
                );
            });
        }

        return null;
    };

    const RenderAllItems = () => {
        if (allMenuItems.ids.length) {
            return allMenuItems.ids.map((idSelected: any) => (
                <MenuItem value={idSelected}>{idSelected}</MenuItem>
            ));
        }
        return null;
    };

    const EndIcon = () => (
        <Send />
    );

    const RenderIconGoBack = () => (
        <NavigateBefore />
    );

    return (
        <>
            <ContainerOptions>
                <TitleOption width="100%">Criação da Atividade - Escolha a palavra/expressão</TitleOption>
            </ContainerOptions>
            {RenderInputsTitles('Título da atividade', taskSelectedWord.title, onChangeTitle)}
            {RenderInputsTitles('Subtítulo da atividade', taskSelectedWord.subtitle, onChangeSubtitle)}
            <FormControl style={adjustWidthSelectedActivities}>
                <InputLabel>Quantidade</InputLabel>
                <Select value={taskSelectedWord.target} onChange={onChangeTarget}>
                    {RenderAllItems()}
                </Select>
            </FormControl>
            {RenderInputsBody()}
            <ContainerOptions marginTop="10px" width="50%">
                {RenderSelectedCorrectOptions(false)}
                {RenderSelectedCorrectOptions(true)}
            </ContainerOptions>
            <ContainerOptions marginTop="40px">
                <Button
                    color="primary"
                    startIcon={RenderIconGoBack()}
                    onClick={BackScreenModal}
                    variant="contained"
                >
                    Voltar
                </Button>
                <Button
                    color={validationBackground()}
                    disabled={validationDisabled()}
                    endIcon={EndIcon()}
                    onClick={CreateTask}
                    variant="contained"
                >
                    {taskSelectedWord?.id ? 'Editar Atividade' : 'Criar Atividade'}
                </Button>
            </ContainerOptions>
        </>
    );
};

export default TaskSelectedWord;
