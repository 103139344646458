/* eslint-disable no-alert */
// js
import api from './api';

// api
async function UploadFile(file: any): Promise<void> {
    try {
        const body = new FormData();
        body.append('file', file);
        const configs = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const response = await api.post('/uploads', body, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

export default {
    UploadFile,
};
