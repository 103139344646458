// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';
import consts from '../../utils/consts';

// interfaces
interface ButtonActionInterface {
    backgroundColor: boolean,
    enabledPointer: boolean,
}

// styles
export const ButtonAction = styled.button<ButtonActionInterface>`
    background-color: ${(props) => (props.backgroundColor ? colors.theme.blue : colors.basic.gray)};
    cursor: ${(props) => (props.enabledPointer ? 'pointer' : 'auto')};
    border-radius: 5px;
    border-width: 0px;
    color: ${colors.basic.white};
    font-size: 20px;
    font-weight: ${consts.getFontWeight('Lato-Bold')};
    margin-top: 20px;
    min-height: 45px;
    min-width: 100%;
    transition: all ease .4s;
    :hover {
        opacity: ${(props) => (props.enabledPointer ? 0.8 : 1)};
    }
`;

export const BoxInputs = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 30px;
    min-width: 50%;
    padding: 50px 50px 50px 50px;
`;

export const CheckBox = styled.input`
    align-self: flex-start;
    height: 20px;
    width: 20px;
`;

export const Container = styled.div`
    align-items: center;
    background-color: ${colors.theme.blue};
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
`;

export const ContainerCheckBox = styled.div`
    align-items: center;
    align-self: flex-start;
    background-color: ${colors.basic.white};
    cursor: pointer;
    display: flex;
    height: 20px;
    justify-items: center;
    margin-top: 20px;
`;

export const InputAuth = styled.input`
    background-color: ${colors.basic.white};
    color: ${colors.basic.gray};
    border: none;
    font-size: 16px;
    font-weight: ${consts.getFontWeight('Lato-Regular')};
    min-height: 40px;
    min-width: 92%;
    outline: 0px;
    padding-left: 10px;
    transition: all ease .4s;
    :focus {
        color: ${colors.basic.black};
    }
`;

export const Title = styled.h1`
    color: ${colors.basic.black};
    font-size: 50px;
    font-weight: ${consts.getFontWeight('Lato-Bold')};
    padding-bottom: 30px;
`;

export const TitleInputAuth = styled.h1`
    align-self: flex-start;
    color: ${colors.basic.black};
    font-size: 20px;
    font-weight: ${consts.getFontWeight('Lato-Bold')};
    padding-bottom: 15px;
    padding-top: 15px;
`;

export const TitleRemember = styled.h1`
    color: ${colors.basic.black};
    font-size: 18px;
    font-weight: ${consts.getFontWeight('Lato-Regular')};
    padding-left: 10px;
`;

export const InputIconview = styled.div`
    min-height: 40px;
    min-width: 100%;
    border: 2px solid gray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ImageView = styled.img`
    float: left;
    margin-right: 20px;
    width: 24px;
    border: none;
    cursor: pointer;

`;

export const InputIconEnvelope = styled.img`
    float: left;
    margin-right: 20px;
    width: 24px;
    border: none;
`;
