/* eslint-disable max-len */
/* eslint-disable no-plusplus */
// libraries
import React from 'react';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { CheckCircle, NavigateBefore, Send } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';

// js
import defaultSound from '../../../assets/images/defaultSound.png';
import defaultPhoto from '../../../assets/images/default.png';
import { allMenuItems } from './taskConstants';
import {
    ContainerAdjustIconEdit,
    ContainerOptions,
    InputNullSelectedPhoto,
    Logo,
    TitleOption,
} from '../modulesStyles';

// interfaces
import type { CompletedPhraseInterface, DataModuleInterface } from '../modulesInterfaces';
import uploads from '../../../services/uploads';
import tasks from '../../../services/tasks';

const TaskCompletedPhrase = (
    setIdModal: any,
    initialDataTaskCompletedPhrase: CompletedPhraseInterface,
    taskCompletedPhrase: CompletedPhraseInterface,
    setTaskCompletedPhrase: any,
    dataModule: DataModuleInterface,
) => {
    // constants
    const token: any = String(localStorage.getItem('token') || '');
    const adjustMarginBottom = { marginBottom: 10 };
    const adjustButtonLogo = { marginBottom: 25, width: 200 };
    const adjustWidthSelectedActivities = { width: 200 };
    const marginDefault = { marginRight: 10, width: '45%' };

    // methods
    const BackScreenModal = () => {
        setIdModal(1);
        setTaskCompletedPhrase(initialDataTaskCompletedPhrase);
    };

    const CreateTask = async () => {
        const formatOptions = taskCompletedPhrase?.options?.map((e: any) => {
            const option = e;
            delete option?.idWrapperTask;
            return option;
        });
        const formatTask: any = {
            ...taskCompletedPhrase,
            options: formatOptions,
            idTask: taskCompletedPhrase.id,
            idModule: dataModule?.id,
        };
        delete formatTask?.id;
        if (formatTask?.idTask) {
            const editTask: any = await tasks.EditTask(formatTask, token);
            if (editTask) {
                BackScreenModal();
            }
        } else {
            delete formatTask?.idTask;
            const createTask: any = await tasks.CreateTask(formatTask, token);
            if (createTask) {
                BackScreenModal();
            }
        }
    };

    const onChangeTitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskCompletedPhrase, title: txt.target.value };
            setTaskCompletedPhrase(newDataTask);
        }
    };

    const onChangeSubtitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskCompletedPhrase, subtitle: txt.target.value };
            setTaskCompletedPhrase(newDataTask);
        }
    };

    const onChangePhraseEnglish = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskCompletedPhrase, phraseEnglish: txt.target.value };
            setTaskCompletedPhrase(newDataTask);
        }
    };

    const onChangeCorrect = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskCompletedPhrase, correct: txt.target.value };
            setTaskCompletedPhrase(newDataTask);
        }
    };

    const onChangePhrasePortuguese = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskCompletedPhrase, phrasePortuguese: txt.target.value };
            setTaskCompletedPhrase(newDataTask);
        }
    };

    const onChangeOptionCorrect = (txt: any) => {
        const newDataTask = { ...taskCompletedPhrase, correct: txt.target.value };
        setTaskCompletedPhrase(newDataTask);
    };

    const onChangeOptionSkipTask = (txt: any) => {
        const newDataTask = { ...taskCompletedPhrase, skip: txt.target.value };
        setTaskCompletedPhrase(newDataTask);
    };

    const validationDisabled = () => {
        let valueObject = true;
        const ValidationOptionsLength = taskCompletedPhrase?.typeQuestion === 'Selecionar' && taskCompletedPhrase?.options ? taskCompletedPhrase?.options?.filter((v: any) => v.option).length === taskCompletedPhrase?.options?.length : true;
        if (taskCompletedPhrase?.id) {
            const ValidationLengthChecked = Object.values(taskCompletedPhrase).filter((v) => v).length !== 11;
            const ValidationLengthSelect = Object.values(taskCompletedPhrase).filter((v) => v).length !== 12;
            if (taskCompletedPhrase.typeQuestion === 'Selecionar') {
                if (!ValidationLengthSelect && ValidationOptionsLength) {
                    valueObject = false;
                }
            }
            if (taskCompletedPhrase.typeQuestion === 'Checar') {
                if (ValidationLengthChecked && ValidationOptionsLength) {
                    valueObject = false;
                }
            }
        } else if (!taskCompletedPhrase?.id) {
            const ValidationLengthSelect = Object.values(taskCompletedPhrase).filter((v) => v !== '').length !== 11;
            const ValidationLengthChecked = Object.values(taskCompletedPhrase).filter((v) => v !== '').length !== 12;
            if (taskCompletedPhrase.typeQuestion === 'Selecionar') {
                if (!ValidationLengthSelect && ValidationOptionsLength) {
                    valueObject = false;
                }
            }
            if (taskCompletedPhrase.typeQuestion === 'Checar') {
                if (!ValidationLengthChecked && ValidationOptionsLength) {
                    valueObject = false;
                }
            }
        }
        return valueObject;
    };

    const validationBackground = () => {
        const valueObject = Object.values(taskCompletedPhrase).filter((v) => v !== '');
        const ValidationOptionsLength = taskCompletedPhrase?.typeQuestion === 'Selecionar' && taskCompletedPhrase?.options ? taskCompletedPhrase?.options?.filter((v: any) => v.option).length === taskCompletedPhrase?.options?.length : true;
        if ((
            taskCompletedPhrase?.id ? valueObject.length === 12 && taskCompletedPhrase.typeQuestion === 'Selecionar' && ValidationOptionsLength : valueObject.length === 11 && taskCompletedPhrase.typeQuestion === 'Selecionar' && ValidationOptionsLength
        ) || (
            taskCompletedPhrase?.id ? valueObject.length === 13 && taskCompletedPhrase.typeQuestion === 'Checar' && ValidationOptionsLength : valueObject.length === 12 && taskCompletedPhrase.typeQuestion === 'Checar' && ValidationOptionsLength
        )) {
            return 'primary';
        }

        return 'inherit';
    };

    const onChangeTarget = (txt: any) => {
        const allOptions = [];
        for (let i = 0; i < Number(txt.target.value); i++) {
            const objOrigin = { option: '', id: uuidv4() };
            allOptions.push(objOrigin);
            if (allOptions.length === Number(txt.target.value)) {
                const orderNewDataOption = allOptions.sort((a, b) => {
                    if (a.id < b.id) return -1;
                    if (a.id > b.id) return 1;
                    return 0;
                });
                const newDataTask = {
                    ...taskCompletedPhrase,
                    target: Number(txt.target.value),
                    options: orderNewDataOption,
                    correct: orderNewDataOption.filter((v: any) => !v.option).length > 0 ? '' : taskCompletedPhrase?.correct,
                };
                setTaskCompletedPhrase(newDataTask);
            }
        }
    };

    const onChangeTypeQuestion = (txt: any) => {
        const newDataTask = {
            ...initialDataTaskCompletedPhrase,
            typeQuestion: txt.target.value,
        };
        setTaskCompletedPhrase(newDataTask);
    };

    // renders
    const RenderAllOptionsCorrect = () => {
        const AllOptionsInitial = [] as any;
        const AllOptions = taskCompletedPhrase?.options?.map(
            (v: any) => AllOptionsInitial.push(v.option),
        );
        if (AllOptionsInitial.length === AllOptions.length) {
            return AllOptionsInitial.map((option: any) => (
                <MenuItem value={option}>{option}</MenuItem>
            ));
        }

        return null;
    };

    const RenderSelectedCorrectOptions = (skipTask: boolean) => {
        const options = taskCompletedPhrase?.options?.filter((v: any) => v?.option !== '')?.length;
        const allOptions = options === taskCompletedPhrase?.target;

        if (taskCompletedPhrase.typeQuestion === 'Checar' || (options > 0 && allOptions && !skipTask)) {
            const SelectedAudio = async (event: any) => {
                if (event.target.files.length) {
                    const newDataFiles = {
                        eventFile: event.target.files[0],
                        eventName: event.target.files[0].name,
                        file: URL.createObjectURL(event.target.files[0]),
                    };
                    const upload: any = await uploads.UploadFile(newDataFiles.eventFile);
                    if (upload) {
                        setTaskCompletedPhrase({ ...taskCompletedPhrase, file: upload.result });
                        // eslint-disable-next-line no-param-reassign
                        event.target.value = null;
                    }
                }
            };

            const SelectedPhoto = async (event: any) => {
                if (event.target.files.length) {
                    const newDataFiles = {
                        eventFile: event.target.files[0],
                        eventName: event.target.files[0].name,
                        file: URL.createObjectURL(event.target.files[0]),
                    };
                    const upload: any = await uploads.UploadFile(newDataFiles.eventFile);
                    if (upload) {
                        setTaskCompletedPhrase({ ...taskCompletedPhrase, fileImg: upload.result });
                    }
                }
            };

            return (
                <>
                    {taskCompletedPhrase.typeQuestion === 'Selecionar' ? (
                        <FormControl style={adjustWidthSelectedActivities}>
                            <InputLabel>Opção correta</InputLabel>
                            <Select
                                value={taskCompletedPhrase.correct}
                                onChange={onChangeOptionCorrect}
                            >
                                {RenderAllOptionsCorrect()}
                            </Select>
                        </FormControl>
                    ) : null}
                    <Button
                        style={adjustButtonLogo}
                        title={taskCompletedPhrase?.file}
                    >
                        <Logo src={defaultSound} />
                        {taskCompletedPhrase?.file ? (
                            <ContainerAdjustIconEdit right="40px" style={adjustMarginBottom}>
                                <CheckCircle color="inherit" />
                            </ContainerAdjustIconEdit>
                        ) : null}
                        <InputNullSelectedPhoto
                            accept=".mp3,audio/*"
                            onChange={SelectedAudio}
                            type="file"
                        />
                    </Button>
                    {taskCompletedPhrase.typeQuestion === 'Checar' ? (
                        <>
                            <Button
                                style={adjustButtonLogo}
                                title={taskCompletedPhrase?.fileImg}
                            >
                                <Logo src={taskCompletedPhrase?.fileImg || defaultPhoto} />
                                <InputNullSelectedPhoto
                                    accept="image/png, image/jpeg, image/jpg"
                                    onChange={SelectedPhoto}
                                    type="file"
                                />
                            </Button>
                            <FormControl style={adjustWidthSelectedActivities}>
                                <InputLabel>Pular/Não pular</InputLabel>
                                <Select
                                    value={taskCompletedPhrase.skip}
                                    onChange={onChangeOptionSkipTask}
                                >
                                    <MenuItem value="true">Pular</MenuItem>
                                    <MenuItem value="false">Não pular</MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    ) : null}
                </>
            );
        }
        if (options > 0 && allOptions && skipTask && taskCompletedPhrase.typeQuestion === 'Selecionar') {
            return (
                <FormControl style={adjustWidthSelectedActivities}>
                    <InputLabel>Pular/Não pular</InputLabel>
                    <Select value={taskCompletedPhrase.skip} onChange={onChangeOptionSkipTask}>
                        <MenuItem value="true">Pular</MenuItem>
                        <MenuItem value="false">Não pular</MenuItem>
                    </Select>
                </FormControl>
            );
        }

        return null;
    };

    const RenderInputsOptions = (label: string, value: string, onChange: any) => {
        const ValueActual = value;
        return (
            <TextField
                label={`${label} (${ValueActual.length}/200)`}
                onChange={onChange}
                style={marginDefault}
                value={value}
            />
        );
    };

    const RenderInputsBody = () => {
        if (taskCompletedPhrase?.options?.length) {
            return taskCompletedPhrase.options.map((item: any, index: number) => {
                const indexFormat = index + 1;
                const indexZero = indexFormat < 10 ? 0 : '';

                const onChangeOption = (txt: any) => {
                    if (txt.target.value.length <= 200) {
                        const [getOneOption] = taskCompletedPhrase.options.filter(
                            (v: any) => v.id === item.id,
                        );
                        if (getOneOption.id === item.id) {
                            const removeOneOption = taskCompletedPhrase.options.filter(
                                (v: any) => v.id !== item.id,
                            );
                            const optionModified = {
                                option: txt.target.value,
                                id: getOneOption.id,
                            };
                            const newDataOption = [...removeOneOption, optionModified];
                            const orderNewDataOption = newDataOption.sort((a, b) => {
                                if (a.id < b.id) return -1;
                                if (a.id > b.id) return 1;
                                return 0;
                            });
                            setTaskCompletedPhrase({
                                ...taskCompletedPhrase,
                                options: orderNewDataOption,
                            });
                        }
                    }
                };

                return RenderInputsOptions(`Opção ${indexZero}${indexFormat}`, item.option, onChangeOption);
            });
        }

        return null;
    };

    const RenderAllItems = () => {
        if (allMenuItems.ids.length) {
            return allMenuItems.ids.map((idSelected: any) => (
                <MenuItem value={idSelected}>{idSelected}</MenuItem>
            ));
        }
        return null;
    };

    const RenderInputsTitles = (label: string, value: string, onChange: any) => {
        const ValueActual = value;
        return (
            <>
                <ContainerOptions marginTop="10px" />
                <TextField
                    fullWidth
                    label={`${label} (${ValueActual.length}/200)`}
                    onChange={onChange}
                    value={value}
                />
            </>
        );
    };

    const EndIcon = () => (
        <Send />
    );

    const RenderIconGoBack = () => (
        <NavigateBefore />
    );

    return (
        <>
            <ContainerOptions>
                <TitleOption width="100%">Criação da Atividade - Preencha a lacuna</TitleOption>
            </ContainerOptions>
            <FormControl style={adjustWidthSelectedActivities}>
                <InputLabel>Tipo da Questão</InputLabel>
                <Select value={taskCompletedPhrase.typeQuestion} onChange={onChangeTypeQuestion}>
                    <MenuItem value="Selecionar">Selecionar</MenuItem>
                    <MenuItem value="Checar">Checar</MenuItem>
                </Select>
            </FormControl>
            {RenderInputsTitles('Título da atividade', taskCompletedPhrase.title, onChangeTitle)}
            {RenderInputsTitles('Subtítulo da atividade', taskCompletedPhrase.subtitle, onChangeSubtitle)}
            <TextField
                fullWidth
                label={`Ex.: Quem é você? (${taskCompletedPhrase.phrasePortuguese.length}/200)`}
                onChange={onChangePhrasePortuguese}
                value={taskCompletedPhrase.phrasePortuguese}
            />
            <TextField
                fullWidth
                label={`Ex.: Who _LACUNA_ you? (${taskCompletedPhrase.phraseEnglish.length}/200)`}
                onChange={onChangePhraseEnglish}
                value={taskCompletedPhrase.phraseEnglish}
            />
            {taskCompletedPhrase.typeQuestion === 'Selecionar' ? (
                <FormControl style={adjustWidthSelectedActivities}>
                    <InputLabel>Quantidade</InputLabel>
                    <Select value={taskCompletedPhrase.target} onChange={onChangeTarget}>
                        {RenderAllItems()}
                    </Select>
                </FormControl>
            ) : (
                <>
                    <TextField
                        fullWidth
                        label={`Respota Correta. Ex.: are (${taskCompletedPhrase.correct.length}/200)`}
                        onChange={onChangeCorrect}
                        value={taskCompletedPhrase.correct}
                    />
                    <ContainerOptions marginTop="10px" width="50%">
                        {RenderSelectedCorrectOptions(false)}
                    </ContainerOptions>
                </>
            )}
            {taskCompletedPhrase.typeQuestion === 'Selecionar' ? (
                <>
                    <ContainerOptions wrap="wrap" maxWidth="1000px">
                        {RenderInputsBody()}
                    </ContainerOptions>
                    <ContainerOptions marginTop="10px" width="50%">
                        {RenderSelectedCorrectOptions(false)}
                        {RenderSelectedCorrectOptions(true)}
                    </ContainerOptions>
                </>
            ) : null}
            <ContainerOptions marginTop="40px">
                <Button
                    color="primary"
                    startIcon={RenderIconGoBack()}
                    onClick={BackScreenModal}
                    variant="contained"
                >
                    Voltar
                </Button>
                <Button
                    color={validationBackground()}
                    disabled={validationDisabled()}
                    endIcon={EndIcon()}
                    onClick={CreateTask}
                    variant="contained"
                >
                    {taskCompletedPhrase?.id ? 'Editar Atividade' : 'Criar Atividade'}
                </Button>
            </ContainerOptions>
        </>
    );
};

export default TaskCompletedPhrase;
