// libraries
import React from 'react';
import ReactLoading from 'react-loading';
import { Modal } from '@material-ui/core';

// js
import colors from '../styles/colors';
import { Container } from '../pages/Modules/modulesStyles';

// interfaces
interface Props {
    activeLoading: boolean,
}

const ModalLoading: React.FC<Props> = (props: Props) => {
    const { activeLoading } = props;
    return (
        <Modal
            disableBackdropClick
            disableEscapeKeyDown
            open={activeLoading}
        >
            <Container>
                <ReactLoading type="bars" color={colors.basic.white} height={100} width={100} />
            </Container>
        </Modal>
    );
};

export default ModalLoading;
