// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';
import consts from '../../utils/consts';

// interfaces
interface ContainerOptionsInterface {
    marginTop?: string,
    maxWidth?: string,
    positionItemHorizontal?: string,
    positionItemVertical?: string,
    wrap?: string,
    width?: string,
}
interface TitleOptionInterfaces {
    width?: string;
}
interface ContainerBoxInterfaces {
    marginTop?: string;
}

// styles
export const Container = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
`;

export const ContainerPaginator = styled.div`
    display: flex;
    margin-top: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const ContainerBox = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 5px;
    box-shadow: 1px 1px 3px gray;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 80px;
    min-width: 1000px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
`;

export const ContainerBoxModalInline = styled.div<ContainerBoxInterfaces>`
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 5px;
    box-shadow: 1px 1px 3px gray;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: ${({ marginTop }) => marginTop || '80px'};
    min-width: 1000px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
`;

export const ContainerMore = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 20%;
`;

export const ContainerTitle = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
`;

export const ContainerOptions = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
`;

export const InfoOption = styled.h1<TitleOptionInterfaces>`
    color: ${colors.basic.black};
    font-size: 14px;
    font-weight: ${consts.getFontWeight('Lato-Light')};
    width: ${({ width }) => width || '20%'};
    word-wrap: break-word;
`;

export const LineSeparator = styled.div`
    background-color: ${colors.basic.gray};
    height: 1px;
    margin-top: 20px;
    opacity: 0.3;
    width: 100%;
`;

export const TitleBox = styled.h1`
    color: ${colors.basic.black};
    font-size: 22px;
    font-weight: ${consts.getFontWeight('Lato-Bold')};
    margin-top: 20px;
`;

export const TitleOption = styled.h1<TitleOptionInterfaces>`
    color: ${colors.basic.black};
    font-size: 17px;
    font-weight: ${consts.getFontWeight('Lato-Regular')};
    width: ${({ width }) => width || '20%'};
`;

export const ContainerOptionsInput = styled.div<ContainerOptionsInterface>`
    align-items: ${(props) => (props.positionItemVertical || 'center')};
    background-color: ${colors.basic.white};
    display: flex;
    flex-wrap: ${(props) => (props.wrap || 'nowrap')};
    justify-content: ${(props) => (props.positionItemHorizontal || 'space-between')};
    margin-top: ${(props) => (props.marginTop || '0px')};
    width: ${(props) => (props.width || 'auto')};
    max-width: ${(props) => (props.maxWidth || 'none')};
`;

export const ContainerModal = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
`;

export const ContainerBoxModal = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 5px;
    box-shadow: 1px 1px 3px gray;
    justify-content: center;
    margin: 20px 0px 20px 0px;
    min-width: 1000px;
    max-height: 70vh;
    overflow-y: auto;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
`;

export const AdjustFilters = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 1000px;
    margin-top: 20px;
`;

export const AdjustInput = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    width: 150px;
`;

export const LabelInput = styled.text`
    color: gray;
`;

export const ContainerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 100%;
`;

export const Separator = styled.div`
    width: 10%;
`;
