// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// styles
export const Container = styled.div`
    background-color: ${colors.basic.white};
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    flex-direction: column;
    padding: 20px;
`;

export const Title = styled.text`
    color: gray;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: bold;
`;

export const Subtitle = styled.text`
    color: gray;
    font-size: 18px;
    margin-bottom: 10px;
`;

export const Text = styled.text`
    color: gray;
    font-size: 14px;
    margin-bottom: 10px;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;
