/* eslint-disable no-alert */
// libraries
import React, { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';

// js
import {
    BoxInputs,
    ButtonAction,
    Container,
    InputAuth,
    Title,
    TitleInputAuth,
    ImageView,
    InputIconview,
} from './deleteAccountStyles';
import auth from '../../services/auth';

// Images
import viewOn from '../../assets/images/viewOn.svg';
import viewOff from '../../assets/images/viewOff.svg';
import envelope from '../../assets/images/envelope.svg';

// components
import ModalLoading from '../../components/ModalLoading';
import users from '../../services/users';

// interfaces
interface FormUserInterface {
    email: string,
    password: string,
}

const DeleteAccountPage: React.FC = () => {
    // consts
    const adjustAlert = { alignSelf: 'flex-start', marginTop: 10 };
    const initialFormUser = {
        email: '',
        password: '',
    };

    const [focusPassword, setFocusPassword] = useState(false);
    const [activeLoading, setActiveLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');

    const [formUser, setFormUser] = useState<FormUserInterface>(initialFormUser);
    const [verifyButton, setVerifyButton] = useState(false);
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // useEffect
    useEffect(() => {
        const validatePassword = formUser.password.length >= 6;
        const validateEmail = re.test(String(formUser.email).toLowerCase());
        const validateButton = validatePassword && validateEmail;
        setVerifyButton(validateButton);
    }, [formUser]);

    // methods
    const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormUser({ ...formUser, email: e.target.value });
    };

    const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormUser({ ...formUser, password: e.target.value });
    };

    const Login = async () => {
        try {
            if (formUser.email && formUser.password) {
                setActiveLoading(true);
                const applicationType = window.location.pathname.split('/')[1];
                const sign = await auth.Login(formUser.email, formUser.password, 'client', applicationType);
                const removeAccount: any = await users.DeleteUsers(
                    sign.result.idUser,
                    sign.result.token,
                );
                if (removeAccount?.status === 200) {
                    alert(removeAccount?.message || 'Conta excluída!');
                    setFormUser(initialFormUser);
                } else {
                    alert(removeAccount?.message || 'Ocorreu um erro ao excluir a conta!');
                }
                setActiveLoading(false);
            } else {
                setMessageAlert('Preencha todos os campos.');
            }
        } catch (error) {
            setActiveLoading(false);
        }
    };

    const EnabledFocusEmail = () => {
        setFocusPassword(false);
    };

    const EnabledFocusPassword = () => {
        setFocusPassword(true);
    };

    const KeyUpInputs = (value: React.KeyboardEvent) => {
        const { key } = value;
        if (key === 'Enter' && !focusPassword) {
            return setFocusPassword(true);
        }
        if (key === 'Enter' && focusPassword) {
            return Login();
        }
        return null;
    };

    const [verification, setVerification] = useState(false);

    const ViewPassword = () => {
        setVerification(!verification);
    };

    // renders
    return (
        <Container>
            <ModalLoading activeLoading={activeLoading} />
            <BoxInputs>
                <Title>Excluir conta</Title>
                <TitleInputAuth>E-mail</TitleInputAuth>
                <InputIconview>
                    <InputAuth
                        id="email"
                        autoFocus
                        onChange={onChangeEmail}
                        placeholder="Digite o e-mail"
                        type="email"
                        value={formUser.email}
                        onFocus={EnabledFocusEmail}
                        onKeyUp={KeyUpInputs}
                    />
                    <ImageView src={envelope} />
                </InputIconview>
                <TitleInputAuth>Senha</TitleInputAuth>
                <InputIconview>
                    <InputAuth
                        id="password"
                        onChange={onChangePassword}
                        placeholder="Digite a senha"
                        type={!verification ? 'password' : 'text'}
                        value={formUser.password}
                        onFocus={EnabledFocusPassword}
                        onKeyUp={KeyUpInputs}
                    />
                    <ImageView onClick={ViewPassword} src={!verification ? viewOff : viewOn} />
                </InputIconview>
                {messageAlert !== '' ? (
                    <Alert
                        action={messageAlert}
                        color="error"
                        severity="error"
                        style={adjustAlert}
                    />
                ) : null}
                <ButtonAction
                    onClick={Login}
                    type="button"
                    backgroundColor={verifyButton}
                    enabledPointer={verifyButton}
                >
                    Excluir conta
                </ButtonAction>
            </BoxInputs>
        </Container>
    );
};

export default DeleteAccountPage;
