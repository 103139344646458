/* eslint-disable no-alert */
// js
import api from './api';

async function UsersFilters(
    page: number,
    searchTxt: string,
    startProgress: number,
    dateStart: number,
    dateEnd: number,
    token: string,
): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        let baseURL = `/users?applicationType=${nameBank}&page=${page}&limit=10`;
        if (searchTxt.length) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`;
        }
        if (startProgress) {
            baseURL = `${baseURL}&startProgress=${startProgress}`;
        }
        if (dateStart) {
            baseURL = `${baseURL}&dateStart=${dateStart}`;
        }
        if (dateEnd) {
            baseURL = `${baseURL}&dateEnd=${dateEnd}`;
        }
        const response = await api.get(baseURL, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function ListUsers(page: number, searchTxt: string, token: string): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        let baseURL = `/users?applicationType=${nameBank}&page=${page}&limit=10`;
        if (searchTxt.length) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`;
        }
        const response = await api.get(baseURL, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function ListUserSearch(
    page: number,
    searchTxt: string,
    applicationType: null | 'inglestec' | 'e4keys' | 'iNutri' | 'iMed' | 'e4l' | 'e4b' | 'biblicalGreek' | 'gregoBiblico' | 'mic' | 'ipsis' | 'e4ce' | 'hebraicodabiblia' | 'biblicalhebrew' | 'griegobiblico' | 'hebreodelabiblia',
    startProgress: number | null,
    endProgress: number | null,
    isFree: string,
    isCancel: string,
    dateStart: string,
    dateEnd: string,
    token: string,
    type: string,
): Promise<void> {
    try {
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        let baseURL = `/users/search?page=${page}&limit=10`;
        if (searchTxt.length) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`;
        }
        if (applicationType?.length) {
            baseURL = `${baseURL}&applicationType=${applicationType}`;
        }
        if (startProgress) {
            baseURL = `${baseURL}&startProgress=${startProgress}`;
        }
        if (endProgress) {
            baseURL = `${baseURL}&endProgress=${endProgress}`;
        }
        if (dateStart) {
            baseURL = `${baseURL}&dateStart=${dateStart}`;
        }
        if (dateEnd) {
            baseURL = `${baseURL}&dateEnd=${dateEnd}`;
        }
        if (isFree) {
            baseURL = `${baseURL}&isFree=${isFree === 'no'}`;
        }
        if (isCancel) {
            baseURL = `${baseURL}&isCancel=${isCancel === 'yes'}`;
        }
        if (type) {
            baseURL = `${baseURL}&type=${type}`;
        }
        const response = await api.get(baseURL, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function DeleteUsers(idUser: string, token: string): Promise<void> {
    try {
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const response = await api.delete(`/users?idUser=${idUser}`, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function ExportUsers(idsUsers: Array<string>, token: string): Promise<void> {
    try {
        const body = {
            idsUsers,
        };
        const configs = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json',
            },
        };
        const response = await api.post('/users/export', body, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

export default {
    ListUsers,
    ListUserSearch,
    UsersFilters,
    DeleteUsers,
    ExportUsers,
};
