// libraries
import React from 'react';
import { AnimatedSwitch } from 'react-router-transition';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// pages
import AuthPage from '../pages/Auth/AuthPage';
import CategoryPage from '../pages/Category/CategoryPage';
import ModulesPage from '../pages/Modules/ModulesPage';
import PlansPage from '../pages/Plans/PlansPage';
import UsersPage from '../pages/Users/UsersPage';
import FinancePage from '../pages/Finance/FinancePage';
import FunctionalitiesPage from '../pages/Functionalities/FunctionalitiesPage';
import HistoricPage from '../pages/Historic/HistoricPage';
import EmailsPage from '../pages/Emails/EmailsPage';
import PoliticPage from '../pages/Politic';
import PoliticEsPage from '../pages/PoliticEs';
import DeleteAccountPage from '../pages/DeleteAccount/DeleteAccountPage';

const Routes: React.FC = () => {
    // constants
    const opacityMax = { opacity: 1 };
    const opacityMin = { opacity: 0 };

    // routes
    return (
        <Router>
            <AnimatedSwitch
                atActive={opacityMax}
                atEnter={opacityMin}
                atLeave={opacityMin}
            >
                <Route path="/" exact component={AuthPage} />
                <Route path="/category" component={CategoryPage} />
                <Route path="/modules" component={ModulesPage} />
                <Route path="/users" component={UsersPage} />
                <Route path="/plans" component={PlansPage} />
                <Route path="/finance" component={FinancePage} />
                <Route path="/historic" component={HistoricPage} />
                <Route path="/functionalities" component={FunctionalitiesPage} />
                <Route path="/emails" component={EmailsPage} />
                <Route path="/politica-de-privacidade" component={PoliticPage} />
                <Route path="/politica-de-privacidade-es" component={PoliticEsPage} />
                <Route path="/:applicationType/excluir-conta" exact component={DeleteAccountPage} />
            </AnimatedSwitch>
        </Router>
    );
};

export default Routes;
