// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';
import consts from '../../utils/consts';

interface MenuOptionProps {
    isActive: boolean;
}

// styles
export const Container = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
`;

export const ContainerBox = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 5px;
    box-shadow: 1px 1px 3px gray;
    justify-content: center;
    /* margin-bottom: 20px;
    margin-top: 80px; */
    min-width: 1000px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    width: 90%;
    max-height: 80%;
    margin: 80px auto 20px;
`;

export const TitleBox = styled.h1`
    color: ${colors.basic.black};
    font-size: 22px;
    font-weight: ${consts.getFontWeight('Lato-Bold')};
    margin-top: 20px;
`;

export const MenuContainer = styled.div`
    margin-top: 20px;
`;

export const MenuOption = styled.button<MenuOptionProps>`
    margin-right: 20px;
    color: ${({ isActive }) => (isActive ? colors.basic.blue : colors.basic.gray)};
    font-size: 16px;
    background-color: transparent;
    border: none;
    border-bottom: ${({ isActive }) => (isActive ? `2px solid ${colors.basic.blue}` : '2px solid transparent')};
    padding: 5px;
    &:hover {
        cursor: pointer;
    }
`;
