// js
import firebase from '../../configs/firebase';

const ActionCompleteAllTasks = (
    nameBank: string,
    idModule: string,
    setAllTasks: any,
) => {
    firebase.database().ref(`${nameBank}/modules/${idModule}/tasks`).once('value', async (snapshot) => {
        const GetAllTasks = [] as any;
        snapshot.forEach((childItem: any) => {
            if (childItem.val().type === 'COMPLETED_PHRASE') {
                const newValue = {
                    correct: childItem.val().correct,
                    id: childItem.val().id,
                    file: childItem.val().file,
                    fileImg: childItem.val().fileImg,
                    options: childItem.val().options,
                    phraseEnglish: childItem.val().phraseEnglish,
                    phrasePortuguese: childItem.val().phrasePortuguese,
                    subtitle: childItem.val().subtitle,
                    skip: childItem.val().skip,
                    target: childItem.val().target,
                    title: childItem.val().title,
                    typeQuestion: childItem.val().typeQuestion,
                    type: childItem.val().type,
                };
                GetAllTasks.push(newValue);
            }
            if (childItem.val().type === 'CONNECT_PHRASES' || childItem.val().type === 'CONNECT_WORD' || childItem.val().type === 'CONNECT_WORD_TO_IMAGES' || childItem.val().type === 'INFO_PHRASES') {
                const newValue = {
                    options: childItem.val().options,
                    id: childItem.val().id,
                    subtitle: childItem.val().subtitle,
                    title: childItem.val().title,
                    skip: childItem.val().skip,
                    target: childItem.val().target,
                    type: childItem.val().type,
                };
                GetAllTasks.push(newValue);
            }
            if (childItem.val().type === 'ORDER_WORD') {
                const newValue = {
                    order: childItem.val().order,
                    id: childItem.val().id,
                    phraseFinal: childItem.val().phraseFinal,
                    phraseInitial: childItem.val().phraseInitial,
                    subtitle: childItem.val().subtitle,
                    skip: childItem.val().skip,
                    title: childItem.val().title,
                    type: childItem.val().type,
                    file: childItem.val().file,
                };
                GetAllTasks.push(newValue);
            }
            if (childItem.val().type === 'ORDER_WORD_IMAGE') {
                const newValue = {
                    order: childItem.val().order,
                    id: childItem.val().id,
                    phraseFinal: childItem.val().phraseFinal,
                    phraseInitial: childItem.val().phraseInitial,
                    subtitle: childItem.val().subtitle,
                    skip: childItem.val().skip,
                    title: childItem.val().title,
                    type: childItem.val().type,
                    file: childItem.val().file,
                    fileImg: childItem.val().fileImg,
                };
                GetAllTasks.push(newValue);
            }
            if (childItem.val().type === 'SELECTED_WORD') {
                const newValue = {
                    correct: childItem.val().correct,
                    file: childItem.val().file,
                    id: childItem.val().id,
                    options: childItem.val().options,
                    subtitle: childItem.val().subtitle,
                    skip: childItem.val().skip,
                    target: childItem.val().target,
                    title: childItem.val().title,
                    type: childItem.val().type,
                };
                GetAllTasks.push(newValue);
            }
            if (childItem.val().type === 'SELECTED_WORD_IMAGE') {
                const newValue = {
                    correct: childItem.val().correct,
                    file: childItem.val().file,
                    fileImg: childItem.val().fileImg,
                    id: childItem.val().id,
                    options: childItem.val().options,
                    subtitle: childItem.val().subtitle,
                    skip: childItem.val().skip,
                    target: childItem.val().target,
                    title: childItem.val().title,
                    type: childItem.val().type,
                };
                GetAllTasks.push(newValue);
            }
            if (childItem.val().type === 'ASSOCIATE_WORD') {
                const newValue = {
                    correct: childItem.val().correct,
                    file: childItem.val().file,
                    id: childItem.val().id,
                    options: childItem.val().options,
                    subtitle: childItem.val().subtitle,
                    skip: childItem.val().skip,
                    target: childItem.val().target,
                    title: childItem.val().title,
                    type: childItem.val().type,
                };
                GetAllTasks.push(newValue);
            }
            if (childItem.val().type === 'ORDER_WORD_COMPLETED_PHRASE') {
                const newValue = {
                    file: childItem.val().file,
                    id: childItem.val().id,
                    answer: childItem.val().answer,
                    phraseInitial: childItem.val().phraseInitial,
                    phraseFinal: childItem.val().phraseFinal,
                    subtitle: childItem.val().subtitle,
                    skip: childItem.val().skip,
                    title: childItem.val().title,
                    type: childItem.val().type,
                };
                GetAllTasks.push(newValue);
            }
            if (childItem.val().type === 'SELECTED_WORD_FOR_QUESTION') {
                const newValue = {
                    correct: childItem.val().correct,
                    file: childItem.val().file,
                    id: childItem.val().id,
                    options: childItem.val().options,
                    subtitle: childItem.val().subtitle,
                    skip: childItem.val().skip,
                    target: childItem.val().target,
                    title: childItem.val().title,
                    type: childItem.val().type,
                    question: childItem.val().question,
                };
                GetAllTasks.push(newValue);
            }
            if (childItem.val().type === 'SELECTED_WORD_FOR_TRANSLATION') {
                const newValue = {
                    correct: childItem.val().correct,
                    file: childItem.val().file,
                    fileImg: childItem.val().fileImg,
                    id: childItem.val().id,
                    options: childItem.val().options,
                    subtitle: childItem.val().subtitle,
                    skip: childItem.val().skip,
                    target: childItem.val().target,
                    title: childItem.val().title,
                    type: childItem.val().type,
                    question: childItem.val().question,
                };
                GetAllTasks.push(newValue);
            }
        });
        setAllTasks(GetAllTasks);
    });
};

const ActionAutoCompleteTasks = (
    infoObject: any,
    setTaskSelectedWord: any,
    setTaskCompletedPhrase: any,
    setTaskConnectPhrases: any,
    setTaskConnectWord: any,
    setTaskOrderWord: any,
    setTaskOrderWordImage: any,
    setTaskConnectWordToImages: any,
    setTaskSelectedWordImage: any,
    setTaskAssociateWord: any,
    setTaskOrderWordCompletedPhrase: any,
    setTaskSelectedWordForQuestion: any,
    setTaskSelectedWordForTranslation: any,
    setTaskInfoPhrases: any,
) => {
    if (infoObject?.type === 'SELECTED_WORD') {
        setTaskSelectedWord({
            correct: infoObject?.correct,
            file: infoObject?.file,
            id: infoObject?.idTask,
            options: infoObject?.options,
            subtitle: infoObject?.subtitle,
            skip: infoObject?.skip,
            target: infoObject?.target,
            title: infoObject?.title,
            type: infoObject?.type,
        });
    }
    if (infoObject?.type === 'COMPLETED_PHRASE') {
        setTaskCompletedPhrase({
            correct: infoObject?.correct,
            file: infoObject?.file,
            fileImg: infoObject?.typeQuestion === 'Checar' ? infoObject?.fileImg || '' : '',
            id: infoObject?.idTask,
            options: infoObject?.options,
            phraseEnglish: infoObject?.phraseEnglish,
            phrasePortuguese: infoObject?.phrasePortuguese,
            subtitle: infoObject?.subtitle,
            skip: infoObject?.skip,
            target: infoObject?.typeQuestion === 'Checar' ? 0 : infoObject?.target,
            title: infoObject?.title,
            type: infoObject?.type,
            typeQuestion: infoObject?.typeQuestion || 'Selecionar',
        });
    }
    if (infoObject?.type === 'CONNECT_PHRASES') {
        setTaskConnectPhrases({
            id: infoObject?.idTask,
            options: infoObject?.options,
            subtitle: infoObject?.subtitle,
            skip: infoObject?.skip,
            target: infoObject?.target,
            title: infoObject?.title,
            type: infoObject?.type,
        });
    }
    if (infoObject?.type === 'CONNECT_WORD') {
        setTaskConnectWord({
            id: infoObject?.idTask,
            options: infoObject?.options,
            subtitle: infoObject?.subtitle,
            skip: infoObject?.skip,
            target: infoObject?.target,
            title: infoObject?.title,
            type: infoObject?.type,
        });
    }
    if (infoObject?.type === 'ORDER_WORD') {
        setTaskOrderWord({
            id: infoObject?.idTask,
            file: infoObject?.file,
            order: infoObject?.order,
            phraseFinal: infoObject?.phraseFinal,
            phraseInitial: infoObject?.phraseInitial,
            subtitle: infoObject?.subtitle,
            skip: infoObject?.skip,
            title: infoObject?.title,
            type: infoObject?.type,
        });
    }
    if (infoObject?.type === 'ORDER_WORD_IMAGE') {
        setTaskOrderWordImage({
            id: infoObject?.idTask,
            file: infoObject?.file,
            order: infoObject?.order,
            phraseFinal: infoObject?.phraseFinal,
            phraseInitial: infoObject?.phraseInitial,
            subtitle: infoObject?.subtitle,
            skip: infoObject?.skip,
            title: infoObject?.title,
            type: infoObject?.type,
            fileImg: infoObject?.fileImg,
        });
    }
    if (infoObject?.type === 'CONNECT_WORD_TO_IMAGES') {
        setTaskConnectWordToImages({
            id: infoObject?.idTask,
            options: infoObject?.options,
            subtitle: infoObject?.subtitle,
            target: infoObject?.target,
            skip: infoObject?.skip,
            title: infoObject?.title,
            type: infoObject?.type,
        });
    }
    if (infoObject?.type === 'SELECTED_WORD_IMAGE') {
        setTaskSelectedWordImage({
            correct: infoObject?.correct,
            file: infoObject?.file,
            fileImg: infoObject?.fileImg,
            id: infoObject?.idTask,
            options: infoObject?.options,
            subtitle: infoObject?.subtitle,
            skip: infoObject?.skip,
            target: infoObject?.target,
            title: infoObject?.title,
            type: infoObject?.type,
        });
    }
    if (infoObject?.type === 'ASSOCIATE_WORD') {
        setTaskAssociateWord({
            id: infoObject?.idTask,
            options: infoObject?.options,
            subtitle: infoObject?.subtitle,
            skip: infoObject?.skip,
            target: infoObject?.target,
            title: infoObject?.title,
            type: infoObject?.type,
        });
    }
    if (infoObject?.type === 'ORDER_WORD_COMPLETED_PHRASE') {
        setTaskOrderWordCompletedPhrase({
            file: infoObject?.file,
            id: infoObject?.idTask,
            answer: infoObject?.answer,
            phraseInitial: infoObject?.phraseInitial,
            phraseFinal: infoObject?.phraseFinal,
            subtitle: infoObject?.subtitle,
            skip: infoObject?.skip,
            title: infoObject?.title,
            type: infoObject?.type,
        });
    }
    if (infoObject?.type === 'SELECTED_WORD_FOR_QUESTION') {
        setTaskSelectedWordForQuestion({
            correct: infoObject?.correct,
            file: infoObject?.file,
            id: infoObject?.idTask,
            options: infoObject?.options,
            subtitle: infoObject?.subtitle,
            skip: infoObject?.skip,
            target: infoObject?.target,
            title: infoObject?.title,
            type: infoObject?.type,
            question: infoObject?.question,
        });
    }
    if (infoObject?.type === 'SELECTED_WORD_FOR_TRANSLATION') {
        setTaskSelectedWordForTranslation({
            correct: infoObject?.correct,
            file: infoObject?.file,
            fileImg: infoObject?.fileImg,
            id: infoObject?.idTask,
            options: infoObject?.options,
            subtitle: infoObject?.subtitle,
            skip: infoObject?.skip,
            target: infoObject?.target,
            title: infoObject?.title,
            type: infoObject?.type,
            question: infoObject?.question,
        });
    }
    if (infoObject?.type === 'INFO_PHRASES') {
        setTaskInfoPhrases({
            id: infoObject?.idTask,
            options: infoObject?.options,
            subtitle: infoObject?.subtitle,
            skip: infoObject?.skip,
            target: infoObject?.target,
            title: infoObject?.title,
            type: infoObject?.type,
        });
    }
};

export default {
    ActionCompleteAllTasks,
    ActionAutoCompleteTasks,
};
