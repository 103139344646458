/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-plusplus */
// libraries
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { addHours, lightFormat } from 'date-fns';
import { Pagination } from '@material-ui/lab';
import {
    Button,
    Fab, MenuItem, Modal, Select, TextField,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';

// js
import { Add, NavigateBefore, Send } from '@material-ui/icons';
import {
    Container,
    ContainerBox,
    ContainerBoxModal,
    ContainerModal,
    ContainerMore,
    ContainerOptions,
    ContainerOptionsInput,
    ContainerPaginator,
    ContainerTitle,
    InfoOption,
    LineSeparator,
    TitleBox,
    TitleOption,
} from './categoryStyles';

// components (navigation)
import DrawerNavigation from '../../routes/DrawerNavigation/DrawerNavigation';
import ModalLoading from '../../components/ModalLoading';
import category from '../../services/category';

// interfaces
interface initialInputsInterfaces {
    title: string;
    idCategory: number | null;
}

const CategoryPage: React.FC = () => {
    // constants
    const token: any = String(localStorage.getItem('token') || '');
    const adjustStyleFab = { width: '30%' };
    const initialInputs: initialInputsInterfaces = {
        title: '',
        idCategory: null,
    };

    // states
    const [allCategory, setAllCategory] = useState([]);
    const [search, setSearch] = useState('');
    const [activeLoading, setActiveLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [qtdPages, setQtdPages] = useState(1);
    const [inputs, setInputs] = useState<initialInputsInterfaces>(initialInputs);
    const [openModal, setOpenModal] = useState(false);

    const GetListCategory = async () => {
        const listCategory: any = await category.ListCategory(page, search, token, '10');
        if (listCategory) {
            setAllCategory(listCategory?.result?.list || []);
            setQtdPages(listCategory?.result?.qtdPages || 1);
            setPage(1);
        }
    };

    // methods
    const handleClickModal = () => {
        setOpenModal(!openModal);
        setInputs(initialInputs);
    };

    const handleChange = async (event: any) => {
        const infoObj = event.target.value;
        if (infoObj.type === 'EDIT') {
            // Lógica de editar
            handleClickModal();
            setInputs({
                ...inputs,
                idCategory: infoObj?.id,
                title: infoObj?.title || '',
            });
        } else {
            setActiveLoading(true);
            const deleteCategory: any = await category.DeleteOnlyCategory(infoObj.id, token);
            setActiveLoading(false);
            if (deleteCategory) {
                GetListCategory();
                alert(deleteCategory.message);
            }
        }
    };

    const handleChangePage = async (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        const listCategory: any = await category.ListCategory(value, search, token, '10');
        if (listCategory) {
            setAllCategory(listCategory?.result?.list || []);
            setQtdPages(listCategory?.result?.qtdPages || 1);
            // window.scrollTo({
            //     top: 0,
            //     left: 0,
            //     behavior: 'smooth',
            // });
        }
    };

    const CreateNewCategory = async () => {
        if (inputs.idCategory) {
            const body = {
                title: inputs.title,
            };
            const editCategory: any = await category.EditOnlyCategory(body, inputs.idCategory, token);
            GetListCategory();
            handleClickModal();
            if (editCategory?.status === 200) {
                alert('Atualizado com sucesso!');
            }
        } else {
            const body = {
                title: inputs.title,
            };
            const createCategory: any = await category.CreateOnlyCategory(body, token);
            GetListCategory();
            handleClickModal();
            if (createCategory?.status === 201) {
                alert('Criado com sucesso!');
            }
        }
    };

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            const listCategory: any = await category.ListCategory(1, search, token, '10');
            if (listCategory) {
                setAllCategory(listCategory?.result?.list || []);
                setQtdPages(listCategory?.result?.qtdPages || 1);
                setPage(1);
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [search]);

    // renders
    const RenderListItems = (item: any) => {
        const {
            title,
            dateCreated,
        } = item;
        const valueItemEdit = { ...item, type: 'EDIT' };
        const valueItemDelete = { ...item, type: 'DELETE' };

        const dateInt = Number(dateCreated);
        const date = addHours(new Date(dateInt), 3);
        const formatDate = lightFormat(date, 'dd/MM/yyyy');
        const formatHour = lightFormat(date, 'HH:mm');
        const concatDate = `${formatDate} às ${formatHour}`;

        return (
            <>
                <ContainerOptions>
                    <InfoOption>{title}</InfoOption>
                    <ContainerMore>
                        <InfoOption>{concatDate || ''}</InfoOption>
                        <Select value="" onChange={handleChange}>
                            <MenuItem value={valueItemEdit}>Editar categoria</MenuItem>
                            <MenuItem value={valueItemDelete}>Deletar categoria</MenuItem>
                        </Select>
                    </ContainerMore>
                </ContainerOptions>
                <LineSeparator />
            </>
        );
    };

    const SearchUsers = (txt: any) => {
        setSearch(txt.target.value);
    };

    const RenderUsersPage = () => {
        if (!localStorage.getItem('existUser')) {
            return <Redirect to="/" />;
        }
        return (
            <Container>
                <ModalLoading activeLoading={activeLoading} />
                <DrawerNavigation />
                <ContainerBox>
                    <TextField
                        fullWidth
                        label="Buscar por categorias"
                        onChange={SearchUsers}
                        value={search}
                    />
                    <ContainerTitle>
                        <TitleBox>Lista de categorias</TitleBox>
                        <Fab
                            variant="extended"
                            color="primary"
                            onClick={handleClickModal}
                            style={adjustStyleFab}
                        >
                            <Add />
                            Adicionar categoria
                        </Fab>
                    </ContainerTitle>
                    <ContainerOptions>
                        <TitleOption>Título</TitleOption>
                        <TitleOption>Data de criação</TitleOption>
                    </ContainerOptions>
                    <LineSeparator />
                    <FlatList list={allCategory} renderItem={RenderListItems} />
                    <ContainerPaginator>
                        <Pagination
                            count={qtdPages}
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChangePage}
                            color="primary"
                        />
                    </ContainerPaginator>
                </ContainerBox>
                <Modal
                    disableBackdropClick
                    disableEscapeKeyDown
                    onClose={handleClickModal}
                    open={openModal}
                >
                    <ContainerModal>
                        <ContainerBoxModal>
                            <TextField
                                fullWidth
                                label={`Título da categoria (${inputs.title.length}/100)`}
                                onChange={(txt) => {
                                    if (txt.target.value.length <= 100) {
                                        setInputs({
                                            ...inputs,
                                            title: txt?.target?.value || '',
                                        });
                                    }
                                }}
                                value={inputs.title}
                            />
                            <ContainerOptionsInput marginTop="40px">
                                <Button
                                    color="primary"
                                    endIcon={() => <NavigateBefore />}
                                    onClick={handleClickModal}
                                    variant="contained"
                                >
                                    Fechar
                                </Button>
                                <Button
                                    color="primary"
                                    endIcon={() => <Send />}
                                    onClick={CreateNewCategory}
                                    variant="contained"
                                >
                                    {`${inputs.idCategory ? 'Editar' : 'Criar'} categoria`}
                                </Button>
                            </ContainerOptionsInput>
                        </ContainerBoxModal>
                    </ContainerModal>
                </Modal>
            </Container>
        );
    };

    return RenderUsersPage();
};

export default CategoryPage;
