// libraries
import React from 'react';
import FlatList from 'flatlist-react';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import { Close, Send, NavigateBefore } from '@material-ui/icons';

// js
import {
    CloseModal,
    ContainerOptions,
    LineSeparator,
    TitleOption,
    InfoOption,
    ContainerMore,
} from '../modulesStyles';

const ContainerInfoTasks = (
    dataModule: any,
    allTasks: Array<any>,
    BackScreenModal: any,
    CreateModule: any,
    handleChangeListTask: any,
    handleChangeSelectedTask: any,
    onCloseModal: any,
    validationBackground: any,
    validationDisabled: any,
) => {
    // constants
    const adjustWidthSelectedActivities = { width: 200 };
    const allTasksActual = allTasks;
    const { id } = dataModule;

    // renders
    const EndIcon = () => (
        <Send />
    );

    const GoBackIcon = () => (
        <NavigateBefore />
    );

    const RenderTypeFormat = (type: string) => {
        if (type === 'SELECTED_WORD') {
            return 'Escolha a palavra/expressão';
        }
        if (type === 'COMPLETED_PHRASE') {
            return 'Preencha a lacuna';
        }
        if (type === 'CONNECT_PHRASES') {
            return 'Ligue as sentenças';
        }
        if (type === 'CONNECT_WORD') {
            return 'Conecte as palavras';
        }
        if (type === 'ORDER_WORD') {
            return 'Ordene as palavras';
        }
        if (type === 'ORDER_WORD_IMAGE') {
            return 'Ordene as palavras com imagem';
        }
        if (type === 'CONNECT_WORD_TO_IMAGES') {
            return 'Associe a palavra às imagens';
        }
        if (type === 'SELECTED_WORD_IMAGE') {
            return 'Associe a imagem às palavras';
        }
        if (type === 'ASSOCIATE_WORD') {
            return 'Associe a frase à sua tradução';
        }
        if (type === 'ORDER_WORD_COMPLETED_PHRASE') {
            return 'Ordene as palavras para preencher a lacuna';
        }
        if (type === 'SELECTED_WORD_FOR_QUESTION') {
            return 'Escolha a palavra/expressão para responder a pergunta';
        }
        if (type === 'SELECTED_WORD_FOR_TRANSLATION') {
            return 'Escolha a palavra/expressão que corresponde a sua tradução';
        }
        if (type === 'INFO_PHRASES') {
            return 'Associe as frases/parágrafos aos áudios correspondentes';
        }

        return '';
    };

    const RenderListItems = (item: any) => {
        const { title, subtitle, type } = item;
        const valueItemPut = { ...item, method: 'EDITAR' };
        const valueItemDelete = { ...item, method: 'DELETE' };

        return (
            <>
                <ContainerOptions>
                    <InfoOption>{title}</InfoOption>
                    <InfoOption>{subtitle}</InfoOption>
                    <ContainerMore>
                        <InfoOption>{RenderTypeFormat(type)}</InfoOption>
                        <Select value="" onChange={handleChangeListTask}>
                            <MenuItem value={valueItemPut}>Editar atividade</MenuItem>
                            <MenuItem value={valueItemDelete}>Deletar atividade</MenuItem>
                        </Select>
                    </ContainerMore>
                </ContainerOptions>
                <LineSeparator />
            </>
        );
    };

    return (
        <>
            <ContainerOptions>
                <TitleOption>Lista de atividades</TitleOption>
                <CloseModal onClick={onCloseModal}>
                    <Close color="primary" fontSize="large" />
                </CloseModal>
            </ContainerOptions>
            <ContainerOptions>
                <TitleOption>Título</TitleOption>
                <TitleOption>Subtítulo</TitleOption>
                <TitleOption>Tipo</TitleOption>
            </ContainerOptions>
            <LineSeparator />
            <FlatList
                list={allTasksActual}
                renderItem={RenderListItems}
            />
            <ContainerOptions marginTop="20px">
                <Button
                    color="primary"
                    startIcon={GoBackIcon()}
                    onClick={BackScreenModal}
                    variant="contained"
                >
                    Voltar
                </Button>
                <FormControl style={adjustWidthSelectedActivities}>
                    <InputLabel>Adicionar atividade</InputLabel>
                    <Select value="" onChange={handleChangeSelectedTask}>
                        <MenuItem value="SELECTED_WORD">Escolha a palavra/expressão</MenuItem>
                        <MenuItem value="COMPLETED_PHRASE">Preencha a lacuna</MenuItem>
                        <MenuItem value="CONNECT_PHRASES">Ligue as sentenças</MenuItem>
                        <MenuItem value="CONNECT_WORD">Conecte as palavras</MenuItem>
                        <MenuItem value="ORDER_WORD">Ordene as palavras</MenuItem>
                        <MenuItem value="ORDER_WORD_IMAGE">Ordene as palavras com imagem</MenuItem>
                        <MenuItem value="CONNECT_WORD_TO_IMAGES">Associe a palavra às imagens</MenuItem>
                        <MenuItem value="SELECTED_WORD_IMAGE">Associe a imagem às palavras</MenuItem>
                        <MenuItem value="ASSOCIATE_WORD">Associe a frase à sua tradução</MenuItem>
                        <MenuItem value="ORDER_WORD_COMPLETED_PHRASE">Ordene as palavras para preencher a lacuna</MenuItem>
                        <MenuItem value="SELECTED_WORD_FOR_QUESTION">Escolha a palavra/expressão para responder a pergunta</MenuItem>
                        <MenuItem value="SELECTED_WORD_FOR_TRANSLATION">Escolha a palavra/expressão que corresponde a sua tradução</MenuItem>
                        <MenuItem value="INFO_PHRASES">Associe as frases/parágrafos aos áudios correspondentes</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    color={validationBackground()}
                    disabled={validationDisabled(2)}
                    endIcon={EndIcon()}
                    onClick={CreateModule}
                    variant="contained"
                >
                    {id ? 'Editar Módulo' : 'Criar Módulo'}
                </Button>
            </ContainerOptions>
        </>
    );
};

export default ContainerInfoTasks;
