import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Pagination } from '@material-ui/lab';
import TableRow from '@mui/material/TableRow';

import { Container, ContainerPaginator } from './styles';
import { ListContacts } from '../../../../services/funtionalities';

interface ContactsTabProps {
    setActiveLoading: (value: boolean) => void;
}

interface Column {
    id: 'name' | 'email' | 'subject' | 'message';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'email', label: 'Email', minWidth: 200 },
    { id: 'name', label: 'Nome', minWidth: 150 },
    { id: 'subject', label: 'Título', minWidth: 150 },
    { id: 'message', label: 'Mensagem', minWidth: 170 },
];

interface Data {
    email: string;
    name: string;
    subject: string;
    message: string;
}

function createData(
    name: string,
    email: string,
    subject: string,
    message: string,
): Data {
    return {
        email,
        name,
        subject,
        message,
    };
}

export default function ContactsTab(props: ContactsTabProps) {
    const { setActiveLoading } = props;
    const token: any = String(localStorage.getItem('token') || '');

    const [page, setPage] = useState(1);
    const [qtdPages, setQtdPages] = useState(1);
    const [rows, setRows] = useState([]);

    const GetAllContacts = async () => {
        setActiveLoading(true);
        const listContacts: any = await ListContacts(page, token);
        if (listContacts) {
            setQtdPages(listContacts?.result?.qtdPages);
            const newList = listContacts.result.list.map((e: Data) => {
                const data = createData(e.name, e.email, e.subject, e.message);
                return data;
            });
            setRows(newList || []);
        }
        setActiveLoading(false);
    };

    const handleChangePage = async (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        setPage(value);
    };

    useEffect(() => {
        GetAllContacts();
    }, [page]);

    return (
        <Container>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        <h3>{column.label}</h3>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row}
                                >
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                            >
                                                {column.format
                                                && typeof value === 'number'
                                                    ? column.format(value)
                                                    : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <ContainerPaginator>
                <Pagination
                    count={qtdPages}
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangePage}
                    color="primary"
                />
            </ContainerPaginator>
        </Container>
    );
}
