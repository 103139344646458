// js
import { DataFileModuleInterface } from '../Modules/modulesInterfaces';

// interfaces
export interface AllPlansInterfaces {
    nameFree: string,
    descriptionFree: string,
    imageFree: DataFileModuleInterface | string,
    nameMonth: string,
    descriptionMonth: string,
    imageMonth: DataFileModuleInterface | string,
    nameSemester: string,
    descriptionSemester: string,
    imageSemester: DataFileModuleInterface | string,
    nameYear: string,
    descriptionYear: string,
    imageYear: DataFileModuleInterface | string,
}

// constants
export const AllPlansInitial: AllPlansInterfaces = {
    nameFree: '',
    descriptionFree: '',
    imageFree: '',
    nameMonth: '',
    descriptionMonth: '',
    imageMonth: '',
    nameSemester: '',
    descriptionSemester: '',
    imageSemester: '',
    nameYear: '',
    descriptionYear: '',
    imageYear: '',
};
