/* eslint-disable no-alert */
// js
import api from './api';

async function ListCategory(
    page: number,
    searchTxt: string,
    token: string,
    limit: string,
): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        let baseURL = `/modules/groups?applicationType=${nameBank}&page=${page}&limit=${limit}`;
        if (searchTxt.length) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`;
        }
        const response = await api.get(baseURL, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function CreateOnlyCategory(
    category: { title: string },
    token: string,
): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json',
            },
        };
        const response = await api.post(`/modules/groups?applicationType=${nameBank}`, category, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function EditOnlyCategory(
    category: { title: string },
    idCategory: number,
    token: string,
): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json',
            },
        };
        const response = await api.put(`/modules/groups?applicationType=${nameBank}&idModuleGroup=${idCategory}`, category, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function DeleteOnlyCategory(idCategory: string, token: string): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const response = await api.delete(`/modules/groups?applicationType=${nameBank}&idModuleGroup=${idCategory}`, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

export default {
    ListCategory,
    CreateOnlyCategory,
    EditOnlyCategory,
    DeleteOnlyCategory,
};
