import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 30px;
`;

export const ContainerPaginator = styled.div`
    display: flex;
    margin-top: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
`;
