// /* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-plusplus */
// libraries
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Pagination } from '@material-ui/lab';
import {
    addMinutes,
    addHours,
    lightFormat,
} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { FormControl, InputLabel } from '@mui/material';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import {
    Checkbox, Fab, MenuItem, Select, TextField, Modal, Button,
} from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import {
    Add, NavigateBefore, Send, InfoOutlined,
} from '@material-ui/icons';
import { Redirect } from 'react-router-dom';

// js
import {
    AdjustPicker,
    Container,
    ContainerBox,
    ContainerHeader,
    ContainerModal,
    ContainerMore,
    ContainerOptions,
    ContainerOptionsInput,
    ContainerPaginator,
    InfoOption,
    LineSeparator,
    Separator,
    TitleBox,
    TitleOption,
    ContainerBoxModal,
    AdjustColumn,
    ImageArchive,
    IframeArchive,
    AdjustFilters,
    AdjustInput,
    LabelInput,
} from './emailsStyles';

// components (navigation)
import DrawerNavigation from '../../routes/DrawerNavigation/DrawerNavigation';
import ModalLoading from '../../components/ModalLoading';
import users from '../../services/users';
import uploads from '../../services/uploads';
import emails from '../../services/emails';
import constants from '../../configs/constants';

// interfaces
interface initialFiltersInterfaces {
    signature: string;
    initialDate: string;
    finalDate: string;
    applicationType: any;
    cancel: string;
    progressInitial: string;
    progressFinal: string;
}

const UsersPage: React.FC = () => {
    // constants
    const token: any = String(localStorage.getItem('token') || '');
    const adjustStyleFab = { width: '30%' };
    const initialFilters: initialFiltersInterfaces = {
        signature: '',
        initialDate: '',
        finalDate: '',
        applicationType: '',
        cancel: '',
        progressInitial: '',
        progressFinal: '',
    };

    // states
    const [allUsers, setAllUsers] = useState([]);
    const [allTrigger, setAllTrigger] = useState([]);
    const [search, setSearch] = useState('');
    const [activeLoading, setActiveLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageTrigger, setPageTrigger] = useState(1);
    const [qtdPages, setQtdPages] = useState(1);
    const [qtdPagesTrigger, setQtdPagesTrigger] = useState(1);
    const [allIds, setAllIds] = useState<Array<string>>([]);
    const [openModal, setOpenModal] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [idFrame, setIdFrame] = useState(1);
    const [dateScheduling, setDateScheduling] = useState(addMinutes(new Date(), 10));
    const [allFiles, setAllFiles] = useState<Array<string>>([]);
    const [triggerActual, setTriggerActual] = useState<any>(null);
    const [allTriggerNotSend, setAllTriggerNotSend] = useState<any>([]);
    const [filters, setFilters] = useState<initialFiltersInterfaces>(initialFilters);

    // methods
    const GetListTrigger = async () => {
        const listTrigger: any = await emails.ListTrigger(1, null, token);
        if (listTrigger) {
            setAllTrigger(listTrigger?.result?.list || []);
            setQtdPagesTrigger(listTrigger?.result?.qtdPages || 1);
            setPageTrigger(1);
        }
    };

    const GetListTriggerNotSend = async () => {
        const listTriggerNotSend: any = await emails.GetNextTrigger(token);
        if (listTriggerNotSend) {
            setAllTriggerNotSend(listTriggerNotSend?.result?.list || []);
        }
    };

    const handleChangeTrigger = async (event: any) => {
        const infoObj = event.target.value;
        if (event.target.value?.type === 'EDIT') {
            delete infoObj?.type;
            setTriggerActual(infoObj);
            setTitle(infoObj?.title || '');
            setDescription(infoObj?.description || '');
            setAllFiles(infoObj?.attachments || []);
            // eslint-disable-next-line max-len
            setDateScheduling(infoObj?.startAt ? addHours(new Date(infoObj?.startAt), 3) : addMinutes(new Date(), 10));
            setAllIds(infoObj?.idUsers || []);
            setDateScheduling(addMinutes(new Date(), 10));
            setOpenModal(true);
        } else {
            setActiveLoading(true);
            const onDelTrigger: any = await emails.DelTrigger(infoObj?.id, token);
            if (onDelTrigger?.status === 200) {
                GetListTrigger();
                alert(onDelTrigger?.message || 'Ocorre um erro ao cancelar o disparo!');
            }
            setActiveLoading(false);
        }
    };

    const SearchUsers = (txt: any) => {
        setSearch(txt.target.value);
    };

    const handleChangePageTrigger = async (event: React.ChangeEvent<unknown>, value: number) => {
        setPageTrigger(value);
        const listTrigger: any = await emails.ListTrigger(value, null, token);
        if (listTrigger) {
            setAllTrigger(listTrigger?.result?.list || []);
            setQtdPagesTrigger(listTrigger?.result?.qtdPages || 1);
        }
    };

    const handleChangePageUsers = async (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        const listUsers: any = await users.ListUsers(value, search, token);
        if (listUsers) {
            setAllUsers(listUsers?.result?.list || []);
            setQtdPages(listUsers?.result?.qtdPages || 1);
        }
    };

    const closedModal = () => {
        setOpenModal(!openModal);
        setTitle('');
        setDescription('');
        setAllIds([]);
        setIdFrame(1);
        setDateScheduling(addMinutes(new Date(), 10));
    };

    const tradeModalTrigger = () => {
        if (idFrame === 1) {
            closedModal();
        } else {
            setIdFrame(1);
        }
    };

    const onChangeTitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            setTitle(txt.target.value);
        }
    };

    const onChangeSubtitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            setDescription(txt.target.value);
        }
    };

    const validationDisabled = () => {
        if (idFrame === 1) {
            return !(title.length && description.length);
        }
        return allIds.length === 0;
    };

    const validationBackground = () => {
        if (idFrame === 1) {
            if (title.length && description.length) {
                return 'primary';
            }

            return 'inherit';
        }
        return allIds.length > 0 ? 'primary' : 'inherit';
    };

    const nextStep = () => {
        setIdFrame(2);
    };

    const createTrigger = async () => {
        const formatDate = lightFormat(dateScheduling, 'yyyy-MM-dd HH:mm:ss.SSS');
        if (triggerActual?.id) {
            const onEdit: any = await emails.EditTrigger(
                title,
                description,
                allFiles,
                formatDate,
                allIds,
                triggerActual?.id,
                token,
            );
            if (onEdit?.status === 200) {
                closedModal();
                GetListTrigger();
                alert(onEdit?.message || 'Disparador alterado!');
            } else {
                alert(onEdit?.message || 'Ocorreu um erro ao alterar o disparador!');
            }
        } else {
            const onCreate: any = await emails.CreateTrigger(
                title,
                description,
                allFiles,
                formatDate,
                allIds,
                token,
            );
            if (onCreate?.status === 201) {
                closedModal();
                GetListTrigger();
                alert(onCreate?.message || 'Disparador agendado!');
            } else {
                alert(onCreate?.message || 'Ocorreu um erro ao agendar o disparador!');
            }
        }
    };

    const getNewUsers = async () => {
        const formatDateInitial = filters.initialDate ? lightFormat(new Date(filters.initialDate), 'yyyy-MM-dd HH:mm:ss.SSS') : '';
        const formatDateFinal = filters.progressFinal ? lightFormat(new Date(filters.progressFinal), 'yyyy-MM-dd HH:mm:ss.SSS') : '';
        const listUsers: any = await users.ListUserSearch(
            1,
            search,
            filters.applicationType,
            Number(filters.progressInitial),
            Number(filters.progressFinal),
            filters.signature,
            filters.cancel,
            formatDateInitial,
            formatDateFinal,
            token,
            '',
        );
        if (listUsers) {
            setAllUsers(listUsers?.result?.list || []);
            setQtdPages(listUsers?.result?.qtdPages || 1);
            setPage(1);
        }
    };

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            if (filters.progressInitial && filters.progressFinal) {
                if (Number(filters.progressInitial) < Number(filters.progressFinal)) {
                    getNewUsers();
                } else {
                    alert('Informe uma data inicial inferior a data final.');
                }
            } else {
                getNewUsers();
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [filters]);

    useEffect(() => {
        GetListTrigger();
        GetListTriggerNotSend();
    }, []);

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            const listUsers: any = await users.ListUsers(1, search, token);
            if (listUsers) {
                setAllUsers(listUsers?.result?.list || []);
                setQtdPages(listUsers?.result?.qtdPages || 1);
                setPage(1);
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [search]);

    useEffect(() => {
        if (openModal) {
            const timeOutId = setTimeout(async () => {
                const formatDateScheduling = lightFormat(dateScheduling, 'yyyy-MM-dd HH:mm:ss.SSS');
                const verify: any = await emails.VerifyDateTrigger(formatDateScheduling, token);
                if (!verify?.result) {
                    alert('Essa data não está disponível para uso.');
                }
            }, 500);
            return () => clearTimeout(timeOutId);
        }
        return () => null;
    }, [dateScheduling, openModal]);

    // renders
    const RenderListItemsUsers = (item: any) => {
        const {
            idUser,
            name,
            email,
        } = item;

        const onChangeCheckBox = () => {
            if (allIds.includes(idUser)) {
                const removeEmail = allIds.filter((v) => v !== idUser);
                setAllIds(removeEmail);
            } else {
                setAllIds([...allIds, idUser]);
            }
        };

        return (
            <div key={idUser}>
                <ContainerOptions>
                    <Separator>
                        <Checkbox color="primary" checked={allIds.includes(idUser)} onChange={onChangeCheckBox} />
                    </Separator>
                    <InfoOption>{name}</InfoOption>
                    <InfoOption>{email}</InfoOption>
                </ContainerOptions>
                <LineSeparator />
            </div>
        );
    };

    const RenderListTrigger = (item: any) => {
        const {
            id,
            startAt,
            isSend,
        } = item;
        const valueItemEdit = { ...item, type: 'EDIT' };
        const valueItem = { ...item, type: 'DELETE' };

        return (
            <div key={id}>
                <ContainerOptions>
                    <InfoOption>{id}</InfoOption>
                    <ContainerMore>
                        <InfoOption>{lightFormat(addHours(new Date(startAt), 3), 'dd/MM/yyyy HH:mm')}</InfoOption>
                        {isSend ? null : (
                            <Select value="" onChange={handleChangeTrigger}>
                                <MenuItem value={valueItemEdit}>Editar disparador</MenuItem>
                                <MenuItem value={valueItem}>Cancelar disparador</MenuItem>
                            </Select>
                        )}
                    </ContainerMore>
                </ContainerOptions>
                <LineSeparator />
            </div>
        );
    };

    const EndIcon = () => (
        <Send />
    );

    const RenderIconGoBack = () => (
        <NavigateBefore />
    );

    const RenderInputsTitles = (label: string, value: string, onChange: any) => {
        const ValueActual = value;
        return (
            <>
                <ContainerOptionsInput marginTop="10px" />
                <TextField
                    fullWidth
                    label={`${label} (${ValueActual.length}/200)`}
                    onChange={onChange}
                    value={value}
                />
            </>
        );
    };

    const handleChange = (date: Date | null) => {
        setDateScheduling(date || new Date());
    };

    const handleChangeArchive = (event: any) => {
        if (event?.target?.value === 'image') {
            // Upload image
            const getInputImage: any = document.getElementById('fileImage');
            if (getInputImage) {
                getInputImage.click();
            }
        }
        if (event?.target?.value === 'pdf') {
            // Upload pdf
            const getInputPdf: any = document.getElementById('filePdf');
            if (getInputPdf) {
                getInputPdf.click();
            }
        }
    };

    const onChangeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if ((event.target.files || []).length) {
            const files = (event.target.files || []);
            const newDataFiles = {
                eventFile: files[0],
                eventName: files[0].name,
                file: URL.createObjectURL(files[0]),
            };
            const upload: any = await uploads.UploadFile(
                newDataFiles.eventFile,
            );
            if (upload?.result) {
                setAllFiles([...allFiles, upload?.result]);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line no-param-reassign
                event.target.value = null;
            }
        }
    };

    const renderFrame = () => {
        if (idFrame === 1) {
            return (
                <>
                    {RenderInputsTitles('Título', title, onChangeTitle)}
                    {RenderInputsTitles('Descrição', description, onChangeSubtitle)}
                    <AdjustPicker>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy HH:mm"
                                margin="normal"
                                value={dateScheduling}
                                onChange={handleChange}
                                minDate={new Date()}
                                invalidDateMessage="Data inválida!"
                                autoOk
                                ampm={false}
                            />
                        </MuiPickersUtilsProvider>
                        <Tooltip style={{ margin: 5 }} title={allTriggerNotSend.length ? `Intervalos de futuros disparos: ${allTriggerNotSend.map((v: any) => `De: ${lightFormat(addHours(new Date(v?.startAt), 3), 'dd/MM/yyyy HH:mm:ss')} Até: ${lightFormat(addHours(new Date(v?.endAt), 3), 'dd/MM/yyyy HH:mm:ss')}`)}` : 'Todas as datas estão disponíveis para uso'}>
                            <InfoOutlined color="primary" />
                        </Tooltip>
                    </AdjustPicker>
                    <AdjustColumn>
                        <FormControl style={{ width: 200, height: 45 }}>
                            <InputLabel id="demo-simple-select-label">Escolha um arquivo</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value=""
                                onChange={handleChangeArchive}
                                style={{ height: 48, paddingTop: 10 }}
                            >
                                <MenuItem value="image">Imagem</MenuItem>
                                <MenuItem value="pdf">PDF</MenuItem>
                            </Select>
                        </FormControl>
                        <AdjustPicker>
                            {allFiles.map((v) => {
                                const removeItem = () => {
                                    const getNewsItems = allFiles.filter((e) => e !== v);
                                    setAllFiles(getNewsItems);
                                };
                                if (v.includes('.pdf')) {
                                    return (
                                        <IframeArchive
                                            key={v}
                                            src={v}
                                            onClick={removeItem}
                                        />
                                    );
                                }
                                return (
                                    <ImageArchive
                                        key={v}
                                        src={v}
                                        alt={v}
                                        onClick={removeItem}
                                    />
                                );
                            })}
                        </AdjustPicker>
                    </AdjustColumn>
                    <input id="fileImage" type="file" accept="image/png, image/jpeg, image/jpg" style={{ display: 'none' }} onChange={onChangeFile} />
                    <input id="filePdf" type="file" accept="application/pdf" style={{ display: 'none' }} onChange={onChangeFile} />
                </>
            );
        }
        return (
            <ContainerBox marginTop="20px">
                <TextField
                    fullWidth
                    label="Buscar por usuários"
                    onChange={SearchUsers}
                    value={search}
                />
                <AdjustFilters>
                    <AdjustInput>
                        <LabelInput>Assinante</LabelInput>
                        <Select
                            value={filters.signature}
                            // eslint-disable-next-line max-len
                            onChange={(txt) => setFilters({ ...filters, signature: String(txt.target.value) })}
                            style={{ height: 35, width: 150 }}
                        >
                            <MenuItem value="">TODOS</MenuItem>
                            <MenuItem value="yes">SIM</MenuItem>
                            <MenuItem value="no">NÃO</MenuItem>
                        </Select>
                    </AdjustInput>
                    <AdjustInput>
                        <LabelInput>Data inicial</LabelInput>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                value={filters.initialDate}
                                onChange={(txt) => {
                                    setFilters({ ...filters, initialDate: String(txt) });
                                }}
                                maxDate={new Date()}
                                invalidDateMessage=""
                                autoOk
                                style={{ height: 35, width: 150 }}
                            />
                        </MuiPickersUtilsProvider>
                    </AdjustInput>
                    <AdjustInput>
                        <LabelInput>Data final</LabelInput>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                value={filters.finalDate}
                                onChange={(txt) => {
                                    setFilters({ ...filters, finalDate: String(txt) });
                                }}
                                maxDate={new Date()}
                                invalidDateMessage=""
                                autoOk
                                style={{ height: 35, width: 150 }}
                            />
                        </MuiPickersUtilsProvider>
                    </AdjustInput>
                    <AdjustInput>
                        <LabelInput>Qual sistema?</LabelInput>
                        <Select
                            value={filters.applicationType}
                            // eslint-disable-next-line max-len
                            onChange={(txt) => setFilters({ ...filters, applicationType: String(txt.target.value) })}
                            style={{ height: 35, width: 150 }}
                        >
                            <MenuItem value="">TODOS</MenuItem>
                            {constants.namesSystem.map((v: any) => (
                                <MenuItem value={v}>{v}</MenuItem>
                            ))}
                        </Select>
                    </AdjustInput>
                    <AdjustInput>
                        <LabelInput style={{ fontSize: 13 }}>Usuários que cancelaram</LabelInput>
                        <Select
                            value={filters.cancel}
                            onChange={(txt) => {
                                setFilters({ ...filters, cancel: String(txt.target.value) });
                            }}
                            style={{ height: 38, width: 150 }}
                        >
                            <MenuItem value="">TODOS</MenuItem>
                            <MenuItem value="yes">SIM</MenuItem>
                            <MenuItem value="no">NÃO</MenuItem>
                        </Select>
                    </AdjustInput>
                    <TextField
                        label="Progresso inicial"
                        onChange={(txt) => {
                            const onlyNumber = txt.target.value.replace(/\D/g, '');
                            if (Number(onlyNumber) < 100 && Number(onlyNumber) >= 0) {
                                setFilters({ ...filters, progressInitial: onlyNumber });
                            } else {
                                alert('Informe um progresso inicial de 0 a 99.');
                            }
                        }}
                        value={filters.progressInitial}
                        style={{ height: 40, width: 150 }}
                    />
                    <TextField
                        label="Progresso final"
                        // eslint-disable-next-line max-len
                        onChange={(txt) => {
                            const onlyNumber = txt.target.value.replace(/\D/g, '');
                            if (Number(onlyNumber) <= 100 && Number(onlyNumber) > 0) {
                                setFilters({ ...filters, progressFinal: onlyNumber });
                            } else {
                                alert('Informe um progresso inicial de 1 a 100.');
                            }
                        }}
                        value={filters.progressFinal}
                    />
                </AdjustFilters>
                <ContainerHeader>
                    <TitleBox>Lista de usuários</TitleBox>
                </ContainerHeader>
                <ContainerOptions>
                    <TitleOption width="10%" />
                    <TitleOption>Nome</TitleOption>
                    <TitleOption>Email</TitleOption>
                </ContainerOptions>
                <LineSeparator />
                <FlatList list={allUsers} renderItem={RenderListItemsUsers} />
                <ContainerPaginator>
                    <Pagination
                        count={qtdPages}
                        page={page}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChangePageUsers}
                        color="primary"
                    />
                </ContainerPaginator>
            </ContainerBox>
        );
    };

    const RenderUsersPage = () => {
        if (!localStorage.getItem('existUser')) {
            return <Redirect to="/" />;
        }
        return (
            <Container>
                <ModalLoading activeLoading={activeLoading} />
                <DrawerNavigation />
                <ContainerBox>
                    <ContainerHeader>
                        <TitleBox>Lista de disparador</TitleBox>
                        <Fab
                            variant="extended"
                            color="primary"
                            onClick={tradeModalTrigger}
                            style={adjustStyleFab}
                        >
                            <Add />
                            Adicionar disparador
                        </Fab>
                    </ContainerHeader>
                    <ContainerOptions>
                        <TitleOption>Identificador</TitleOption>
                        <TitleOption>Data de disparo</TitleOption>
                    </ContainerOptions>
                    <LineSeparator />
                    <FlatList list={allTrigger} renderItem={RenderListTrigger} />
                    <ContainerPaginator>
                        <Pagination
                            count={qtdPagesTrigger}
                            page={pageTrigger}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChangePageTrigger}
                            color="primary"
                        />
                    </ContainerPaginator>
                </ContainerBox>
                <Modal
                    disableBackdropClick
                    disableEscapeKeyDown
                    onClose={tradeModalTrigger}
                    open={openModal}
                >
                    <ContainerModal>
                        <ContainerBoxModal>
                            {renderFrame()}
                            <ContainerOptionsInput marginTop="40px">
                                <Button
                                    color="primary"
                                    startIcon={RenderIconGoBack()}
                                    onClick={tradeModalTrigger}
                                    variant="contained"
                                >
                                    {idFrame === 1 ? 'Fechar' : 'Voltar'}
                                </Button>
                                <Button
                                    color={validationBackground()}
                                    disabled={validationDisabled()}
                                    endIcon={EndIcon()}
                                    onClick={idFrame === 1 ? nextStep : createTrigger}
                                    variant="contained"
                                >
                                    {/* eslint-disable-next-line no-nested-ternary */}
                                    {idFrame === 1 ? 'Próximo' : (triggerActual?.id ? 'Editar disparador' : 'Criar disparador')}
                                </Button>
                            </ContainerOptionsInput>
                        </ContainerBoxModal>
                    </ContainerModal>
                </Modal>
            </Container>
        );
    };

    return RenderUsersPage();
};

export default UsersPage;
