// importando bibliotecas
import firebase from 'firebase';

// Environment - Staging
// const firebaseStaging = {
//     apiKey: 'AIzaSyAEjl2mjobhnhasp9FGuNKY6gxh9xfrrA8',
//     authDomain: 'idiomas-tec-backup.firebaseapp.com',
//     databaseURL: 'https://idiomas-tec-backup-default-rtdb.firebaseio.com',
//     projectId: 'idiomas-tec-backup',
//     storageBucket: 'idiomas-tec-backup.appspot.com',
//     messagingSenderId: '321948151165',
//     appId: '1:321948151165:web:8e15610803001b3352f014',
//     measurementId: 'G-B0YDP7H8M9',
// };

// Environment - Production
const firebaseProduction = {
    apiKey: 'AIzaSyApJ9gF9APPVdtxIWZ6oNmF4ittFU425hk',
    authDomain: 'ingles-tec-ae157.firebaseapp.com',
    databaseURL: 'https://ingles-tec-ae157-default-rtdb.firebaseio.com',
    projectId: 'ingles-tec-ae157',
    storageBucket: 'ingles-tec-ae157.appspot.com',
    messagingSenderId: '518519320691',
    appId: '1:518519320691:web:91659b468e507d5823ba06',
    measurementId: 'G-FJJKFB458N',
};

firebase.initializeApp(firebaseProduction);

export default firebase;
