/* eslint-disable @typescript-eslint/no-explicit-any */
// libraries
import React from 'react';

// js
import { DataFileModuleInterface } from '../Modules/modulesInterfaces';
import { AllPlansInterfaces } from './plansConstants';

const RenderFile = (id: string, allPlans: AllPlansInterfaces): string => {
    if (id === '1f5020aa-571c-4ae5-bc7e-898f11ece7a2') {
        return typeof allPlans.imageFree === 'string' ? allPlans.imageFree : allPlans.imageFree.file;
    }
    if (id === '2c587a99-6f1b-4d86-9913-2a58f2ec192d') {
        return typeof allPlans.imageMonth === 'string' ? allPlans.imageMonth : allPlans.imageMonth.file;
    }
    if (id === '6df27d12-5f9a-476d-9694-f9628ca3d4bc') {
        return typeof allPlans.imageSemester === 'string' ? allPlans.imageSemester : allPlans.imageSemester.file;
    }
    if (id === '84a8614f-e9e9-48e4-8eeb-e054a7241f6c') {
        return typeof allPlans.imageYear === 'string' ? allPlans.imageYear : allPlans.imageYear.file;
    }

    return '';
};

const RenderName = (id: string, allPlans: AllPlansInterfaces): string => {
    if (id === '1f5020aa-571c-4ae5-bc7e-898f11ece7a2') {
        return allPlans.nameFree || '';
    }
    if (id === '2c587a99-6f1b-4d86-9913-2a58f2ec192d') {
        return allPlans.nameMonth || '';
    }
    if (id === '6df27d12-5f9a-476d-9694-f9628ca3d4bc') {
        return allPlans.nameSemester || '';
    }
    if (id === '84a8614f-e9e9-48e4-8eeb-e054a7241f6c') {
        return allPlans.nameYear || '';
    }

    return '';
};

const RenderDescription = (id: string, allPlans: AllPlansInterfaces): string => {
    if (id === '1f5020aa-571c-4ae5-bc7e-898f11ece7a2') {
        return allPlans.descriptionFree || '';
    }
    if (id === '2c587a99-6f1b-4d86-9913-2a58f2ec192d') {
        return allPlans.descriptionMonth || '';
    }
    if (id === '6df27d12-5f9a-476d-9694-f9628ca3d4bc') {
        return allPlans.descriptionSemester || '';
    }
    if (id === '84a8614f-e9e9-48e4-8eeb-e054a7241f6c') {
        return allPlans.descriptionYear || '';
    }

    return '';
};

const onChangeName = (
    id: string,
    allPlans: AllPlansInterfaces,
    setAllPlans: (v: AllPlansInterfaces) => void,
    txt: React.ChangeEvent<HTMLInputElement>,
): any => {
    const { value } = txt.target;
    if (id === '1f5020aa-571c-4ae5-bc7e-898f11ece7a2') {
        setAllPlans({ ...allPlans, nameFree: value });
    }
    if (id === '2c587a99-6f1b-4d86-9913-2a58f2ec192d') {
        setAllPlans({ ...allPlans, nameMonth: value });
    }
    if (id === '6df27d12-5f9a-476d-9694-f9628ca3d4bc') {
        setAllPlans({ ...allPlans, nameSemester: value });
    }
    if (id === '84a8614f-e9e9-48e4-8eeb-e054a7241f6c') {
        setAllPlans({ ...allPlans, nameYear: value });
    }
};

const onChangeDescription = (
    id: string,
    allPlans: AllPlansInterfaces,
    setAllPlans: (v: AllPlansInterfaces) => void,
    txt: React.ChangeEvent<HTMLInputElement>,
): any => {
    const { value } = txt.target;
    if (id === '1f5020aa-571c-4ae5-bc7e-898f11ece7a2') {
        setAllPlans({ ...allPlans, descriptionFree: value });
    }
    if (id === '2c587a99-6f1b-4d86-9913-2a58f2ec192d') {
        setAllPlans({ ...allPlans, descriptionMonth: value });
    }
    if (id === '6df27d12-5f9a-476d-9694-f9628ca3d4bc') {
        setAllPlans({ ...allPlans, descriptionSemester: value });
    }
    if (id === '84a8614f-e9e9-48e4-8eeb-e054a7241f6c') {
        setAllPlans({ ...allPlans, descriptionYear: value });
    }
};

const onChangeImage = (
    id: string,
    allPlans: AllPlansInterfaces,
    setAllPlans: (v: AllPlansInterfaces) => void,
    initialFile: DataFileModuleInterface,
): any => {
    if (id === '1f5020aa-571c-4ae5-bc7e-898f11ece7a2') {
        setAllPlans({ ...allPlans, imageFree: initialFile });
    }
    if (id === '2c587a99-6f1b-4d86-9913-2a58f2ec192d') {
        setAllPlans({ ...allPlans, imageMonth: initialFile });
    }
    if (id === '6df27d12-5f9a-476d-9694-f9628ca3d4bc') {
        setAllPlans({ ...allPlans, imageSemester: initialFile });
    }
    if (id === '84a8614f-e9e9-48e4-8eeb-e054a7241f6c') {
        setAllPlans({ ...allPlans, imageYear: initialFile });
    }
};

export default {
    RenderFile,
    RenderName,
    RenderDescription,
    onChangeName,
    onChangeDescription,
    onChangeImage,
};
