// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';
import consts from '../../utils/consts';

// interfaces
interface InfoOptionInterfaces {
    width?: number | string,
}

// styles
export const Container = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
`;

export const ContainerBox = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    border-radius: 5px;
    box-shadow: 1px 1px 3px gray;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 80px;
    min-width: 1000px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
`;

export const ContainerMore = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 18%;
`;

export const ContainerOptions = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
`;

export const InfoOption = styled.h1<InfoOptionInterfaces>`
    color: ${colors.basic.black};
    font-size: 14px;
    font-weight: ${consts.getFontWeight('Lato-Light')};
    width: ${(props) => (props.width || '19%')};
    word-wrap: break-word;
`;

export const LineSeparator = styled.div`
    background-color: ${colors.basic.gray};
    height: 1px;
    margin-top: 20px;
    opacity: 0.3;
    width: 100%;
`;

export const TitleBox = styled.h1`
    color: ${colors.basic.black};
    font-size: 22px;
    font-weight: ${consts.getFontWeight('Lato-Bold')};
    margin-top: 20px;
`;

export const TitleOption = styled.h1`
    color: ${colors.basic.black};
    font-size: 17px;
    font-weight: ${consts.getFontWeight('Lato-Regular')};
    width: 19%;
`;
