// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';
import consts from '../../utils/consts';

interface ContainerAdjustIconEditInterface {
    right?: string,
}

interface ContainerOptionsInterface {
    marginTop?: string,
    maxWidth?: string,
    positionItemHorizontal?: string,
    positionItemVertical?: string,
    wrap?: string,
    width?: string,
}

// styles
export const Container = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
`;

export const ContainerAdjustIconEdit = styled.div<ContainerAdjustIconEditInterface>`
    align-items: center;
    background-color: ${colors.theme.smoke};
    border-radius: 15px;
    bottom: 7px;
    display: flex;
    height: 30px;
    justify-content: center;
    opacity: 0.7;
    position: absolute;
    right: ${(props) => (props.right || '8px')};
    width: 30px;
`;

export const ContainerBox = styled.div`
    align-items: flex-end;
    background-color: ${colors.basic.white};
    border-radius: 5px;
    box-shadow: 1px 1px 3px gray;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 80px;
    width: 80%;
    max-width: 1000px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
`;

export const ContainerOptions = styled.div<ContainerOptionsInterface>`
    align-items: ${(props) => (props.positionItemVertical || 'center')};
    background-color: ${colors.basic.white};
    display: flex;
    flex-wrap: ${(props) => (props.wrap || 'nowrap')};
    justify-content: ${(props) => (props.positionItemHorizontal || 'space-between')};
    margin-top: ${(props) => (props.marginTop || '0px')};
    width: ${(props) => (props.width || 'auto')};
    max-width: ${(props) => (props.maxWidth || 'none')};
`;

export const ImagePlans = styled.img`
    height: 50px;
    width: 50px;
`;

export const InputNullSelectedPhoto = styled.input`
    align-items: center;
    cursor: pointer;
    height: 112px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 116px;
`;

export const Logo = styled.img`
    height: 100px;
    width: 100px;
`;

export const TitlePlans = styled.h1`
    color: ${colors.basic.gray};
    font-size: 30px;
    font-weight: ${consts.getFontWeight('Lato-Bold')};
    padding-bottom: 30px;
`;
