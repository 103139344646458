/* eslint-disable no-alert */
// js
import api from './api';

async function ListTrigger(page: number, isSend: boolean | null, token: string): Promise<void> {
    try {
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        let baseURL = `/send/email?page=${page}&limit=10`;
        if (typeof isSend === 'boolean') {
            baseURL = `${baseURL}&isSend=${isSend}`;
        }
        const response = await api.get(baseURL, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function CreateTrigger(
    title: string,
    description: string,
    attachments: Array<string>,
    startAt: string,
    idsUsers: Array<string>,
    token: string,
): Promise<void> {
    try {
        let body: any = {
            title,
            description,
            startAt,
            idsUsers,
        };
        if (attachments.length) {
            body = {
                ...body,
                attachments,
            };
        }
        const configs = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json',
            },
        };
        const response = await api.post('/send/email', body, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function EditTrigger(
    title: string,
    description: string,
    attachments: Array<string>,
    startAt: string,
    idsUsers: Array<string>,
    idSend: number,
    token: string,
): Promise<void> {
    try {
        let body: any = {
            title,
            description,
            startAt,
            idsUsers,
        };
        if (attachments.length) {
            body = {
                ...body,
                attachments,
            };
        }
        const configs = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json',
            },
        };
        const response = await api.put(`/send/email?idSend=${idSend}`, body, configs);
        return response.data;
    } catch (error: any) {
        // alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function DelTrigger(idSend: string, token: string): Promise<void> {
    try {
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const response = await api.delete(`/send/email?idSend=${idSend}`, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function VerifyDateTrigger(startAt: string, token: string): Promise<void> {
    try {
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const response = await api.get(`/send/evaluation?startAt=${startAt}`, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function GetNextTrigger(token: string): Promise<void> {
    try {
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const response = await api.get('/send/schedules', configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

export default {
    ListTrigger,
    CreateTrigger,
    EditTrigger,
    DelTrigger,
    VerifyDateTrigger,
    GetNextTrigger,
};
