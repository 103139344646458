/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-plusplus */
// libraries
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Pagination } from '@material-ui/lab';
import {
    Button,
    Fab, MenuItem, Modal, Select, TextField,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { Redirect } from 'react-router-dom';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// js
import { CloudDownload, NavigateBefore } from '@material-ui/icons';
import { lightFormat } from 'date-fns';
import {
    AdjustFilters,
    AdjustInput,
    Container,
    ContainerBox,
    ContainerBoxModal,
    ContainerBoxModalInline,
    ContainerHeader,
    ContainerModal,
    ContainerMore,
    ContainerOptions,
    ContainerOptionsInput,
    ContainerPaginator,
    ContainerTitle,
    InfoOption,
    LabelInput,
    LineSeparator,
    TitleBox,
    TitleOption,
} from './usersStyles';

// components (navigation)
import DrawerNavigation from '../../routes/DrawerNavigation/DrawerNavigation';
import ModalLoading from '../../components/ModalLoading';
import users from '../../services/users';
import functions from '../../utils/functions';
import constants from '../../configs/constants';

// interfaces
interface initialFiltersInterfaces {
    search: string;
    signature: string;
    initialDate: string;
    finalDate: string;
    applicationType: any;
    cancel: string;
    progressInitial: string;
    progressFinal: string;
}

const UsersPage: React.FC = () => {
    // constants
    const token: any = String(localStorage.getItem('token') || '');
    const adjustStyleFab = { width: '30%' };
    const initialFilters: initialFiltersInterfaces = {
        search: '',
        signature: '',
        initialDate: '',
        finalDate: '',
        applicationType: '',
        cancel: '',
        progressInitial: '',
        progressFinal: '',
    };

    // states
    const [allUsers, setAllUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [activeLoading, setActiveLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageModal, setPageModal] = useState(1);
    const [qtdPages, setQtdPages] = useState(1);
    const [qtdPagesModal, setQtdPagesModal] = useState(1);
    const [filters, setFilters] = useState<initialFiltersInterfaces>(initialFilters);
    const [openModal, setOpenModal] = useState(false);
    const [allUsersModal, setAllUsersModal] = useState([]);

    const GetListUsers = async () => {
        const listUsers: any = await users.ListUsers(page, search, token);
        if (listUsers) {
            setAllUsers(listUsers?.result?.list || []);
            setQtdPages(listUsers?.result?.qtdPages || 1);
            setPage(1);
        }
    };

    // methods
    const handleChange = async (event: any) => {
        setActiveLoading(true);
        const infoObj = event.target.value;
        const deleteUser: any = await users.DeleteUsers(infoObj.idUser, token);
        setActiveLoading(false);
        if (deleteUser) {
            GetListUsers();
            alert(deleteUser.message);
        }
    };

    const handleChangePage = async (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        const listUsers: any = await users.ListUsers(value, search, token);
        if (listUsers) {
            setAllUsers(listUsers?.result?.list || []);
            setQtdPages(listUsers?.result?.qtdPages || 1);
            // window.scrollTo({
            //     top: 0,
            //     left: 0,
            //     behavior: 'smooth',
            // });
        }
    };

    const ExportUsers = async () => {
        setActiveLoading(true);
        const formatDateInitial = filters.initialDate ? lightFormat(new Date(filters.initialDate), 'yyyy-MM-dd HH:mm:ss.SSS') : '';
        const formatDateFinal = filters.progressFinal ? lightFormat(new Date(filters.progressFinal), 'yyyy-MM-dd HH:mm:ss.SSS') : '';
        const activeExport: any = await users.ListUserSearch(
            1,
            filters.search,
            filters.applicationType,
            Number(filters.progressInitial),
            Number(filters.progressFinal),
            filters.signature,
            filters.cancel,
            formatDateInitial,
            formatDateFinal,
            token,
            'export',
        );
        setActiveLoading(false);
        await functions.getDownloadRemoteFile(activeExport?.result?.url);
    };

    const closedModal = () => {
        setOpenModal(!openModal);
    };

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            const listUsers: any = await users.ListUsers(1, search, token);
            if (listUsers) {
                setAllUsers(listUsers?.result?.list || []);
                setQtdPages(listUsers?.result?.qtdPages || 1);
                setPage(1);
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [search]);

    // renders
    const RenderListItems = (item: any) => {
        const {
            name,
            email,
            lessonsCompleted,
            percentageLevel,
        } = item;
        const valueItem = { ...item, type: 'DELETE' };

        return (
            <>
                <ContainerOptions>
                    <InfoOption>{name}</InfoOption>
                    <InfoOption>{email}</InfoOption>
                    <InfoOption>{lessonsCompleted}</InfoOption>
                    <ContainerMore>
                        <InfoOption>{percentageLevel}</InfoOption>
                        <Select value="" onChange={handleChange}>
                            <MenuItem value={valueItem}>Deletar usuário</MenuItem>
                        </Select>
                    </ContainerMore>
                </ContainerOptions>
                <LineSeparator />
            </>
        );
    };

    const RenderListItemsUsers = (item: any) => {
        const {
            idUser,
            name,
            email,
        } = item;

        // const onChangeCheckBox = () => {
        //     if (allIds.includes(idUser)) {
        //         const removeEmail = allIds.filter((v) => v !== idUser);
        //         setAllIds(removeEmail);
        //     } else {
        //         setAllIds([...allIds, idUser]);
        //     }
        // };

        return (
            <div key={idUser}>
                <ContainerOptions>
                    {/* <Separator>
                        <Checkbox color="primary" checked={allIds.includes(idUser)} onChange={onChangeCheckBox} />
                    </Separator> */}
                    <InfoOption width="45%">{name}</InfoOption>
                    <InfoOption width="45%">{email}</InfoOption>
                </ContainerOptions>
                <LineSeparator />
            </div>
        );
    };

    const SearchUsers = (txt: any) => {
        setSearch(txt.target.value);
    };

    const SearchUsersModal = (txt: any) => {
        setFilters({ ...filters, search: txt.target.value });
    };

    const handleChangePageUsers = async (event: React.ChangeEvent<unknown>, value: number) => {
        setPageModal(value);
        const listUsers: any = await users.ListUsers(value, filters.search, token);
        if (listUsers) {
            setAllUsersModal(listUsers?.result?.list || []);
            setQtdPagesModal(listUsers?.result?.qtdPages || 1);
        }
    };

    const getNewUsers = async () => {
        const formatDateInitial = filters.initialDate ? lightFormat(new Date(filters.initialDate), 'yyyy-MM-dd HH:mm:ss.SSS') : '';
        const formatDateFinal = filters.progressFinal ? lightFormat(new Date(filters.progressFinal), 'yyyy-MM-dd HH:mm:ss.SSS') : '';
        const listUsers: any = await users.ListUserSearch(
            1,
            filters.search,
            filters.applicationType,
            Number(filters.progressInitial),
            Number(filters.progressFinal),
            filters.signature,
            filters.cancel,
            formatDateInitial,
            formatDateFinal,
            token,
            '',
        );
        if (listUsers) {
            setAllUsersModal(listUsers?.result?.list || []);
            setQtdPagesModal(listUsers?.result?.qtdPages || 1);
            setPageModal(1);
        }
    };

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            if (filters.progressInitial && filters.progressFinal) {
                if (Number(filters.progressInitial) < Number(filters.progressFinal)) {
                    getNewUsers();
                } else {
                    alert('Informe uma data inicial inferior a data final.');
                }
            } else {
                getNewUsers();
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [filters]);

    const RenderUsersPage = () => {
        if (!localStorage.getItem('existUser')) {
            return <Redirect to="/" />;
        }
        return (
            <Container>
                <ModalLoading activeLoading={activeLoading} />
                <DrawerNavigation />
                <ContainerBox>
                    <TextField
                        fullWidth
                        label="Buscar por usuários"
                        onChange={SearchUsers}
                        value={search}
                    />
                    <ContainerTitle>
                        <TitleBox>Lista de usuários</TitleBox>
                        <Fab
                            variant="extended"
                            color="primary"
                            onClick={closedModal}
                            style={adjustStyleFab}
                        >
                            <CloudDownload style={{ marginRight: 10 }} />
                            Exportar usuários
                        </Fab>
                    </ContainerTitle>
                    <ContainerOptions>
                        <TitleOption>Name</TitleOption>
                        <TitleOption>Email</TitleOption>
                        <TitleOption>Lições completas</TitleOption>
                        <TitleOption>Nível</TitleOption>
                    </ContainerOptions>
                    <LineSeparator />
                    <FlatList list={allUsers} renderItem={RenderListItems} />
                    <ContainerPaginator>
                        <Pagination
                            count={qtdPages}
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChangePage}
                            color="primary"
                        />
                    </ContainerPaginator>
                </ContainerBox>
                <Modal
                    disableBackdropClick
                    disableEscapeKeyDown
                    onClose={closedModal}
                    open={openModal}
                >
                    <ContainerModal>
                        <ContainerBoxModal>
                            <ContainerBoxModalInline marginTop="20px">
                                <TextField
                                    fullWidth
                                    label="Buscar por usuários"
                                    onChange={SearchUsersModal}
                                    value={filters.search}
                                />
                                <AdjustFilters>
                                    <AdjustInput>
                                        <LabelInput>Assinante</LabelInput>
                                        <Select
                                            value={filters.signature}
                                            // eslint-disable-next-line max-len
                                            onChange={(txt) => setFilters({ ...filters, signature: String(txt.target.value) })}
                                            style={{ height: 35, width: 150 }}
                                        >
                                            <MenuItem value="">TODOS</MenuItem>
                                            <MenuItem value="yes">SIM</MenuItem>
                                            <MenuItem value="no">NÃO</MenuItem>
                                        </Select>
                                    </AdjustInput>
                                    <AdjustInput>
                                        <LabelInput>Data inicial</LabelInput>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                value={filters.initialDate}
                                                onChange={(txt) => {
                                                    setFilters({ ...filters, initialDate: String(txt) });
                                                }}
                                                maxDate={new Date()}
                                                invalidDateMessage=""
                                                autoOk
                                                style={{ height: 35, width: 150 }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </AdjustInput>
                                    <AdjustInput>
                                        <LabelInput>Data final</LabelInput>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                value={filters.finalDate}
                                                onChange={(txt) => {
                                                    setFilters({ ...filters, finalDate: String(txt) });
                                                }}
                                                maxDate={new Date()}
                                                invalidDateMessage=""
                                                autoOk
                                                style={{ height: 35, width: 150 }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </AdjustInput>
                                    <AdjustInput>
                                        <LabelInput>Qual sistema?</LabelInput>
                                        <Select
                                            value={filters.applicationType}
                                            // eslint-disable-next-line max-len
                                            onChange={(txt) => setFilters({ ...filters, applicationType: String(txt.target.value) })}
                                            style={{ height: 35, width: 150 }}
                                        >
                                            <MenuItem value="">TODOS</MenuItem>
                                            {constants.namesSystem.map((v: any) => (
                                                <MenuItem value={v}>{v}</MenuItem>
                                            ))}
                                        </Select>
                                    </AdjustInput>
                                    <AdjustInput>
                                        <LabelInput style={{ fontSize: 13 }}>Usuários que cancelaram</LabelInput>
                                        <Select
                                            value={filters.cancel}
                                            onChange={(txt) => {
                                                setFilters({ ...filters, cancel: String(txt.target.value) });
                                            }}
                                            style={{ height: 38, width: 150 }}
                                        >
                                            <MenuItem value="">TODOS</MenuItem>
                                            <MenuItem value="yes">SIM</MenuItem>
                                            <MenuItem value="no">NÃO</MenuItem>
                                        </Select>
                                    </AdjustInput>
                                    <TextField
                                        label="Progresso inicial"
                                        onChange={(txt) => {
                                            const onlyNumber = txt.target.value.replace(/\D/g, '');
                                            if (Number(onlyNumber) < 100 && Number(onlyNumber) >= 0) {
                                                setFilters({ ...filters, progressInitial: onlyNumber });
                                            } else {
                                                alert('Informe um progresso inicial de 0 a 99.');
                                            }
                                        }}
                                        value={filters.progressInitial}
                                        style={{ height: 40, width: 150 }}
                                    />
                                    <TextField
                                        label="Progresso final"
                                        // eslint-disable-next-line max-len
                                        onChange={(txt) => {
                                            const onlyNumber = txt.target.value.replace(/\D/g, '');
                                            if (Number(onlyNumber) <= 100 && Number(onlyNumber) > 0) {
                                                setFilters({ ...filters, progressFinal: onlyNumber });
                                            } else {
                                                alert('Informe um progresso inicial de 1 a 100.');
                                            }
                                        }}
                                        value={filters.progressFinal}
                                    />
                                </AdjustFilters>
                                <ContainerHeader>
                                    <TitleBox>Lista de usuários</TitleBox>
                                </ContainerHeader>
                                <ContainerOptions>
                                    {/* <TitleOption width="10%" /> */}
                                    <TitleOption width="45%">Nome</TitleOption>
                                    <TitleOption width="45%">Email</TitleOption>
                                </ContainerOptions>
                                <LineSeparator />
                                <FlatList list={allUsersModal} renderItem={RenderListItemsUsers} />
                                <ContainerPaginator>
                                    <Pagination
                                        count={qtdPagesModal}
                                        page={pageModal}
                                        variant="outlined"
                                        shape="rounded"
                                        onChange={handleChangePageUsers}
                                        color="primary"
                                    />
                                </ContainerPaginator>
                            </ContainerBoxModalInline>
                            <ContainerOptionsInput marginTop="40px">
                                <Button
                                    color="primary"
                                    endIcon={() => <NavigateBefore />}
                                    onClick={closedModal}
                                    variant="contained"
                                >
                                    Fechar
                                </Button>
                                <Button
                                    color="primary"
                                    endIcon={() => <CloudDownload />}
                                    onClick={ExportUsers}
                                    variant="contained"
                                >
                                    Exportar usuários
                                </Button>
                            </ContainerOptionsInput>
                        </ContainerBoxModal>
                    </ContainerModal>
                </Modal>
            </Container>
        );
    };

    return RenderUsersPage();
};

export default UsersPage;
