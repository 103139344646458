/* eslint-disable no-alert */
// libraries
import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Add } from '@material-ui/icons';
import {
    Fab,
    MenuItem,
    Select,
    TextField,
    Switch,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';

// js
import {
    Container,
    ContainerBox,
    ContainerMore,
    ContainerOptions,
    InfoOption,
    LineSeparator,
    TitleBox,
    TitleOption,
} from './modulesStyles';
import modules from '../../services/modules';

// components (navigation)
import DrawerNavigation from '../../routes/DrawerNavigation/DrawerNavigation';
import ModalAddModules from './components/ModalAddModules';

// interfaces
import type { DataModuleInterface } from './modulesInterfaces';
import { ContainerPaginator } from '../Users/usersStyles';
import tasks from '../../services/tasks';
import auth from '../../services/auth';
import category from '../../services/category';

const ModulesPage: React.FC = () => {
    // constants
    const token: any = String(localStorage.getItem('token') || '');
    const tokenRefresh: any = String(localStorage.getItem('tokenRefresh') || '');
    const idUser: any = String(localStorage.getItem('idUser') || '');
    const initialDataModule: DataModuleInterface = {
        amountTask: '',
        description: '',
        title: '',
        signed: '',
        environment: '',
        image: '',
        category: '',
    };

    // states
    const [allModules, setAllModules] = useState([]);
    const [allTasks, setAllTasks] = useState([]);
    const [dataFile, setDataFile] = useState<string>('');
    const [dataModule, setDataModule] = useState(initialDataModule);
    const [openModal, setOpenModal] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [qtdPages, setQtdPages] = useState(1);
    const [allCategory, setAllCategory] = useState([]);

    // api
    const GetListModules = async () => {
        const getList: any = await modules.ListModules(1, search, token);
        if (getList) {
            setAllModules(getList?.result?.list);
        }
    };

    const GetListCategory = async () => {
        const listCategory: any = await category.ListCategory(1, '', token, '100');
        setAllCategory(listCategory?.result?.list || []);
    };

    // methods
    const handleChange = async (event: any) => {
        const infoObj = event.target.value;
        if (infoObj.type === 'DELETE') {
            const deleteModule: any = await modules.DeleteModules(infoObj?.id, token);
            if (deleteModule) {
                alert(deleteModule?.message);
                GetListModules();
            }
        } else if (infoObj.type === 'EDITAR') {
            const getTasks: any = await tasks.ListTasks(infoObj?.id, idUser, token);
            if (getTasks) {
                setAllTasks(getTasks?.result?.list);
                delete infoObj?.type;
                setDataModule({
                    amountTask: infoObj?.amountTask,
                    description: infoObj?.description,
                    id: infoObj?.id,
                    title: infoObj?.title,
                    signed: infoObj?.signed,
                    environment: infoObj?.environment,
                    image: infoObj?.image,
                    category: infoObj?.idModuleGroup,
                });
                // setDataOldModule(infoObj);
                setDataFile(infoObj?.image);
                setOpenModal(true);
            }
        }
    };

    const SearchModules = (txt: any) => {
        const infoSearch = txt.target.value;
        setSearch(infoSearch);
    };

    const OpenModalModules = () => {
        setOpenModal(true);
    };

    const handleChangePage = async (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        const getList: any = await modules.ListModules(value, search, token);
        if (getList) {
            setAllModules(getList?.result?.list || []);
            setQtdPages(getList?.result?.qtdPages || 1);
            // window.scrollTo({
            //     top: 0,
            //     left: 0,
            //     behavior: 'smooth',
            // });
        }
    };

    const GetRefreshToken = async () => {
        const refresh = await auth.RefreshToken(tokenRefresh);
        if (refresh) {
            localStorage.setItem('token', refresh.token);
        }
    };

    // useEffect
    useEffect(() => {
        GetListModules();
        GetListCategory();
        GetRefreshToken();
    }, [openModal]);

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            const getList: any = await modules.ListModules(1, search, token);
            if (getList) {
                setAllModules(getList?.result?.list);
                setQtdPages(getList?.result?.qtdPages || 1);
                setPage(1);
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [search]);

    // renders
    const RenderListItems = (item: any) => {
        const {
            id,
            title,
            description,
            amountTask,
            environment,
        } = item;
        const valueItemPut = { ...item, type: 'EDITAR' };
        const valueItemDelete = { ...item, type: 'DELETE' };

        const onChangeEnvironment = async () => {
            const tradeEnvironment: any = await modules.TradeEnvironment(environment === 'development' ? 'production' : 'development', id, token);
            if (tradeEnvironment) {
                alert(tradeEnvironment?.message);
                GetListModules();
            }
        };

        return (
            <>
                <ContainerOptions>
                    <InfoOption>{title}</InfoOption>
                    <InfoOption>{description}</InfoOption>
                    <ContainerMore>
                        <InfoOption>{amountTask}</InfoOption>
                        <Switch checked={environment !== 'development'} color="primary" onChange={onChangeEnvironment} />
                        <Select value="" onChange={handleChange}>
                            <MenuItem value={valueItemPut}>Editar módulo</MenuItem>
                            <MenuItem value={valueItemDelete}>Deletar módulo</MenuItem>
                        </Select>
                    </ContainerMore>
                </ContainerOptions>
                <LineSeparator />
            </>
        );
    };

    const RenderModulesPage = () => {
        const adjustStyleFab = { width: '50%' };

        if (!localStorage.getItem('existUser')) {
            return <Redirect to="/" />;
        }
        return (
            <Container>
                {ModalAddModules(
                    dataModule,
                    initialDataModule,
                    openModal,
                    setDataModule,
                    setOpenModal,
                    allTasks,
                    setAllTasks,
                    dataFile,
                    setDataFile,
                    allCategory,
                    // dataOldModule,
                )}
                <DrawerNavigation />
                <ContainerBox>
                    <TextField
                        fullWidth
                        label="Buscar por módulos"
                        onChange={SearchModules}
                        value={search}
                    />
                    <TitleBox>Lista de módulos</TitleBox>
                    <ContainerOptions>
                        <TitleOption>Título</TitleOption>
                        <TitleOption>Descrição</TitleOption>
                        <ContainerMore>
                            <TitleOption>Lições completas</TitleOption>
                            <Fab
                                variant="extended"
                                color="primary"
                                onClick={OpenModalModules}
                                style={adjustStyleFab}
                            >
                                <Add />
                                Adicionar módulos
                            </Fab>
                        </ContainerMore>
                    </ContainerOptions>
                    <LineSeparator />
                    <FlatList list={allModules} renderItem={RenderListItems} />
                    <ContainerPaginator>
                        <Pagination
                            count={qtdPages}
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChangePage}
                            color="primary"
                        />
                    </ContainerPaginator>
                </ContainerBox>
            </Container>
        );
    };

    return RenderModulesPage();
};

export default ModulesPage;
