const fontFamily = '"Lato", sans-serif';

const fontSize = {
    default: 22,
};

const getFontWeight = (family: 'Lato-Thin' | 'Lato-Light' | 'Lato-Regular' | 'Lato-Bold' | 'Lato-Black'): number => {
    if (family === 'Lato-Thin') {
        return 100;
    }
    if (family === 'Lato-Light') {
        return 300;
    }
    if (family === 'Lato-Regular') {
        return 400;
    }
    if (family === 'Lato-Bold') {
        return 700;
    }
    if (family === 'Lato-Black') {
        return 900;
    }
    return 0;
};

export default {
    fontFamily,
    fontSize,
    getFontWeight,
};
