import axios from 'axios';

import contants from '../configs/constants';

const baseURL = contants.baseUrl;

const api = axios.create({
    baseURL,
});

export default api;
