/* eslint-disable max-len */
// libraries
import React from 'react';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import {
    CheckCircle, Edit, NavigateBefore, Send,
} from '@material-ui/icons';

// js
import defaultSound from '../../../assets/images/defaultSound.png';
import imgDefault from '../../../assets/images/default.png';
import {
    ContainerAdjustIconEdit,
    ContainerOptions,
    Logo,
    TitleOption,
    InputNullSelectedPhoto,
} from '../modulesStyles';

// interfaces
import type { DataModuleInterface, OrderWordInterface } from '../modulesInterfaces';
import uploads from '../../../services/uploads';
import tasks from '../../../services/tasks';

const TaskOrderWord = (
    setIdModal: any,
    initialDataTaskOrderWord: OrderWordInterface,
    taskOrderWord: any,
    setTaskOrderWord: any,
    dataModule: DataModuleInterface,
) => {
    // constants
    const token: any = String(localStorage.getItem('token') || '');
    const adjustWidthSelectedActivities = { width: 200 };
    const widthDefault = { width: '49%' };

    // methods
    const BackScreenModal = () => {
        setIdModal(1);
        setTaskOrderWord(initialDataTaskOrderWord);
    };

    const CreateTask = async () => {
        const formatTask: any = {
            ...taskOrderWord,
            idTask: taskOrderWord.id,
            idModule: dataModule?.id,
        };
        delete formatTask?.id;
        if (formatTask?.idTask) {
            const editTask: any = await tasks.EditTask(formatTask, token);
            if (editTask) {
                BackScreenModal();
            }
        } else {
            delete formatTask?.idTask;
            const createTask: any = await tasks.CreateTask(formatTask, token);
            if (createTask) {
                BackScreenModal();
            }
        }
    };

    const onChangeTitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskOrderWord, title: txt.target.value };
            setTaskOrderWord(newDataTask);
        }
    };

    const onChangeSubtitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskOrderWord, subtitle: txt.target.value };
            setTaskOrderWord(newDataTask);
        }
    };

    const onChangePhraseInitial = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskOrderWord, phraseInitial: txt.target.value };
            setTaskOrderWord(newDataTask);
        }
    };

    const onChangePhraseFinal = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskOrderWord, phraseFinal: txt.target.value };
            setTaskOrderWord(newDataTask);
        }
    };

    const onChangeOptionOrder = (txt: any) => {
        const newDataTask = { ...taskOrderWord, order: txt.target.value };
        setTaskOrderWord(newDataTask);
    };

    const onChangeOptionSkipTask = (txt: any) => {
        const newDataTask = { ...taskOrderWord, skip: txt.target.value };
        setTaskOrderWord(newDataTask);
    };

    const renderQtdDisabled = () => {
        if (taskOrderWord?.id) {
            if (typeof taskOrderWord?.fileImg === 'string') {
                return Object.values(taskOrderWord).filter((v) => v !== '').length !== 10;
            }
            return Object.values(taskOrderWord).filter((v) => v !== '').length !== 9;
        }
        if (typeof taskOrderWord?.fileImg === 'string') {
            return Object.values(taskOrderWord).filter((v) => v !== '').length !== 9;
        }
        return Object.values(taskOrderWord).filter((v) => v !== '').length !== 8;
    };

    const validationDisabled = () => {
        const valueObject = renderQtdDisabled();
        return valueObject;
    };

    const renderQtdBackground = () => {
        const valueObject = Object.values(taskOrderWord).filter((v) => v !== '');
        if (taskOrderWord?.id) {
            if (typeof taskOrderWord?.fileImg === 'string') {
                return valueObject.length === 10;
            }
            return valueObject.length === 9;
        }
        if (typeof taskOrderWord?.fileImg === 'string') {
            return valueObject.length === 9;
        }
        return valueObject.length === 8;
    };

    const validationBackground = () => {
        const valueObject = renderQtdBackground();
        if (valueObject) {
            return 'primary';
        }

        return 'inherit';
    };

    const SelectedPhoto = async (event: any) => {
        if (event.target.files.length) {
            const newDataFiles = {
                eventFile: event.target.files[0],
                eventName: event.target.files[0].name,
                file: URL.createObjectURL(event.target.files[0]),
            };

            const upload: any = await uploads.UploadFile(newDataFiles.eventFile);
            if (upload) {
                setTaskOrderWord({
                    ...taskOrderWord,
                    fileImg: upload.result,
                });
                // eslint-disable-next-line no-param-reassign
                event.target.value = null;
            }
        }
    };

    // renders
    const RenderSelectedCorrectOptions = (skipTask: boolean) => {
        const validationOptions = {
            phraseInitial: taskOrderWord.phraseInitial,
            phraseFinal: taskOrderWord.phraseFinal,
        };
        const adjustButtonLogo = { marginBottom: 25, width: 200 };
        const adjustMarginBottom = { marginBottom: 10 };
        const conditionRender = Object.values(validationOptions).filter((v) => v !== '').length === 2;

        if (conditionRender && !skipTask) {
            const SelectedAudio = async (event: any) => {
                if (event.target.files.length) {
                    const newDataFiles = {
                        eventFile: event.target.files[0],
                        eventName: event.target.files[0].name,
                        file: URL.createObjectURL(event.target.files[0]),
                    };
                    const upload: any = await uploads.UploadFile(newDataFiles.eventFile);
                    if (upload) {
                        setTaskOrderWord({ ...taskOrderWord, file: upload.result });
                    }
                }
            };
            return (
                <>
                    <FormControl style={adjustWidthSelectedActivities}>
                        <InputLabel>Ordem</InputLabel>
                        <Select value={taskOrderWord.order} onChange={onChangeOptionOrder}>
                            <MenuItem value="Do português para o inglês">
                                Do português para o inglês
                            </MenuItem>
                            <MenuItem value="Do inglês para o português">
                                Do inglês para o português
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        style={adjustButtonLogo}
                        title={taskOrderWord?.file}
                    >
                        <Logo src={defaultSound} />
                        {taskOrderWord?.file ? (
                            <ContainerAdjustIconEdit right="40px" style={adjustMarginBottom}>
                                <CheckCircle color="inherit" />
                            </ContainerAdjustIconEdit>
                        ) : null}
                        <InputNullSelectedPhoto
                            accept=".mp3,audio/*"
                            onChange={SelectedAudio}
                            type="file"
                        />
                    </Button>
                    {typeof taskOrderWord?.fileImg === 'string' ? (
                        <Button style={adjustButtonLogo}>
                            <Logo src={taskOrderWord?.fileImg || imgDefault} />
                            <ContainerAdjustIconEdit>
                                <Edit color="primary" />
                            </ContainerAdjustIconEdit>
                            <InputNullSelectedPhoto
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={SelectedPhoto}
                                type="file"
                            />
                        </Button>
                    ) : null}
                </>
            );
        }

        if (conditionRender && skipTask) {
            return (
                <FormControl style={adjustWidthSelectedActivities}>
                    <InputLabel>Pular/Não pular</InputLabel>
                    <Select value={taskOrderWord.skip} onChange={onChangeOptionSkipTask}>
                        <MenuItem value="true">Pular</MenuItem>
                        <MenuItem value="false">Não pular</MenuItem>
                    </Select>
                </FormControl>
            );
        }

        return null;
    };

    const RenderInputsOptions = (label: string, value: string, onChange: any) => {
        const ValueActual = value;
        return (
            <TextField
                label={`${label} (${ValueActual.length}/200)`}
                onChange={onChange}
                style={widthDefault}
                value={value}
            />
        );
    };

    const RenderInputsTitles = (label: string, value: string, onChange: any) => {
        const ValueActual = value;
        return (
            <>
                <ContainerOptions marginTop="10px" />
                <TextField
                    fullWidth
                    label={`${label} (${ValueActual.length}/200)`}
                    onChange={onChange}
                    value={value}
                />
            </>
        );
    };

    const EndIcon = () => (
        <Send />
    );

    const RenderIconGoBack = () => (
        <NavigateBefore />
    );

    return (
        <>
            <ContainerOptions>
                <TitleOption width="100%">{typeof taskOrderWord?.fileImg === 'string' ? 'Criação da Atividade - Ordene as palavras com imagem' : 'Criação da Atividade - Ordene as palavras'}</TitleOption>
            </ContainerOptions>
            {RenderInputsTitles('Título da atividade', taskOrderWord.title, onChangeTitle)}
            {RenderInputsTitles('Subtítulo da atividade', taskOrderWord.subtitle, onChangeSubtitle)}
            <ContainerOptions marginTop="10px">
                {RenderInputsOptions('Frase inicial', taskOrderWord.phraseInitial, onChangePhraseInitial)}
                {RenderInputsOptions('Frase final', taskOrderWord.phraseFinal, onChangePhraseFinal)}
            </ContainerOptions>
            <ContainerOptions marginTop="10px" width="50%">
                {RenderSelectedCorrectOptions(false)}
                {RenderSelectedCorrectOptions(true)}
            </ContainerOptions>
            <ContainerOptions marginTop="40px">
                <Button
                    color="primary"
                    startIcon={RenderIconGoBack()}
                    onClick={BackScreenModal}
                    variant="contained"
                >
                    Voltar
                </Button>
                <Button
                    color={validationBackground()}
                    disabled={validationDisabled()}
                    endIcon={EndIcon()}
                    onClick={CreateTask}
                    variant="contained"
                >
                    {taskOrderWord?.id ? 'Editar Atividade' : 'Criar Atividade'}
                </Button>
            </ContainerOptions>
        </>
    );
};

export default TaskOrderWord;
