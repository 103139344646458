/* eslint-disable no-alert */
// js
import api from './api';

async function ListContacts(page: number, token: string): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const baseURL = `/contacts?applicationType=${nameBank}&limit=10&page=${page}`;

        const response = await api.get(baseURL, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function ListText(page: number, token: string): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const baseURL = `/audios?applicationType=${nameBank}&type=contact&limit=10&page=${page}`;
        const response = await api.get(baseURL, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function DeleteText(idAudio: string, token: string) {
    try {
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const baseURL = `/audios?idAudio=${idAudio}`;
        const response = await api.delete(baseURL, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function CreateText(
    idUser: string,
    title: string,
    translation: string,
    description: string,
    file: string,
    token: string,
) {
    const body = {
        idUser,
        title,
        translation,
        description,
        file,
    };
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const baseURL = `/audios?applicationType=${nameBank}`;
        const response = await api.post(baseURL, body, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function UpdateText(
    idAudio: string,
    idUser: string,
    title: string,
    translation: string,
    description: string,
    file: string,
    token: string,
) {
    const body = {
        idUser,
        title,
        translation,
        description,
        file,
    };
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const baseURL = `/audios?applicationType=${nameBank}&idAudio=${idAudio}`;
        const response = await api.put(baseURL, body, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function ListTranlation(page: number, token: string): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const baseURL = `/translations?applicationType=${nameBank}&limit=10&page=${page}`;
        const response = await api.get(baseURL, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function DeleteTranslation(idTranslation: string, token: string) {
    try {
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const baseURL = `/translations?idTranslation=${idTranslation}`;
        const response = await api.delete(baseURL, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function CreateTranslation(
    idUser: string,
    title: string,
    translation: string,
    description: string,
    file: string,
    token: string,
) {
    const body = {
        idUser,
        title,
        translation,
        description,
        file,
    };
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const baseURL = `/translations?applicationType=${nameBank}`;
        const response = await api.post(baseURL, body, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function UpdateTranslation(
    idTranslation: string,
    idUser: string,
    title: string,
    translation: string,
    description: string,
    file: string,
    token: string,
) {
    const body = {
        idUser,
        title,
        translation,
        description,
        file,
    };
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const baseURL = `/translations?applicationType=${nameBank}&idTranslation=${idTranslation}`;
        const response = await api.put(baseURL, body, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

export {
    ListContacts,
    ListText,
    DeleteText,
    CreateText,
    UpdateText,
    ListTranlation,
    DeleteTranslation,
    CreateTranslation,
    UpdateTranslation,
};
