/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
// libraries
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import { Edit, NavigateNext } from '@material-ui/icons';
import FlatList from 'flatlist-react';

// js
import { AllPlansInitial, AllPlansInterfaces } from './plansConstants';
import {
    Container,
    ContainerAdjustIconEdit,
    ContainerBox,
    ContainerOptions,
    InputNullSelectedPhoto,
    Logo,
} from './plansStyles';
import functions from './plansFunctions';
import plans from '../../services/plans';

// components (navigation)
import DrawerNavigation from '../../routes/DrawerNavigation/DrawerNavigation';

const PlansPage: React.FC = () => {
    // constants
    const token: any = String(localStorage.getItem('token') || '');
    const adjustButtonLogo = { alignItems: 'flex-end', marginBottom: 10 };
    const adjustDisplay = { display: 'initial' };
    const adjustTextField = { marginTop: 20, marginRight: 10, width: '50%' };

    // useState
    const [allPlans, setAllPlans] = useState<AllPlansInterfaces>(AllPlansInitial);
    const [generalAllPlans, setGeneralAllPlans] = useState<any>([]);

    const GetListPlans = async () => {
        const getPlans: any = await plans.ListPlans(token || '');
        const listPlan = getPlans?.result?.list || [];
        setAllPlans({
            nameFree: listPlan[0].title || '',
            descriptionFree: listPlan[0].description || '',
            imageFree: listPlan[0].image || '',
            nameMonth: listPlan[1].title || '',
            descriptionMonth: listPlan[1].description || '',
            imageMonth: listPlan[1].image || '',
            nameSemester: listPlan[2].title || '',
            descriptionSemester: listPlan[2].description || '',
            imageSemester: listPlan[2].image || '',
            nameYear: listPlan[3].title || '',
            descriptionYear: listPlan[3].description || '',
            imageYear: listPlan[3].image || '',
        });
        setGeneralAllPlans(listPlan);
    };

    const EditPlans = async () => {
        if (!Object.values(allPlans).filter((v) => v === '').length) {
            const formatAllPlans: any = [
                {
                    title: allPlans.nameFree || '',
                    description: allPlans.descriptionFree || '',
                    image: allPlans.imageFree || '',
                    idPlan: generalAllPlans[0].idPlan,
                },
                {
                    title: allPlans.nameMonth || '',
                    description: allPlans.descriptionMonth || '',
                    image: allPlans.imageMonth || '',
                    idPlan: generalAllPlans[1].idPlan,
                },
                {
                    title: allPlans.nameSemester || '',
                    description: allPlans.descriptionSemester || '',
                    image: allPlans.imageSemester || '',
                    idPlan: generalAllPlans[2].idPlan,
                },
                {
                    title: allPlans.nameYear || '',
                    description: allPlans.descriptionYear || '',
                    image: allPlans.imageYear || '',
                    idPlan: generalAllPlans[3].idPlan,
                },
            ];
            const updatePlans = await formatAllPlans.map(
                (v: any) => plans.EditPlan(v.title, v.description, v.image, v.idPlan, token),
            );
            await Promise.all(updatePlans);
            GetListPlans();
            alert('Dados atualizados.');
        } else {
            alert('Preencha todos os campos de todos os planos para prosseguir.');
        }
    };

    // useEffect
    useEffect(() => {
        GetListPlans();
    }, []);

    // renders
    const EndIcon = () => (
        <NavigateNext />
    );

    const RenderListItems = (item: any) => {
        const { idPlan } = item;
        const onChangeName = (txt: React.ChangeEvent<HTMLInputElement>) => {
            functions.onChangeName(idPlan, allPlans, setAllPlans, txt);
        };

        const onChangeDescription = (txt: any) => {
            functions.onChangeDescription(idPlan, allPlans, setAllPlans, txt);
        };

        const SelectedPhoto = (event: any) => {
            if (event.target.files.length) {
                const newDataFiles = {
                    eventFile: event.target.files[0],
                    eventName: event.target.files[0].name,
                    file: URL.createObjectURL(event.target.files[0]),
                };
                functions.onChangeImage(idPlan, allPlans, setAllPlans, newDataFiles);
            }
        };

        return (
            <>
                <ContainerOptions marginTop="30px">
                    <Button style={adjustButtonLogo}>
                        <Logo src={functions.RenderFile(idPlan, allPlans)} />
                        <ContainerAdjustIconEdit>
                            <Edit color="primary" />
                        </ContainerAdjustIconEdit>
                        <InputNullSelectedPhoto
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={SelectedPhoto}
                            type="file"
                        />
                    </Button>
                    <ContainerOptions width="80%" style={adjustDisplay}>
                        <TextField
                            style={adjustTextField}
                            label="Nome do plano"
                            onChange={onChangeName}
                            value={functions.RenderName(idPlan, allPlans)}
                        />
                        <TextField
                            style={adjustTextField}
                            label="Descrição do plano"
                            onChange={onChangeDescription}
                            multiline
                            value={functions.RenderDescription(idPlan, allPlans)}
                        />
                    </ContainerOptions>
                </ContainerOptions>
            </>
        );
    };

    const RenderPlansPage = () => {
        if (!localStorage.getItem('existUser')) {
            return <Redirect to="/" />;
        }
        return (
            <Container>
                <DrawerNavigation />
                <ContainerBox>
                    <FlatList list={generalAllPlans} renderItem={RenderListItems} />
                    <ContainerOptions marginTop="20px" positionItemHorizontal="flex-end">
                        <Button
                            color="primary"
                            endIcon={EndIcon()}
                            onClick={EditPlans}
                            variant="contained"
                        >
                            Salvar alterações
                        </Button>
                    </ContainerOptions>
                </ContainerBox>
            </Container>
        );
    };

    return RenderPlansPage();
};

export default PlansPage;
