/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Fab } from '@material-ui/core';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { PlayArrow } from '@material-ui/icons';
import uploads from '../../../../../../services/uploads';
import {
    CreateText,
    UpdateText,
} from '../../../../../../services/funtionalities';

import {
    TitleOption,
    Form,
    InputAudio,
    Label,
    ButtonContainer,
    ContainerButtonAudio,
    ButtonPlayer,
} from './styles';

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const adjustStyleFab = { width: '25%', margin: '20px', alignSelf: 'flex-end' };

interface ResponseProps {
    status: number;
    message: string;
}

interface ModalData {
    id: string;
    title: string;
    translation: string;
    description: string;
    file: string;
}

type ModalType = 'create' | 'edit';

interface ModalCreateProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    setActiveLoading: (value: boolean) => void;
    dataModal: ModalData;
    modalType: ModalType;
}

export function ModalCreate(props: ModalCreateProps) {
    const token: any = String(localStorage.getItem('token') || '');
    const idUser: any = String(localStorage.getItem('idUser') || '');

    const {
        open, setOpen, setActiveLoading, dataModal, modalType,
    } = props;

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [translation, setTranslation] = useState('');
    const [audio, setAudio] = useState('');
    const [enabledButton, setEnabledButton] = useState(true);

    const titleModal = modalType === 'create' ? 'Criar texto' : 'Edtar texto';

    const clearInputs = () => {
        setTitle('');
        setDescription('');
        setAudio('');
        setTranslation('');
    };

    const handleClose = () => {
        setOpen(false);
        clearInputs();
    };

    const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleChangeTranslation = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTranslation(event.target.value);
    };

    const handleChangeDescription = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setDescription(event.target.value);
    };

    const handleChangeAudio = async (event: any) => {
        if (event.target.files.length) {
            const newDataFiles = {
                eventFile: event.target.files[0],
                eventName: event.target.files[0].name,
                file: URL.createObjectURL(event.target.files[0]),
            };

            const upload: any = await uploads.UploadFile(
                newDataFiles.eventFile,
            );
            if (upload) {
                setAudio(upload.result);
                // eslint-disable-next-line no-param-reassign
                event.target.value = null;
            }
        }
    };

    const renderAudio = () => {
        if (!audio) {
            return null;
        }

        const playerAudio = () => {
            const currentAudio = new Audio(audio);
            currentAudio.play();
        };

        return (
            <ButtonPlayer onClick={playerAudio}>
                <PlayArrow />
            </ButtonPlayer>
        );
    };

    const handleCreateText = async () => {
        const listText: ResponseProps = await CreateText(
            idUser,
            title,
            translation,
            description,
            audio,
            token,
        );
        if (listText?.status === 201) {
            alert(listText.message);
            handleClose();
            setActiveLoading(true);
        }
    };

    const handleEditText = async () => {
        const listText: ResponseProps = await UpdateText(
            dataModal.id,
            idUser,
            title,
            translation,
            description,
            audio,
            token,
        );
        if (listText?.status === 200) {
            alert(listText.message);
            handleClose();
            setActiveLoading(true);
        }
    };

    const handleSave = async () => {
        if (modalType === 'create') {
            await handleCreateText();
        } else {
            await handleEditText();
        }
    };

    useEffect(() => {
        if (modalType === 'edit') {
            setTitle(dataModal.title);
            setDescription(dataModal.description);
            setTranslation(dataModal.translation);
            setAudio(dataModal.file);
        }
    }, [dataModal]);

    useEffect(() => {
        if (title && description && audio) {
            setEnabledButton(false);
        }
    }, [title, description, audio]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <TitleOption>{titleModal}</TitleOption>
                    <Form>
                        <TextField
                            fullWidth
                            label="Título"
                            margin="normal"
                            onChange={handleChangeTitle}
                            rows={3}
                            value={title}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            label="Tradução"
                            margin="normal"
                            onChange={handleChangeTranslation}
                            rows={3}
                            value={translation}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            label="Descrição"
                            margin="normal"
                            onChange={handleChangeDescription}
                            rows={3}
                            value={description}
                            variant="outlined"
                            multiline
                        />
                        <ContainerButtonAudio>
                            <Label htmlFor="audio">Selecionar áudio</Label>
                            <InputAudio
                                type="file"
                                name="audio"
                                id="audio"
                                accept="audio/mp3, audio.wav"
                                onChange={handleChangeAudio}
                            />
                            {renderAudio()}
                        </ContainerButtonAudio>
                    </Form>
                    <ButtonContainer>
                        <Fab
                            disabled={enabledButton}
                            variant="extended"
                            color="primary"
                            onClick={handleSave}
                            style={adjustStyleFab}
                        >
                            Salvar
                        </Fab>
                    </ButtonContainer>
                </Box>
            </Modal>
        </div>
    );
}
