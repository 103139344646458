/* eslint-disable no-alert */
// js
import api from './api';

async function Login(email: string, password: string, type: 'client' | 'adm', applicationType?: string) {
    try {
        const body = {
            email,
            password,
            type,
        };
        let baseURL = '/users/token';
        if (applicationType) {
            baseURL = `${baseURL}?applicationType=${applicationType}`;
        }
        const response = await api.post(baseURL, body);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function EmitCode(code: string, idUser?: string) {
    try {
        const body = {
            code,
        };
        let baseURL = '/users/twoFactor';
        if (idUser) {
            baseURL = `${baseURL}?idUser=${idUser}`;
        }
        const response = await api.patch(baseURL, body);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function RefreshToken(refresh: string) {
    try {
        const body = {
            refresh,
        };
        const configs = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const response = await api.post('/users/refreshToken', body, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

export default {
    Login,
    EmitCode,
    RefreshToken,
};
