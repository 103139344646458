/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Pagination } from '@material-ui/lab';
import { Fab } from '@material-ui/core';
import {
    PlayCircleFilledOutlined,
    Edit,
    Delete,
    Add,
} from '@material-ui/icons';
import TableRow from '@mui/material/TableRow';

import {
    Container,
    ContainerPaginator,
    ButtonPlayer,
    ActionsContainer,
} from './styles';
import {
    ListTranlation,
    DeleteTranslation,
} from '../../../../services/funtionalities';
import { ModalCreate } from './components/ModalCreate';

interface ContactsTabProps {
    setActiveLoading: (value: boolean) => void;
    activeLoading: boolean;
}

interface Column {
    id: 'title' | 'description' | 'file' | 'translation' | 'actions';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'title', label: 'Título', minWidth: 150 },
    { id: 'translation', label: 'Tradução', minWidth: 150 },
    { id: 'description', label: 'Description', minWidth: 250 },
    { id: 'file', label: 'Áudio', minWidth: 150 },
    { id: 'actions', label: 'Ações', minWidth: 150 },
];

interface Data {
    id: string;
    title: string;
    description: string;
    file: string;
    translation: string;
}

interface DeleteProps {
    status: number;
    message: string;
}

interface ModalData {
    id: string;
    title: string;
    description: string;
    file: string;
    translation: string;
}

type ModalType = 'create' | 'edit';

const adjustStyleFab = { width: '25%', margin: '20px', alignSelf: 'flex-end' };

export default function TranlationTab(props: ContactsTabProps) {
    const { setActiveLoading, activeLoading } = props;
    const token: any = String(localStorage.getItem('token') || '');

    const [page, setPage] = useState(1);
    const [qtdPages, setQtdPages] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const [dataModal, setDataModal] = useState<ModalData>({} as ModalData);
    const [modalType, setModalType] = useState<ModalType>('create');
    const [rows, setRows] = useState([]);

    function createData(
        id: string,
        title: string,
        translation: string,
        description: string,
        file: string,
    ) {
        const renderAudio = () => {
            const audio = new Audio(file);

            const playerAudio = () => {
                audio.play();
            };

            return (
                <ButtonPlayer onClick={playerAudio}>
                    <PlayCircleFilledOutlined color="primary" />
                </ButtonPlayer>
            );
        };

        const renderAction = () => {
            const handleOpenModalEdit = () => {
                setModalType('edit');
                const data = {
                    id,
                    title,
                    description,
                    file,
                    translation,
                };
                setDataModal(data);
                setOpenModal(true);
            };

            const deleteRow = async () => {
                const data: DeleteProps = await DeleteTranslation(id, token);
                if (data.status === 200) {
                    alert(data.message);
                }
                setActiveLoading(true);
            };

            return (
                <ActionsContainer>
                    <ButtonPlayer onClick={handleOpenModalEdit}>
                        <Edit color="action" />
                    </ButtonPlayer>
                    <ButtonPlayer onClick={deleteRow}>
                        <Delete color="error" />
                    </ButtonPlayer>
                </ActionsContainer>
            );
        };

        return {
            title,
            translation,
            description,
            file: renderAudio(),
            actions: renderAction(),
        };
    }

    const GetAllTranslation = async () => {
        setActiveLoading(true);
        const listContacts: any = await ListTranlation(page, token);
        if (listContacts) {
            setQtdPages(listContacts?.result?.qtdPages);
            const newList = listContacts.result.list.map((e: Data) => {
                const data = createData(
                    e.id,
                    e.title,
                    e.translation,
                    e.description,
                    e.file,
                );
                return data;
            });
            setRows(newList || []);
            setActiveLoading(false);
        }
    };

    const handleChangePage = async (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        setPage(value);
    };

    const handleOpenModal = () => {
        setModalType('create');
        setOpenModal(true);
    };

    useEffect(() => {
        if (activeLoading) {
            GetAllTranslation();
        }
    }, [page, activeLoading, openModal]);

    return (
        <Container>
            <Fab
                variant="extended"
                color="primary"
                onClick={handleOpenModal}
                style={adjustStyleFab}
            >
                <Add />
                Adicionar Textos
            </Fab>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        <h3>{column.label}</h3>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row}
                                >
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                            >
                                                {column.format
                                                && typeof value === 'number'
                                                    ? column.format(value)
                                                    : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <ContainerPaginator>
                <Pagination
                    count={qtdPages}
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangePage}
                    color="primary"
                />
            </ContainerPaginator>
            <ModalCreate
                setActiveLoading={setActiveLoading}
                open={openModal}
                setOpen={setOpenModal}
                dataModal={dataModal}
                modalType={modalType}
            />
        </Container>
    );
}
