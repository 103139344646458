/* eslint-disable no-alert */
// js
import api from './api';

async function ListPlans(token: string): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const response = await api.get(`/plans?applicationType=${nameBank}`, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function EditPlan(
    title: string,
    description: string,
    image: string,
    idPlan: string,
    token: string,
): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const body = {
            title,
            description,
            image,
        };
        const configs = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json',
            },
        };
        const response = await api.put(`/plans?applicationType=${nameBank}&idPlan=${idPlan}`, body, configs);
        return response.data;
    } catch (error: any) {
        // alert(error.response.data?.message || 'Erro de servidor');
    }
}

export default {
    ListPlans,
    EditPlan,
};
