/* eslint-disable max-len */
// libraries
import React from 'react';
import {
    Container, Text, Title, Subtitle, Column,
} from './styles';

const PoliticEsPage: React.FC = () => (
    <Container>
        <Title>Política de Privacidad</Title>
        <Text>
            En Idiomas Tec, la privacidad y seguridad son prioridades y estamos comprometidos con la
            transparencia en el tratamiento de datos personales de nuestros usuarios/clientes. Por ello, esta
            Política de Privacidad establece cómo se realiza la recolección, uso y transferencia de
            información de clientes u otras personas que accedan o utilicen nuestra aplicación.
            Al utilizar nuestros servicios, usted entiende que recopilaremos y utilizaremos su información
            personal de las formas descritas en esta Política, bajo las normas de Protección de Datos (LGPD,
            Ley Federal 13.709/2018), las disposiciones del consumidor de la Ley Federal 8078/1990 y las
            demás normas del ordenamiento jurídico brasileño aplicables.
            De esta manera, Carlos Gomes de Oliveira, en adelante denominada simplemente como Idiomas
            Tec, inscrita en el CNPJ bajo el nº 41.750.051/0001-18, en el papel de Controlador de Datos, se
            compromete a lo dispuesto en esta Política de Privacidad.
        </Text>
        <Subtitle>1. ¿Qué datos recopilamos sobre usted y con qué finalidad?</Subtitle>
        <Text>
            Nuestra aplicación recopila y utiliza algunos datos personales suyos, para hacer viable la
            prestación de servicios y mejorar la experiencia de uso.
            {' '}
        </Text>
        <Subtitle>1.1. Datos personales proporcionados por el titular </Subtitle>
        <Column>
            <Text>
                .Nombre.
            </Text>
            <Text>
                .Correo electrónico.
            </Text>
        </Column>
        <Subtitle>1.2. Datos personales recopilados automáticamente </Subtitle>
        <Column>
            <Text>
                .Nombre, al momento del registro, el nombre es necesario para la identificación personal
                en la aplicación
            </Text>
            <Text>
                .Correo electrónico, al momento del registro, el correo electrónico proporcionado se
                convierte en el inicio de sesión para acceder a la aplicación y para la recuperación de
                contraseñas perdidas/olvidadas.
            </Text>
        </Column>
        <Subtitle>2. ¿Cómo recopilamos sus datos?</Subtitle>
        <Column>
            <Text>
                En este sentido, la recolección de sus datos personales ocurre de la siguiente manera:
            </Text>
            <Text>
                .Después de descargar la aplicación e instalarla, el usuario proporciona su nombre y correo
                electrónico al registrarse, para que pueda comenzar a utilizar la aplicación.
            </Text>
        </Column>
        <Subtitle>2.1. Consentimiento</Subtitle>
        <Text>
            Es a partir de su consentimiento que tratamos sus datos personales. El consentimiento es la
            manifestación libre, informada e inequívoca por la cual usted autoriza a Idiomas Tec a tratar sus
            datos.
            Así, en consonancia con la Ley General de Protección de Datos, sus datos solo serán
            recopilados, tratados y almacenados mediante previo y expreso consentimiento.
            Su consentimiento se obtendrá de manera específica para cada finalidad mencionada
            anteriormente, evidenciando el compromiso de transparencia y buena fe de Idiomas Tec hacia
            sus usuarios/clientes, siguiendo las regulaciones legislativas pertinentes.
            Al utilizar los servicios de Idiomas Tec y proporcionar sus datos personales, usted está
            consciente y consiente con las disposiciones de esta Política de Privacidad, además de conocer
            sus derechos y cómo ejercerlos.
            En cualquier momento y sin costo alguno, puede revocar su consentimiento.
            Es importante destacar que la revocación del consentimiento para el tratamiento de datos puede
            implicar la imposibilidad del funcionamiento adecuado de alguna funcionalidad de la aplicación
            que dependa de esta operación. Tales consecuencias se informarán previamente.
        </Text>
        <Subtitle>3. ¿Cuáles son sus derechos?</Subtitle>
        <Column>
            <Text>
                Idiomas Tec garantiza a sus usuarios/clientes sus derechos de titular previstos en el artículo 18
                de la Ley General de Protección de Datos. De esta forma, puede, de manera gratuita y en
                cualquier momento:
            </Text>
            <Text>
                .Confirmar la existencia de tratamiento de datos, de manera simplificada o en formato claro
                y completo.
            </Text>
            <Text>
                .Acceder a sus datos, pudiendo solicitarlos en una copia legible en forma impresa o por
                medio electrónico, seguro y adecuado.
            </Text>
            <Text>
                .Corregir sus datos, solicitando la edición, corrección o actualización de los mismos.
            </Text>
            <Text>
                .Limitar sus datos cuando sean innecesarios, excesivos o tratados en desacuerdo con la
                legislación mediante anonimización, bloqueo o eliminación.
            </Text>
            <Text>
                .Solicitar la portabilidad de sus datos, a través de un informe de datos de registro que
                Idiomas Tec trata sobre usted.
            </Text>
            <Text>
                .Eliminar sus datos tratados a partir de su consentimiento, excepto en casos previstos por
                ley.
            </Text>
            <Text>
                .Revocar su consentimiento, desautorizando el tratamiento de sus datos.
            </Text>
            <Text>
                .Informarse sobre la posibilidad de no proporcionar su consentimiento y las consecuencias
                de la negativa.
            </Text>
        </Column>
        <Subtitle>4. ¿Cómo puede ejercer sus derechos como titular? </Subtitle>
        <Column>
            <Text>
                Para ejercer sus derechos como titular, debe ponerse en contacto con Idiomas Tec a través de
                los siguientes medios disponibles:
            </Text>
            <Text>
                .Enlace de comentarios dentro de las propias tiendas de la App Store y Google Play.
            </Text>
            <Text>
                .Correo electrónico de Idiomas Tec.
            </Text>
            <Text>
                Para garantizar su correcta identificación como titular de los datos personales objeto de la
                solicitud, es posible que le pidamos documentos u otras pruebas que puedan comprobar su
                identidad. En este caso, será informado previamente.
            </Text>
        </Column>
        <Subtitle>
            5. ¿Cómo y por cuánto tiempo se almacenan sus datos?
        </Subtitle>
        <Column>
            <Text>
                Sus datos personales recopilados por Idiomas Tec se utilizarán y almacenarán durante el tiempo
                necesario para la prestación del servicio o para que se logren los objetivos descritos en esta
                Política de Privacidad, teniendo en cuenta los derechos de los titulares de los datos y de los
                controladores.
                En general, sus datos se mantendrán mientras dure la relación contractual entre usted e Idiomas
                Tec. Una vez finalizado el período de almacenamiento de los datos personales, serán eliminados
                de nuestras bases de datos o anonimizados, excepto en casos previstos por ley.
                El almacenamiento de datos recopilados por Idiomas Tec refleja nuestro compromiso con la
                seguridad y la privacidad de sus datos. Empleamos medidas y soluciones técnicas de protección
                que garantizan la confidencialidad, integridad e inviolabilidad de sus datos. Además, también
                contamos con medidas de seguridad adecuadas a los riesgos y con control de acceso a la
                información almacenada.
            </Text>
        </Column>
        <Subtitle>
            6. ¿Qué hacemos para mantener sus datos seguros?
        </Subtitle>
        <Column>
            <Text>
                Para mantener sus datos personales seguros, utilizamos herramientas físicas, electrónicas y
                gerenciales orientadas a la protección de su privacidad.
                Aplicamos estas herramientas teniendo en cuenta la naturaleza de los datos personales
                recopilados, el contexto y el propósito del tratamiento y los riesgos que eventuales violaciones
                podrían generar para los derechos y libertades del titular de los datos recopilados y tratados.
                Entre las medidas que adoptamos, destacamos las siguientes:
            </Text>
            <Text>
                .Solo personas autorizadas tendrán acceso a sus datos personales.
            </Text>
            <Text>
                .El acceso a sus datos personales solo se hará después de firmar el compromiso de
                confidencialidad.
            </Text>
            <Text>
                .Sus datos personales se almacenarán en un entorno seguro y adecuado.
            </Text>
            <Text>
                Idiomas Tec se compromete a adoptar las mejores prácticas para evitar incidentes de seguridad.
                Sin embargo, es necesario destacar que ninguna plataforma virtual es completamente segura y
                libre de riesgos. Es posible que, a pesar de todos nuestros protocolos de seguridad, problemas
                debidos exclusivamente a terceros ocurran, como ataques cibernéticos de hackers, o también
                debido a la negligencia o imprudencia del propio usuario/cliente.
                En caso de incidentes de seguridad que puedan generar riesgo o daño relevante para usted o
                cualquier otro de nuestros usuarios/clientes, informaremos a los afectados y a la Autoridad
                Nacional de Protección de Datos sobre lo ocurrido, en consonancia con las disposiciones de la
                Ley General de Protección de Datos.
            </Text>
        </Column>
        <Subtitle>
            7. ¿Con quién pueden compartirse sus datos?
        </Subtitle>
        <Column>
            <Text>
                Con el objetivo de preservar su privacidad, Idiomas Tec no compartirá sus datos personales con
                ningún tercero no autorizado. En este momento, Idiomas Tec no cuenta con ningún socio
                comercial.
            </Text>
            <Text>
                Además, también existen otras circunstancias en las que sus datos pueden ser compartidos,
                como:
                {' '}
            </Text>
            <Text>
                I – Determinación legal, solicitud, requerimiento u orden judicial por parte de autoridades
                judiciales, administrativas o gubernamentales competentes.

            </Text>
            <Text>
                II – En caso de movimientos societarios, como fusiones, adquisiciones y fusiones, de forma
                automática.
                {' '}
            </Text>
            <Text>
                III – Protección de los derechos de Idiomas Tec en cualquier tipo de conflicto, incluidos los de
                naturaleza judicial.
                {' '}
            </Text>
        </Column>
        <Subtitle>
            7.1. Transferencia internacional de datos
        </Subtitle>
        <Text>
            Algunos de los terceros con quienes compartimos sus datos pueden estar ubicados o tener
            instalaciones en países extranjeros. En estas condiciones, de todas maneras, sus datos
            personales estarán sujetos a la Ley General de Protección de Datos y demás legislaciones
            brasileñas de protección de datos.
            En este sentido, Idiomas Tec se compromete a adoptar siempre estándares eficientes de
            seguridad cibernética y de protección de datos, con los mejores esfuerzos para garantizar y
            cumplir con las exigencias legislativas.
            Al aceptar esta Política de Privacidad, acepta este tipo de compartimiento, que se realizará de
            acuerdo con las finalidades descritas en este documento.
        </Text>
        <Subtitle>
            8. Cookies o datos de navegación
        </Subtitle>
        <Text>
            Idiomas Tec no usa Cookies, que son archivos de texto enviados por la plataforma a su
            computadora y que se almacenan en ella, que contienen información relacionada con la
            navegación del sitio.
        </Text>
        <Subtitle>
            9. Modificación de esta Política de Privacidad
        </Subtitle>
        <Text>
            La versión actual de la Política de Privacidad fue formulada y actualizada por última vez el 25 de
            abril de 2024.
            Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento,
            principalmente debido a cambios realizados en nuestra aplicación o en el ámbito legislativo.
            Recomendamos que la revise con frecuencia.
            Cualquier cambio entrará en vigor a partir de su publicación en las tiendas donde la aplicación
            esté disponible y siempre le notificaremos sobre los cambios realizados.
            Al utilizar nuestros servicios y proporcionar sus datos personales después de tales
            modificaciones, usted consiente con ellas
        </Text>
        <Subtitle>
            10. Responsabilidad
        </Subtitle>
        <Text>
            Idiomas Tec prevé la responsabilidad de los agentes que actúan en los procesos de tratamiento
            de datos, de conformidad con los artículos 42 al 45 de la Ley General de Protección de Datos.
            Nos comprometemos a mantener esta Política de Privacidad actualizada, observando sus
            disposiciones y cuidando de su cumplimiento.
            Además, también asumimos el compromiso de buscar condiciones técnicas y organizativas
            adecuadas para proteger todo el proceso de tratamiento de datos.
            Si la Autoridad Nacional de Protección de Datos exige la adopción de medidas en relación con
            el tratamiento de datos realizado por Idiomas Tec, nos comprometemos a seguirlas.
        </Text>
        <Subtitle>
            10.1 Exención de responsabilidad
        </Subtitle>
        <Column>
            <Text>
                Como se menciona en el Tópico 6, aunque adoptamos altos estándares de seguridad para evitar
                incidentes, no hay ninguna página virtual completamente libre de riesgos. En este sentido,
                Idiomas Tec no se hace responsable de:
            </Text>
            <Text>
                I – Cualquier consecuencia derivada de la negligencia, imprudencia o impericia de los usuarios
                en relación con sus datos individuales. Garantizamos y nos hacemos responsables únicamente
                de la seguridad de los procesos de tratamiento de datos y del cumplimiento de las finalidades
                descritas en este documento.
                Cabe destacar que la responsabilidad en cuanto a la confidencialidad de los datos de acceso es
                del usuario.
            </Text>
            <Text>
                II – Acciones maliciosas de terceros, como ataques de hackers, excepto si se comprueba la
                conducta culposa o deliberada de Idiomas Tec. Destacamos que, en caso de incidentes de
                seguridad que puedan generar riesgo o daño relevante para usted o cualquier otro de nuestros
                usuarios/clientes, informaremos a los afectados y a la Autoridad Nacional de Protección de Datos
                sobre lo ocurrido y cumpliremos con las medidas necesarias.
            </Text>
            <Text>
                III – Inexactitud de la información ingresada por el usuario/cliente en los registros necesarios para
                la utilización de los servicios de Idiomas Tec; cualquier consecuencia derivada de información
                falsa o ingresada de mala fe es totalmente responsabilidad del usuario/cliente.
            </Text>
        </Column>
        <Subtitle>
            11. Encargado de Protección de Datos
        </Subtitle>
        <Column>
            <Text>
                Idiomas Tec proporciona el siguiente medio para que pueda ponerse en contacto con nosotros
                para ejercer sus derechos de titular: enlace de comentarios dentro de las propias tiendas de la
                App Store y Google Play, así como por medio del correo electrónico de Idiomas Tec.
                Si tiene alguna pregunta sobre esta Política de Privacidad o sobre los datos personales que
                tratamos, puede ponerse en contacto con nuestro Encargado de Protección de Datos Personales
                a través de los siguientes canales:
            </Text>
            <Text>
                Carlos Gomes de Oliveira=
            </Text>
            <Text>Razón Social: Idiomas Tec </Text>
            <Text>
                Dirección: Estrada das Lágrimas, nº 3.621, São Paulo/SP, CEP 04244-000
                Correo electrónico: contacto@idiomastec.com
            </Text>
            <Text>
                .CNPJ: 41.750.051/0001-18.
            </Text>
        </Column>
    </Container>
);

export default PoliticEsPage;
