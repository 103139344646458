/* eslint-disable no-alert */
// js
import api from './api';

async function ListTasks(idModule: string, idUser: string, token: string): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const response = await api.get(`/tasks?applicationType=${nameBank}&idModule=${idModule}&idUser=${idUser}&type=adm`, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function DetailsTask(idTask: string, type: string, token: string): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const response = await api.get(`/tasks/details?applicationType=${nameBank}&idTask=${idTask}&type=${type}`, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function CreateTask(
    task: any,
    token: string,
): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const body = {
            ...task,
        };
        const configs = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json',
            },
        };
        const response = await api.post(`/tasks?applicationType=${nameBank}`, body, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function EditTask(
    task: any,
    token: string,
): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const body = {
            ...task,
        };
        const configs = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json',
            },
        };
        const response = await api.put(`/tasks?applicationType=${nameBank}&idTask=${task.idTask}`, body, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function DeleteTask(idTask: string, token: string): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const response = await api.delete(`/tasks?applicationType=${nameBank}&idTask=${idTask}`, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

export default {
    ListTasks,
    DetailsTask,
    CreateTask,
    EditTask,
    DeleteTask,
};
