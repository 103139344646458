// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';
import consts from '../../utils/consts';

// functions
import {
    ValidationBackgroundColorButton,
    ValidationColorText,
} from './drawerNavigationFunctions';

// interfaces
interface ButtonActionDrawerOptionInterface {
    index: number,
}

interface DrawerOptionInterface {
    index: number,
}

// styles
export const Container = styled.div`
    background-color: ${colors.basic.white};
    cursor: pointer;
    left: 10px;
    position: absolute;
    top: 10px;
`;

export const ContainerInfoMaster = styled.div`
    background-color: ${colors.basic.white};
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    width: 300px;
`;

export const ContainerList = styled.div`
    align-items: center;
    background-color: ${colors.basic.white};
    min-height: 100%;
    width: 300px;
`;

export const ScrollOptions = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
`;

export const ContainerLogout = styled.div`
    background-color: ${colors.basic.white};
    bottom: 0;
    height: 100px;
    position: absolute;
    width: 100%;
`;

export const DrawerOption = styled.div<DrawerOptionInterface>`
    color: ${(props) => ValidationColorText(props.index)};
    font-size: 14px;
    font-weight: ${consts.getFontWeight('Lato-Bold')};
    padding-left: 20px;
`;

export const ListItem = styled.a<ButtonActionDrawerOptionInterface>`
    align-items: center;
    background-color: ${(props) => ValidationBackgroundColorButton(props.index)};
    border-radius: 5px;
    border-width: 0px;
    cursor: pointer;
    display: flex;
    height: 45px;
    margin-left: 5px;
    margin-top: 5px;
    padding-left: 20px;
    text-decoration: none;
    transition: all ease .4s;
    width: 290px;
    :hover {
        background-color: ${colors.theme.purpleLight};
    }
`;

export const ListItemLogout = styled.div<ButtonActionDrawerOptionInterface>`
    align-items: center;
    background-color: ${(props) => ValidationBackgroundColorButton(props.index)};
    border-radius: 5px;
    border-width: 0px;
    cursor: pointer;
    display: flex;
    height: 45px;
    margin-left: 5px;
    margin-top: 5px;
    padding-left: 20px;
    text-decoration: none;
    transition: all ease .4s;
    width: 290px;
    :hover {
        background-color: ${colors.theme.purpleLight};
    }
`;

export const Logo = styled.img`
    border-radius: 30px;
    height: 60px;
    margin-bottom: 20px;
    width: 60px;
`;

export const Subtitle = styled.h1`
    color: ${colors.basic.gray};
    font-size: 16px;
    font-weight: ${consts.getFontWeight('Lato-Regular')};
    padding-bottom: 30px;
    word-wrap: break-word;
`;

export const Title = styled.h1`
    color: ${colors.basic.black};
    font-size: 30px;
    font-weight: ${consts.getFontWeight('Lato-Bold')};
    word-wrap: break-word;
`;

export const Version = styled.h1`
    color: ${colors.basic.black};
    font-size: 14px;
    font-weight: ${consts.getFontWeight('Lato-Regular')};
    padding-left: 20px;
    padding-top: 20px;
`;
