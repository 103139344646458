/* eslint-disable no-alert */
// js
import api from './api';

async function ListModules(page: number, searchTxt: string, token: string): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        let baseURL = `/modules?applicationType=${nameBank}&page=${page}&limit=10`;
        if (searchTxt.length) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`;
        }
        const response = await api.get(baseURL, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function DeleteModules(idModule: string, token: string): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const response = await api.delete(`/modules?applicationType=${nameBank}&idModule=${idModule}`, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function TradeEnvironment(
    environment: string,
    idModule: string,
    token: string,
): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const response = await api.patch(`/modules/environment?applicationType=${nameBank}&idModule=${idModule}&environment=${environment}`, null, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function CreateOnlyModule(
    module: any,
    token: string,
): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const body = {
            ...module,
            environment: 'development',
        };
        const configs = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json',
            },
        };
        const response = await api.post(`/modules?applicationType=${nameBank}`, body, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function EditModule(
    dataModule: any,
    token: string,
): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const body = {
            ...dataModule,
        };
        const configs = {
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json',
            },
        };
        const response = await api.put(`/modules?applicationType=${nameBank}&idModule=${dataModule?.id}`, body, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

export default {
    ListModules,
    DeleteModules,
    TradeEnvironment,
    CreateOnlyModule,
    EditModule,
};
