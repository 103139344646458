// libraries
import React, { useState } from 'react';
import { Divider, Drawer } from '@material-ui/core';
import {
    AccountBox,
    ExitToApp,
    Folder,
    Bookmarks,
    Menu,
    AttachMoney,
    Star,
    Restore,
    // ColorLensOutlined,
    Email,
} from '@material-ui/icons';

// js
import { version } from '../../../package.json';
import {
    Container,
    ContainerInfoMaster,
    ContainerList,
    ContainerLogout,
    DrawerOption,
    ListItem,
    ListItemLogout,
    Logo,
    ScrollOptions,
    Subtitle,
    Title,
    Version,
} from './drawerNavigationStyles';
import logoBiblicalGreek from '../../assets/images/logoBiblicalGreek.png';
import logoE4B from '../../assets/images/logoE4B.png';
import logoE4Keys from '../../assets/images/logoE4Keys.png';
import logoE4L from '../../assets/images/logoE4L.png';
import logoE4MED from '../../assets/images/logoE4MED.png';
import logoE4Nutri from '../../assets/images/logoE4Nutri.png';
import logoGregoBiblico from '../../assets/images/logoGregoBiblico.png';
import logoInglesTec from '../../assets/images/logoInglesTec.png';
import logoIpsis from '../../assets/images/logoIpsis.png';
import logoMic from '../../assets/images/logoMic.png';
import logoE4CE from '../../assets/images/logoE4CE.png';
import logoHebraicodabiblia from '../../assets/images/logoHebraicodabiblia.png';

// functions
import { ValidationColorIcon } from './drawerNavigationFunctions';

// components (navigation)
import ToNavigation from '../navigation';

const DrawerNavigation: React.FC = () => {
    // constants
    const emailBank: any = String(localStorage.getItem('emailBank') || '');
    const nameBank: any = String(localStorage.getItem('nameBank') || '');
    const AllOptionsDrawer = [
        'Categorias',
        'Módulos',
        'Usuários',
        'Planos',
        'Financeiro',
        'Histórico',
        'Site',
        'Emails',
    ];

    // states
    const [openDrawerLeft, setOpenDrawerLeft] = useState(false);
    const [pathRoute, setPathRoute] = useState('/modules');
    const [redirect, setRedirect] = useState(false);

    // methods
    const TransitionScreen = (index: number) => {
        if (index === 0) {
            setPathRoute('/category');
            setRedirect(true);
            setOpenDrawerLeft(false);
        } else if (index === 1) {
            setPathRoute('/modules');
            setRedirect(true);
            setOpenDrawerLeft(false);
        } else if (index === 2) {
            setPathRoute('/users');
            setRedirect(true);
            setOpenDrawerLeft(false);
        } else if (index === 3) {
            setPathRoute('/plans');
            setRedirect(true);
            setOpenDrawerLeft(false);
        } else if (index === 4) {
            setPathRoute('/finance');
            setRedirect(true);
            setOpenDrawerLeft(false);
        } else if (index === 5) {
            setPathRoute('/historic');
            setRedirect(true);
            setOpenDrawerLeft(false);
        } else if (index === 6) {
            setPathRoute('/functionalities');
            setRedirect(true);
            setOpenDrawerLeft(false);
        } else if (index === 7) {
            setPathRoute('/emails');
            setRedirect(true);
            setOpenDrawerLeft(false);
        }
    };

    const OpenDrawer = () => {
        setOpenDrawerLeft(true);
    };

    const CloseDrawer = () => {
        setOpenDrawerLeft(false);
    };

    // renders
    const RenderIcon = (index: number) => {
        const colorTrade = { color: ValidationColorIcon(index) };
        if (index === 0) {
            return <Folder style={colorTrade} />;
        }
        if (index === 1) {
            return <Folder style={colorTrade} />;
        }
        if (index === 2) {
            return <AccountBox style={colorTrade} />;
        }
        if (index === 3) {
            return <Bookmarks style={colorTrade} />;
        }
        if (index === 4) {
            return <AttachMoney style={colorTrade} />;
        }
        if (index === 5) {
            return <Restore style={colorTrade} />;
        }
        if (index === 6) {
            return <Star style={colorTrade} />;
        }
        if (index === 7) {
            return <Email style={colorTrade} />;
        }
        if (index === 8) {
            return <ExitToApp style={colorTrade} />;
        }
        return null;
    };

    const RenderOptions = () => AllOptionsDrawer.map((text, index) => {
        if (index === 6 && nameBank !== 'gregoBiblico') {
            return null;
        }

        const onClick = () => {
            TransitionScreen(index);
        };

        return (
            <ListItem index={index} key={text} onClick={onClick}>
                {RenderIcon(index)}
                <DrawerOption index={index}>{text}</DrawerOption>
            </ListItem>
        );
    });

    function renderLogoApp() {
        if (nameBank === 'ipsis') {
            return logoIpsis;
        }
        if (nameBank === 'mic') {
            return logoMic;
        }
        if (nameBank === 'gregoBiblico') {
            return logoGregoBiblico;
        }
        if (nameBank === 'biblicalGreek') {
            return logoBiblicalGreek;
        }
        if (nameBank === 'e4b') {
            return logoE4B;
        }
        if (nameBank === 'e4l') {
            return logoE4L;
        }
        if (nameBank === 'iMed') {
            return logoE4MED;
        }
        if (nameBank === 'iNutri') {
            return logoE4Nutri;
        }
        if (nameBank === 'e4keys') {
            return logoE4Keys;
        }
        if (nameBank === 'e4ce') {
            return logoE4CE;
        }
        if (nameBank === 'hebraicodabiblia') {
            return logoHebraicodabiblia;
        }

        return logoInglesTec;
    }

    const RenderList = () => {
        const onClick = () => {
            localStorage.removeItem('existUser');
            window.location.reload();
        };

        return (
            <ContainerList>
                <ContainerInfoMaster>
                    <Logo src={renderLogoApp()} />
                    <Title>{nameBank}</Title>
                    <Subtitle>{emailBank}</Subtitle>
                </ContainerInfoMaster>
                <Divider />
                <ScrollOptions>
                    {RenderOptions()}
                </ScrollOptions>
                <ContainerLogout>
                    <ListItemLogout index={8} key="Logout" onClick={onClick}>
                        {RenderIcon(8)}
                        <DrawerOption index={8}>Logout</DrawerOption>
                    </ListItemLogout>
                    <Divider />
                    <Version>{`Versão: ${version}`}</Version>
                </ContainerLogout>
            </ContainerList>
        );
    };

    return (
        <Container>
            <Menu color="action" fontSize="large" onClick={OpenDrawer} />
            <ToNavigation pathRoute={pathRoute} redirect={redirect} />
            <Drawer anchor="left" onClose={CloseDrawer} open={openDrawerLeft}>
                {RenderList()}
            </Drawer>
        </Container>
    );
};

export default DrawerNavigation;
