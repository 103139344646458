// libraries
import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';

// js
import imgDefault from '../../../assets/images/default.png';
import {
    initialDataTaskCompletedPhrase,
    initialDataTaskConnectPhrases,
    initialDataTaskConnectWord,
    initialDataTaskOrderWord,
    initialDataTaskOrderWordImage,
    initialDataTaskSelectedWord,
    initialDataTaskConnectWordToImages,
    initialDataTaskSelectedWordImage,
    initialDataTaskSelectedWordForQuestion,
    initialDataTaskAssociateWord,
    initialDataTaskOrderWordCompletedPhrase,
    initialDataTaskInfoPhrases,
    initialDataTaskSelectedWordForTranslation,
} from './taskConstants';
import { Container, ContainerBox } from '../modulesStyles';

// components
import ContainerInfoModules from './ContainerInfoModules';
import ContainerInfoTasks from './ContainerInfoTasks';
import TaskCompletedPhrase from './TaskCompletedPhrase';
import TaskConnectPhrases from './TaskConnectPhrases';
import TaskConnectWord from './TaskConnectWord';
import TaskConnectWordToImages from './TaskConnectWordToImages';
import TaskOrderWord from './TaskOrderWord';
import TaskSelectedWord from './TaskSelectedWord';
import ModalLoading from '../../../components/ModalLoading';

// interfaces
import type { DataModuleInterface } from '../modulesInterfaces';
import modulesFunctions from '../modulesFunctions';
import modules from '../../../services/modules';
import tasks from '../../../services/tasks';
import uploads from '../../../services/uploads';
import TaskSelectedWordImage from './TaskSelectedWordImage';
import TaskSelectedWordForQuestion from './TaskSelectedWordForQuestion';
import TaskAssociateWord from './TaskAssociateWord';
import TaskOrderWordCompletedPhrase from './TaskOrderWordCompletedPhrase';
import TaskSelectedWordForTranslation from './TaskSelectedWordForTranslation';
import TaskInfoPhrases from './TaskInfoPhrases';

const ModalAddModules = (
    dataModule: DataModuleInterface,
    initialDataModule: DataModuleInterface,
    openModal: boolean,
    setDataModule: any,
    setOpenModal: any,
    allTasks: any,
    setAllTasks: any,
    dataFile: string,
    setDataFile: any,
    allCategory: any,
    // dataOldModule: any,
) => {
    // constants
    const token: any = String(localStorage.getItem('token') || '');
    const idUser: any = String(localStorage.getItem('idUser') || '');
    const {
        title,
        description,
        signed,
    } = dataModule;

    // useState
    // const [allTasks, setAllTasks] = useState([]);
    // const [dataFile, setDataFile] = useState(initialFile);
    const [idModal, setIdModal] = useState(0);
    const [taskCompletedPhrase, setTaskCompletedPhrase] = useState(initialDataTaskCompletedPhrase);
    const [taskConnectPhrases, setTaskConnectPhrases] = useState(initialDataTaskConnectPhrases);
    const [taskConnectWord, setTaskConnectWord] = useState(initialDataTaskConnectWord);
    const [taskConnectWordToImages, setTaskConnectWordToImages] = useState(
        initialDataTaskConnectWordToImages,
    );
    const [taskOrderWord, setTaskOrderWord] = useState(initialDataTaskOrderWord);
    const [taskOrderWordImage, setTaskOrderWordImage] = useState(initialDataTaskOrderWordImage);
    const [taskSelectedWord, setTaskSelectedWord] = useState(initialDataTaskSelectedWord);
    const [taskSelectedWordImage, setTaskSelectedWordImage] = useState(
        initialDataTaskSelectedWordImage,
    );
    const [taskAssociateWord, setTaskAssociateWord] = useState(
        initialDataTaskAssociateWord,
    );
    const [taskOrderWordCompletedPhrase, setTaskOrderWordCompletedPhrase] = useState(
        initialDataTaskOrderWordCompletedPhrase,
    );
    const [taskSelectedWordForQuestion, setTaskSelectedWordForQuestion] = useState(
        initialDataTaskSelectedWordForQuestion,
    );
    const [taskSelectedWordForTranslation, setTaskSelectedWordForTranslation] = useState(
        initialDataTaskSelectedWordForTranslation,
    );
    const [taskInfoPhrases, setTaskInfoPhrases] = useState(
        initialDataTaskInfoPhrases,
    );
    const [activeLoading, setActiveLoading] = useState(false);

    // methods
    const onChangeTitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataModule = { ...dataModule, title: txt.target.value };
            setDataModule(newDataModule);
        }
    };

    const onChangeDescription = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataModule = { ...dataModule, description: txt.target.value };
            setDataModule(newDataModule);
        }
    };

    const onChangeSigned = (txt: any) => {
        const newDataModule = { ...dataModule, signed: txt.target.value };
        setDataModule(newDataModule);
    };

    const onChangeCategory = (txt: any) => {
        const newDataModule = { ...dataModule, category: txt.target.value };
        setDataModule(newDataModule);
    };

    const handleChangeSelectedTask = (txt: any) => {
        const type = txt.target.value;
        if (type === 'SELECTED_WORD') {
            setIdModal(2);
        }
        if (type === 'COMPLETED_PHRASE') {
            setIdModal(3);
        }
        if (type === 'CONNECT_PHRASES') {
            setIdModal(4);
        }
        if (type === 'CONNECT_WORD') {
            setIdModal(5);
        }
        if (type === 'ORDER_WORD') {
            setIdModal(6);
        }
        if (type === 'CONNECT_WORD_TO_IMAGES') {
            setIdModal(7);
        }
        if (type === 'ORDER_WORD_IMAGE') {
            setIdModal(8);
        }
        if (type === 'SELECTED_WORD_IMAGE') {
            setIdModal(9);
        }
        if (type === 'ASSOCIATE_WORD') {
            setIdModal(10);
        }
        if (type === 'ORDER_WORD_COMPLETED_PHRASE') {
            setIdModal(11);
        }
        if (type === 'SELECTED_WORD_FOR_QUESTION') {
            setIdModal(12);
        }
        if (type === 'SELECTED_WORD_FOR_TRANSLATION') {
            setIdModal(13);
        }
        if (type === 'INFO_PHRASES') {
            setIdModal(14);
        }
    };

    const GetListTasks = async () => {
        const {
            id,
        } = dataModule;
        if (id) {
            const getTasks: any = await tasks.ListTasks(id, idUser, token);
            if (getTasks) {
                setAllTasks(getTasks?.result?.list);
            }
        }
    };

    const handleChangeListTask = async (txt: any) => {
        const infoObj = txt.target.value;
        if (infoObj.method === 'DELETE') {
            const deleteTask: any = await tasks.DeleteTask(
                infoObj?.id,
                token,
            );
            if (deleteTask) {
                GetListTasks();
            }
        } else if (infoObj?.method === 'EDITAR') {
            const getDetailsTask: any = await tasks.DetailsTask(
                infoObj?.id,
                infoObj?.type,
                token,
            );
            if (getDetailsTask) {
                const TypeSelect = {
                    target: {
                        value: infoObj?.type,
                    },
                };
                handleChangeSelectedTask(TypeSelect);
                modulesFunctions.ActionAutoCompleteTasks(
                    getDetailsTask?.result,
                    setTaskSelectedWord,
                    setTaskCompletedPhrase,
                    setTaskConnectPhrases,
                    setTaskConnectWord,
                    setTaskOrderWord,
                    setTaskOrderWordImage,
                    setTaskConnectWordToImages,
                    setTaskSelectedWordImage,
                    setTaskAssociateWord,
                    setTaskOrderWordCompletedPhrase,
                    setTaskSelectedWordForQuestion,
                    setTaskSelectedWordForTranslation,
                    setTaskInfoPhrases,
                );
            }
        }
    };

    const onCloseModal = () => {
        setOpenModal(false);
        setIdModal(0);
        setAllTasks([]);
        setDataModule(initialDataModule);
        setDataFile('');
        setTaskCompletedPhrase(initialDataTaskCompletedPhrase);
        setTaskConnectPhrases(initialDataTaskConnectPhrases);
        setTaskConnectWord(initialDataTaskConnectWord);
        setTaskConnectWordToImages(initialDataTaskConnectWordToImages);
        setTaskOrderWord(initialDataTaskOrderWord);
        setTaskOrderWordImage(initialDataTaskOrderWordImage);
        setTaskSelectedWord(initialDataTaskSelectedWord);
    };

    const NextScreenModal = async () => {
        const {
            id,
            category,
        } = dataModule;
        const formatDataModule = {
            ...dataModule,
            image: dataFile,
            idModuleGroup: category,
        };
        delete formatDataModule?.amountTask;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete formatDataModule?.category;
        if (id) {
            const editModule: any = await modules.EditModule(formatDataModule, token);
            if (editModule) {
                setIdModal(1);
            }
        } else {
            const createModule: any = await modules.CreateOnlyModule(formatDataModule, token);
            if (createModule) {
                setIdModal(1);
                setDataModule(createModule?.result);
            }
        }
    };

    const BackScreenModal = () => {
        setIdModal(0);
    };

    const ResetStates = () => {
        setActiveLoading(false);
        setOpenModal(false);
        setIdModal(0);
        setDataFile('');
        setDataModule(initialDataModule);
        setAllTasks([]);
    };

    const CreateModule = async () => {
        ResetStates();
    };

    const SelectedPhoto = async (event: any) => {
        if (event.target.files.length) {
            const newDataFiles = {
                eventFile: event.target.files[0],
                eventName: event.target.files[0].name,
                file: URL.createObjectURL(event.target.files[0]),
            };

            const upload: any = await uploads.UploadFile(newDataFiles.eventFile);
            if (upload) {
                setDataFile(upload.result);
                // eslint-disable-next-line no-param-reassign
                event.target.value = null;
            }
        }
    };

    const validationDisabled = (type: any) => {
        const moduleFormat = {
            description,
            file: dataFile,
            title,
            signed,
        };
        const valueObject = Object.values(moduleFormat).filter((v) => v !== '' && v !== imgDefault).length !== 4;
        if (type === 1) {
            return valueObject;
        }
        if (type === 2) {
            return allTasks.length === 0;
        }

        return true;
    };

    const validationBackground = () => {
        const moduleFormat = {
            description,
            title,
            file: dataFile,
            signed,
        };
        const valueObject = Object.values(moduleFormat).filter((v) => v !== '' && v !== imgDefault);
        if (valueObject.length === 4) {
            return 'primary';
        }
        if (allTasks.length) {
            return 'primary';
        }

        return 'inherit';
    };

    useEffect(() => {
        if (idModal === 1) {
            GetListTasks();
        }
    }, [idModal]);

    // renders
    const RenderInfo = () => {
        if (idModal === 1) {
            return ContainerInfoTasks(
                dataModule,
                allTasks,
                BackScreenModal,
                CreateModule,
                handleChangeListTask,
                handleChangeSelectedTask,
                onCloseModal,
                validationBackground,
                validationDisabled,
            );
        }

        if (idModal === 2) {
            return TaskSelectedWord(
                setIdModal,
                initialDataTaskSelectedWord,
                taskSelectedWord,
                setTaskSelectedWord,
                dataModule,
            );
        }

        if (idModal === 3) {
            return TaskCompletedPhrase(
                setIdModal,
                initialDataTaskCompletedPhrase,
                taskCompletedPhrase,
                setTaskCompletedPhrase,
                dataModule,
            );
        }

        if (idModal === 4) {
            return TaskConnectPhrases(
                setIdModal,
                initialDataTaskConnectPhrases,
                taskConnectPhrases,
                setTaskConnectPhrases,
                dataModule,
            );
        }

        if (idModal === 5) {
            return TaskConnectWord(
                setIdModal,
                initialDataTaskConnectWord,
                taskConnectWord,
                setTaskConnectWord,
                dataModule,
            );
        }

        if (idModal === 6) {
            return TaskOrderWord(
                setIdModal,
                initialDataTaskOrderWord,
                taskOrderWord,
                setTaskOrderWord,
                dataModule,
            );
        }

        if (idModal === 7) {
            return TaskConnectWordToImages(
                setIdModal,
                initialDataTaskConnectWordToImages,
                taskConnectWordToImages,
                setTaskConnectWordToImages,
                dataModule,
            );
        }

        if (idModal === 8) {
            return TaskOrderWord(
                setIdModal,
                initialDataTaskOrderWordImage,
                taskOrderWordImage,
                setTaskOrderWordImage,
                dataModule,
            );
        }

        if (idModal === 9) {
            return TaskSelectedWordImage(
                setIdModal,
                initialDataTaskSelectedWordImage,
                taskSelectedWordImage,
                setTaskSelectedWordImage,
                dataModule,
            );
        }

        if (idModal === 10) {
            return TaskAssociateWord(
                setIdModal,
                initialDataTaskAssociateWord,
                taskAssociateWord,
                setTaskAssociateWord,
                dataModule,
            );
        }

        if (idModal === 11) {
            return TaskOrderWordCompletedPhrase(
                setIdModal,
                initialDataTaskOrderWordCompletedPhrase,
                taskOrderWordCompletedPhrase,
                setTaskOrderWordCompletedPhrase,
                dataModule,
            );
        }

        if (idModal === 12) {
            return TaskSelectedWordForQuestion(
                setIdModal,
                initialDataTaskSelectedWordForQuestion,
                taskSelectedWordForQuestion,
                setTaskSelectedWordForQuestion,
                dataModule,
            );
        }

        if (idModal === 13) {
            return TaskSelectedWordForTranslation(
                setIdModal,
                initialDataTaskSelectedWordForTranslation,
                taskSelectedWordForTranslation,
                setTaskSelectedWordForTranslation,
                dataModule,
            );
        }

        if (idModal === 14) {
            return TaskInfoPhrases(
                setIdModal,
                initialDataTaskInfoPhrases,
                taskInfoPhrases,
                setTaskInfoPhrases,
                dataModule,
            );
        }

        return ContainerInfoModules(
            dataFile,
            dataModule,
            NextScreenModal,
            onChangeDescription,
            onChangeTitle,
            onChangeSigned,
            onChangeCategory,
            onCloseModal,
            SelectedPhoto,
            validationBackground,
            validationDisabled,
            allCategory,
        );
    };

    return (
        <Modal
            disableBackdropClick
            disableEscapeKeyDown
            onClose={onCloseModal}
            open={openModal}
        >
            <Container>
                <ContainerBox>
                    <ModalLoading activeLoading={activeLoading} />
                    {RenderInfo()}
                </ContainerBox>
            </Container>
        </Modal>
    );
};

export default ModalAddModules;
