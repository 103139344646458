// libraries
import React from 'react';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { Close, Edit, NavigateNext } from '@material-ui/icons';

// js
import {
    CloseModal,
    ContainerAdjustIconEdit,
    ContainerOptions,
    InputNullSelectedPhoto,
    Logo,
    TitleOption,
} from '../modulesStyles';

// interfaces
import type { DataModuleInterface } from '../modulesInterfaces';

const ContainerInfoModules = (
    dataFile: string,
    dataModule: DataModuleInterface,
    NextScreenModal: any,
    onChangeDescription: any,
    onChangeTitle: any,
    onChangeSigned: any,
    onChangeCategory: any,
    onCloseModal: any,
    SelectedPhoto: any,
    validationBackground: any,
    validationDisabled: any,
    allCategory: Array<any>,
) => {
    // constants
    const adjustButtonLogo = { alignItems: 'flex-end', marginBottom: 10 };
    const adjustWidthSelectedActivities = { width: 200 };
    const {
        title, description, signed, category,
    } = dataModule;

    // renders
    const EndIcon = () => (
        <NavigateNext />
    );

    return (
        <>
            <ContainerOptions>
                <TitleOption>Criação de módulo</TitleOption>
                <CloseModal onClick={onCloseModal}>
                    <Close color="primary" fontSize="large" />
                </CloseModal>
            </ContainerOptions>
            <Button style={adjustButtonLogo}>
                <Logo src={dataFile} />
                <ContainerAdjustIconEdit>
                    <Edit color="primary" />
                </ContainerAdjustIconEdit>
                <InputNullSelectedPhoto
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={SelectedPhoto}
                    type="file"
                />
            </Button>
            <TextField
                fullWidth
                label={`Título do módulo (${title.length}/200)`}
                onChange={onChangeTitle}
                value={title}
            />
            <TextField
                fullWidth
                label={`Descrição do módulo (${description.length}/200)`}
                margin="normal"
                multiline
                onChange={onChangeDescription}
                rows={3}
                value={description}
                variant="outlined"
            />
            <FormControl style={adjustWidthSelectedActivities}>
                <InputLabel>Tipo do módulo</InputLabel>
                <Select value={signed} onChange={onChangeSigned}>
                    <MenuItem value="true">Exclusivo para assinantes</MenuItem>
                    <MenuItem value="false">Para todos os usuários</MenuItem>
                </Select>
            </FormControl>
            <FormControl style={{ ...adjustWidthSelectedActivities, marginLeft: 20 }}>
                <InputLabel>Categoria</InputLabel>
                <Select value={category} onChange={onChangeCategory}>
                    {allCategory.map((v) => (
                        <MenuItem value={v.id} key={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <ContainerOptions marginTop="20px" positionItemHorizontal="flex-end">
                <Button
                    color={validationBackground()}
                    disabled={validationDisabled(1)}
                    endIcon={EndIcon()}
                    onClick={NextScreenModal}
                    variant="contained"
                >
                    Próximo
                </Button>
            </ContainerOptions>
        </>
    );
};

export default ContainerInfoModules;
