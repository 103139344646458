/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-plusplus */
// libraries
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

// js
import {
    Container,
    ContainerBox,
    TitleBox,
    MenuContainer,
    MenuOption,
} from './functionalitiesStyles';

// components (navigation)
import DrawerNavigation from '../../routes/DrawerNavigation/DrawerNavigation';
import ModalLoading from '../../components/ModalLoading';
import ContactsTab from './Tabs/ContactsTab';
import TextTab from './Tabs/TextTab';
import TranslationTab from './Tabs/TranslationTab';

const FunctionalitiesPage: React.FC = () => {
    // states
    const [activeLoading, setActiveLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState(1);

    const contactsIsActive = currentTab === 1;
    const textIsActive = currentTab === 2;
    const translationIsActive = currentTab === 3;

    const renderTabs = () => {
        if (currentTab === 1) {
            return <ContactsTab setActiveLoading={setActiveLoading} />;
        }
        if (currentTab === 2) {
            return (
                <TextTab
                    activeLoading={activeLoading}
                    setActiveLoading={setActiveLoading}
                />
            );
        }
        return (
            <TranslationTab
                activeLoading={activeLoading}
                setActiveLoading={setActiveLoading}
            />
        );
    };

    const RenderUsersPage = () => {
        if (!localStorage.getItem('existUser')) {
            return <Redirect to="/" />;
        }

        const handleChangeContact = () => {
            setActiveLoading(true);
            setCurrentTab(1);
        };

        const handleChangeText = () => {
            setActiveLoading(true);
            setCurrentTab(2);
        };

        const handleChangeTranlation = () => {
            setActiveLoading(true);
            setCurrentTab(3);
        };

        return (
            <Container>
                <ModalLoading activeLoading={activeLoading} />
                <DrawerNavigation />
                <ContainerBox>
                    <TitleBox>Site</TitleBox>
                    <MenuContainer>
                        <MenuOption
                            onClick={handleChangeContact}
                            isActive={contactsIsActive}
                        >
                            Contatos
                        </MenuOption>
                        <MenuOption
                            onClick={handleChangeText}
                            isActive={textIsActive}
                        >
                            Textos
                        </MenuOption>
                        <MenuOption
                            onClick={handleChangeTranlation}
                            isActive={translationIsActive}
                        >
                            Traduções
                        </MenuOption>
                    </MenuContainer>
                    {renderTabs()}
                </ContainerBox>
            </Container>
        );
    };

    return RenderUsersPage();
};

export default FunctionalitiesPage;
