import axios from 'axios';

const toPrice = (price: number, scale?: number): string => (
    String(((price * 100) / 100).toFixed(scale)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
);

async function getDownloadRemoteFile(file: string): Promise<null> {
    const { data: blob } = await axios.get(file, { responseType: 'blob' });

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob);

    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a');

    // Passing the blob downloading url
    a.setAttribute('href', url);

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', 'download.xlsx');

    // Performing a download with click
    a.click();

    return null;
}

export default {
    toPrice,
    getDownloadRemoteFile,
};
