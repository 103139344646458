/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
// libraries
import React from 'react';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import {
    CheckCircle,
    Edit,
    NavigateBefore,
    Send,
} from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';

// js
import defaultSound from '../../../assets/images/defaultSound.png';
import imgDefault from '../../../assets/images/default.png';
import { allMenuItems } from './taskConstants';
import {
    ContainerAdjustIconEdit,
    ContainerOptions,
    InputNullSelectedPhoto,
    Logo,
    TitleOption,
} from '../modulesStyles';

// interfaces
import type { ConnectWordToImagesInterface, DataModuleInterface } from '../modulesInterfaces';
import uploads from '../../../services/uploads';
import tasks from '../../../services/tasks';

const TaskConnectWordToImages = (
    setIdModal: any,
    initialDataTaskConnectWordToImages: ConnectWordToImagesInterface,
    taskConnectWordToImages: ConnectWordToImagesInterface,
    setTaskConnectWordToImages: any,
    dataModule: DataModuleInterface,
) => {
    // constants
    const token: any = String(localStorage.getItem('token') || '');
    const adjustMarginBottom = { marginBottom: 10 };
    const adjustButtonLogo = { alignItems: 'flex-end', marginBottom: 10 };
    const adjustWidthSelectedActivities = { width: 200 };

    // methods
    const BackScreenModal = () => {
        setIdModal(1);
        setTaskConnectWordToImages(initialDataTaskConnectWordToImages);
    };

    const CreateTask = async () => {
        const formatOptions = taskConnectWordToImages?.options?.map((e: any) => {
            const option = e;
            delete option.idWrapperTask;
            return option;
        });
        const formatTask: any = {
            ...taskConnectWordToImages,
            options: formatOptions,
            idTask: taskConnectWordToImages.id,
            idModule: dataModule?.id,
        };
        delete formatTask?.id;
        if (formatTask?.idTask) {
            const editTask: any = await tasks.EditTask(formatTask, token);
            if (editTask) {
                BackScreenModal();
            }
        } else {
            delete formatTask?.idTask;
            const createTask: any = await tasks.CreateTask(formatTask, token);
            if (createTask) {
                BackScreenModal();
            }
        }
    };

    const onChangeTitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskConnectWordToImages, title: txt.target.value };
            setTaskConnectWordToImages(newDataTask);
        }
    };

    const onChangeSubtitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskConnectWordToImages, subtitle: txt.target.value };
            setTaskConnectWordToImages(newDataTask);
        }
    };

    const onChangeOptionSkipTask = (txt: any) => {
        const newDataTask = { ...taskConnectWordToImages, skip: txt.target.value };
        setTaskConnectWordToImages(newDataTask);
    };

    const validationDisabled = () => {
        let valueObject = true;
        const ValidationOptionsLength = taskConnectWordToImages?.options.filter((v: any) => (v.fileAudio && v.file)).length === taskConnectWordToImages?.options?.length;
        if (taskConnectWordToImages?.id) {
            const ValidationLength = Object.values(taskConnectWordToImages).filter((v) => v !== '').length !== 7;
            if (!ValidationLength && ValidationOptionsLength) {
                valueObject = false;
            }
        } else if (!taskConnectWordToImages?.id) {
            const ValidationLength = Object.values(taskConnectWordToImages).filter((v) => v !== '').length !== 6;
            if (!ValidationLength && ValidationOptionsLength) {
                valueObject = false;
            }
        }
        return valueObject;
    };

    const validationBackground = () => {
        const valueObject = Object.values(taskConnectWordToImages).filter((v) => v !== '');
        const ValidationOptionsLength = taskConnectWordToImages?.options.filter((v: any) => (v.fileAudio && v.file)).length === taskConnectWordToImages?.options?.length;
        if ((taskConnectWordToImages?.id ? valueObject.length === 7 : valueObject.length === 6) && ValidationOptionsLength) {
            return 'primary';
        }

        return 'inherit';
    };

    const onChangeTarget = (txt: any) => {
        const allOptions = [];
        for (let i = 0; i < Number(txt.target.value); i++) {
            const objOrigin = { fileAudio: '', file: '', id: uuidv4() };
            allOptions.push(objOrigin);
            if (allOptions.length === Number(txt.target.value)) {
                const orderNewDataOption = allOptions.sort((a, b) => {
                    if (a.id < b.id) return -1;
                    if (a.id > b.id) return 1;
                    return 0;
                });
                const newDataTask = {
                    ...taskConnectWordToImages,
                    target: Number(txt.target.value),
                    options: orderNewDataOption,
                };
                setTaskConnectWordToImages(newDataTask);
            }
        }
    };

    // renders
    const RenderInputsOptions = (
        dataFile: any,
        onChange: any,
        SelectedPhotoTarget: any,
        value: any,
    ) => (
        <ContainerOptions
            marginTop="10px"
            positionItemVertical="flex-end"
            width="100%"
        >
            <Button
                style={adjustButtonLogo}
                title={value}
            >
                <Logo src={defaultSound} />
                {value ? (
                    <ContainerAdjustIconEdit right="17px" style={adjustMarginBottom}>
                        <CheckCircle color="inherit" />
                    </ContainerAdjustIconEdit>
                ) : null}
                <InputNullSelectedPhoto
                    accept=".mp3,audio/*"
                    onChange={onChange}
                    type="file"
                />
            </Button>
            <Button
                style={adjustButtonLogo}
                title={dataFile}
            >
                <Logo src={dataFile || imgDefault} />
                <ContainerAdjustIconEdit right="5px">
                    <Edit color="primary" />
                </ContainerAdjustIconEdit>
                <InputNullSelectedPhoto
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={SelectedPhotoTarget}
                    type="file"
                />
            </Button>
        </ContainerOptions>
    );

    const RenderInputsBody = () => {
        if (taskConnectWordToImages.options?.length) {
            return taskConnectWordToImages.options.map((item: any) => {
                const [getOneOption] = taskConnectWordToImages.options.filter(
                    (v: any) => v.id === item.id,
                );
                const removeOneOption = taskConnectWordToImages.options.filter(
                    (v: any) => v.id !== item.id,
                );
                const onChangeOption = async (event: any) => {
                    if (getOneOption.id === item.id) {
                        const newDataFiles = {
                            eventFile: event.target.files[0],
                            eventName: event.target.files[0].name,
                            file: URL.createObjectURL(event.target.files[0]),
                        };

                        const upload: any = await uploads.UploadFile(newDataFiles.eventFile);
                        if (upload) {
                            const optionModified = {
                                fileAudio: upload.result,
                                file: getOneOption.file,
                                id: getOneOption.id,
                            };
                            const newDataOption = [...removeOneOption, optionModified];
                            const orderNewDataOption = newDataOption.sort((a, b) => {
                                if (a.id < b.id) return -1;
                                if (a.id > b.id) return 1;
                                return 0;
                            });
                            setTaskConnectWordToImages({
                                ...taskConnectWordToImages,
                                options: orderNewDataOption,
                            });
                            // eslint-disable-next-line no-param-reassign
                            event.target.value = null;
                        }
                    }
                };

                const SelectedPhoto = async (event: any) => {
                    if (event.target.files.length) {
                        const newDataFiles = {
                            eventFile: event.target.files[0],
                            eventName: event.target.files[0].name,
                            file: URL.createObjectURL(event.target.files[0]),
                        };

                        const upload: any = await uploads.UploadFile(newDataFiles.eventFile);
                        if (upload && getOneOption.id === item.id) {
                            const optionModified = {
                                fileAudio: getOneOption.fileAudio,
                                file: upload.result,
                                id: getOneOption.id,
                            };
                            const newDataOption = [...removeOneOption, optionModified];
                            const orderNewDataOption = newDataOption.sort((a, b) => {
                                if (a.id < b.id) return -1;
                                if (a.id > b.id) return 1;
                                return 0;
                            });
                            setTaskConnectWordToImages({
                                ...taskConnectWordToImages,
                                options: orderNewDataOption,
                            });
                            // eslint-disable-next-line no-param-reassign
                            event.target.value = null;
                        }
                    }
                };

                return (
                    <ContainerOptions>
                        {RenderInputsOptions(
                            item?.file,
                            onChangeOption,
                            SelectedPhoto,
                            item?.fileAudio,
                        )}
                    </ContainerOptions>
                );
            });
        }

        return null;
    };

    const RenderSelectedSkipTask = () => {
        const optionsFileAudio = taskConnectWordToImages.options.filter((v: any) => v.fileAudio).length;
        const optionsFile = taskConnectWordToImages.options.filter((v: any) => v.file).length;
        const allOptions = optionsFileAudio + optionsFile === taskConnectWordToImages.target * 2;

        if (optionsFileAudio + optionsFile > 0 && allOptions) {
            return (
                <FormControl style={adjustWidthSelectedActivities}>
                    <InputLabel>Pular/Não pular</InputLabel>
                    <Select value={taskConnectWordToImages.skip} onChange={onChangeOptionSkipTask}>
                        <MenuItem value="true">Pular</MenuItem>
                        <MenuItem value="false">Não pular</MenuItem>
                    </Select>
                </FormControl>
            );
        }

        return null;
    };

    const RenderInputsTitles = (label: string, value: string, onChange: any) => {
        const ValueActual = value;
        return (
            <>
                <ContainerOptions marginTop="10px" />
                <TextField
                    fullWidth
                    label={`${label} (${ValueActual.length}/200)`}
                    onChange={onChange}
                    value={value}
                />
            </>
        );
    };

    const RenderAllItems = () => {
        if (allMenuItems.ids.length) {
            return allMenuItems.ids.map((idSelected: any) => (
                <MenuItem value={idSelected}>{idSelected}</MenuItem>
            ));
        }
        return null;
    };

    const EndIcon = () => (
        <Send />
    );

    const RenderIconGoBack = () => (
        <NavigateBefore />
    );

    return (
        <>
            <ContainerOptions>
                <TitleOption width="100%">Criação da Atividade - Associe a palavra às imagens</TitleOption>
            </ContainerOptions>
            {RenderInputsTitles('Título da atividade', taskConnectWordToImages.title, onChangeTitle)}
            {RenderInputsTitles('Subtítulo da atividade', taskConnectWordToImages.subtitle, onChangeSubtitle)}
            <FormControl style={adjustWidthSelectedActivities}>
                <InputLabel>Quantidade</InputLabel>
                <Select value={taskConnectWordToImages.target} onChange={onChangeTarget}>
                    {RenderAllItems()}
                </Select>
            </FormControl>
            {RenderInputsBody()}
            {RenderSelectedSkipTask()}
            <ContainerOptions marginTop="40px">
                <Button
                    color="primary"
                    startIcon={RenderIconGoBack()}
                    onClick={BackScreenModal}
                    variant="contained"
                >
                    Voltar
                </Button>
                <Button
                    color={validationBackground()}
                    disabled={validationDisabled()}
                    endIcon={EndIcon()}
                    onClick={CreateTask}
                    variant="contained"
                >
                    {taskConnectWordToImages?.id ? 'Editar Atividade' : 'Criar Atividade'}
                </Button>
            </ContainerOptions>
        </>
    );
};

export default TaskConnectWordToImages;
