/* eslint-disable max-len */
/* eslint-disable no-plusplus */
// libraries
import React from 'react';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { CheckCircle, NavigateBefore, Send } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';

// js
import defaultSound from '../../../assets/images/defaultSound.png';
import { allMenuItems } from './taskConstants';
import {
    ContainerAdjustIconEdit,
    ContainerOptions,
    InputNullSelectedPhoto,
    Logo,
    TitleOption,
} from '../modulesStyles';

// interfaces
import type { ConnectPhrases, DataModuleInterface } from '../modulesInterfaces';
import uploads from '../../../services/uploads';
import tasks from '../../../services/tasks';

const TaskConnectPhrases = (
    setIdModal: any,
    initialDataTaskConnectPhrases: ConnectPhrases,
    taskConnectPhrases: ConnectPhrases,
    setTaskConnectPhrases: any,
    dataModule: DataModuleInterface,
) => {
    // constants
    const token: any = String(localStorage.getItem('token') || '');
    const adjustMarginBottom = { marginBottom: 10 };
    const adjustButtonLogo = { marginBottom: 25, width: 200 };
    const adjustWidthSelectedActivities = { width: 200 };
    const marginDefault = { marginRight: 10 };

    // methods
    const BackScreenModal = () => {
        setIdModal(1);
        setTaskConnectPhrases(initialDataTaskConnectPhrases);
    };

    const CreateTask = async () => {
        const formatOptions = taskConnectPhrases?.options?.map((e: any) => {
            const option = e;
            delete option.idWrapperTask;
            return option;
        });
        const formatTask: any = {
            ...taskConnectPhrases,
            options: formatOptions,
            idTask: taskConnectPhrases.id,
            idModule: dataModule?.id,
        };
        delete formatTask?.id;
        if (formatTask?.idTask) {
            const editTask: any = await tasks.EditTask(formatTask, token);
            if (editTask) {
                BackScreenModal();
            }
        } else {
            delete formatTask?.idTask;
            const createTask: any = await tasks.CreateTask(formatTask, token);
            if (createTask) {
                BackScreenModal();
            }
        }
    };

    const onChangeTitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskConnectPhrases, title: txt.target.value };
            setTaskConnectPhrases(newDataTask);
        }
    };

    const onChangeSubtitle = (txt: any) => {
        if (txt.target.value.length <= 200) {
            const newDataTask = { ...taskConnectPhrases, subtitle: txt.target.value };
            setTaskConnectPhrases(newDataTask);
        }
    };

    const onChangeOptionSkipTask = (txt: any) => {
        const newDataTask = { ...taskConnectPhrases, skip: txt.target.value };
        setTaskConnectPhrases(newDataTask);
    };

    const validationDisabled = () => {
        let valueObject = true;
        const ValidationOptionsLength = taskConnectPhrases?.options.filter((v: any) => v.optionEnglish && v.optionPortuguese && v.file).length === taskConnectPhrases?.options?.length;
        if (taskConnectPhrases?.id) {
            const ValidationLength = Object.values(taskConnectPhrases).filter((v) => v !== '').length !== 7;
            if (!ValidationLength && ValidationOptionsLength) {
                valueObject = false;
            }
        } else if (!taskConnectPhrases?.id) {
            const ValidationLength = Object.values(taskConnectPhrases).filter((v) => v !== '').length !== 6;
            if (!ValidationLength && ValidationOptionsLength) {
                valueObject = false;
            }
        }
        return valueObject;
    };

    const validationBackground = () => {
        const valueObject = Object.values(taskConnectPhrases).filter((v) => v !== '');
        const ValidationOptionsLength = taskConnectPhrases?.options.filter((v: any) => v.optionEnglish && v.optionPortuguese && v.file).length === taskConnectPhrases?.options?.length;
        if ((taskConnectPhrases?.id ? valueObject.length === 7 : valueObject.length === 6) && ValidationOptionsLength) {
            return 'primary';
        }

        return 'inherit';
    };

    const onChangeTarget = (txt: any) => {
        const allOptions = [];
        for (let i = 0; i < Number(txt.target.value); i++) {
            const objOrigin = {
                optionEnglish: '',
                optionPortuguese: '',
                id: uuidv4(),
                file: '',
            };
            allOptions.push(objOrigin);
            if (allOptions.length === Number(txt.target.value)) {
                const orderNewDataOption = allOptions.sort((a, b) => {
                    if (a.id < b.id) return -1;
                    if (a.id > b.id) return 1;
                    return 0;
                });
                const newDataTask = {
                    ...taskConnectPhrases,
                    target: Number(txt.target.value),
                    options: orderNewDataOption,
                };
                setTaskConnectPhrases(newDataTask);
            }
        }
    };

    // renders
    const RenderInputsOptions = (label: string, value: string, onChange: any) => {
        const ValueActual = value;
        return (
            <TextField
                fullWidth
                label={`${label} (${ValueActual.length}/200)`}
                onChange={onChange}
                style={marginDefault}
                value={value}
            />
        );
    };

    const RenderInputsBody = () => {
        if (taskConnectPhrases.options?.length) {
            return taskConnectPhrases.options.map((item: any, index: number) => {
                const indexFormat = index + 1;
                const indexZero = indexFormat < 10 ? 0 : '';
                const [getOneOption] = taskConnectPhrases.options.filter(
                    (v: any) => v.id === item.id,
                );
                const removeOneOption = taskConnectPhrases.options.filter(
                    (v: any) => v.id !== item.id,
                );

                const onChangeOptionPortuguese = (txt: any) => {
                    if (txt.target.value.length <= 200) {
                        if (getOneOption.id === item.id) {
                            const optionModified = {
                                optionEnglish: getOneOption.optionEnglish,
                                optionPortuguese: txt.target.value,
                                id: getOneOption.id,
                                file: getOneOption.file,
                            };
                            const newDataOption = [...removeOneOption, optionModified];
                            const orderNewDataOption = newDataOption.sort((a, b) => {
                                if (a.id < b.id) return -1;
                                if (a.id > b.id) return 1;
                                return 0;
                            });
                            setTaskConnectPhrases({
                                ...taskConnectPhrases,
                                options: orderNewDataOption,
                            });
                        }
                    }
                };

                const onChangeOptionEnglish = (txt: any) => {
                    if (txt.target.value.length <= 200) {
                        if (getOneOption.id === item.id) {
                            const optionModified = {
                                optionEnglish: txt.target.value,
                                optionPortuguese: getOneOption.optionPortuguese,
                                id: getOneOption.id,
                                file: getOneOption.file,
                            };
                            const newDataOption = [...removeOneOption, optionModified];
                            const orderNewDataOption = newDataOption.sort((a, b) => {
                                if (a.id < b.id) return -1;
                                if (a.id > b.id) return 1;
                                return 0;
                            });
                            setTaskConnectPhrases({
                                ...taskConnectPhrases,
                                options: orderNewDataOption,
                            });
                        }
                    }
                };

                const SelectedPhoto = async (event: any) => {
                    if (event.target.files.length) {
                        const newDataFiles = {
                            eventFile: event.target.files[0],
                            eventName: event.target.files[0].name,
                            file: URL.createObjectURL(event.target.files[0]),
                        };

                        const upload: any = await uploads.UploadFile(newDataFiles.eventFile);
                        if (upload) {
                            const optionModified = {
                                optionEnglish: getOneOption.optionEnglish,
                                optionPortuguese: getOneOption.optionPortuguese,
                                id: getOneOption.id,
                                file: upload.result,
                            };
                            const newDataOption = [...removeOneOption, optionModified];
                            const orderNewDataOption = newDataOption.sort((a, b) => {
                                if (a.id < b.id) return -1;
                                if (a.id > b.id) return 1;
                                return 0;
                            });
                            setTaskConnectPhrases({
                                ...taskConnectPhrases,
                                options: orderNewDataOption,
                            });
                            // eslint-disable-next-line no-param-reassign
                            event.target.value = null;
                        }
                    }
                };

                return (
                    <ContainerOptions marginTop="10px">
                        {RenderInputsOptions(`Opção ${indexZero}${indexFormat} português`, item.optionPortuguese, onChangeOptionPortuguese)}
                        {RenderInputsOptions(`Opção ${indexZero}${indexFormat} inglês`, item.optionEnglish, onChangeOptionEnglish)}
                        <Button
                            style={adjustButtonLogo}
                            title={item?.file}
                        >
                            <Logo src={defaultSound} />
                            {item?.file ? (
                                <ContainerAdjustIconEdit right="7px" style={adjustMarginBottom}>
                                    <CheckCircle color="inherit" />
                                </ContainerAdjustIconEdit>
                            ) : null}
                            <InputNullSelectedPhoto
                                accept=".mp3,audio/*"
                                onChange={SelectedPhoto}
                                type="file"
                            />
                        </Button>
                    </ContainerOptions>
                );
            });
        }

        return null;
    };

    const RenderSelectedSkipTask = () => {
        const optionsEnglish = taskConnectPhrases.options.filter((v: any) => v.optionEnglish !== '').length;
        const optionsPortuguese = taskConnectPhrases.options.filter((v: any) => v.optionPortuguese !== '').length;
        const optionsFile = taskConnectPhrases.options.filter((v: any) => v.file).length;
        const allOptionsConnectPhrases = optionsEnglish + optionsPortuguese + optionsFile;
        const allOptions = allOptionsConnectPhrases === taskConnectPhrases.target * 3;

        if (optionsEnglish + optionsPortuguese > 0 && allOptions) {
            return (
                <FormControl style={adjustWidthSelectedActivities}>
                    <InputLabel>Pular/Não pular</InputLabel>
                    <Select value={taskConnectPhrases.skip} onChange={onChangeOptionSkipTask}>
                        <MenuItem value="true">Pular</MenuItem>
                        <MenuItem value="false">Não pular</MenuItem>
                    </Select>
                </FormControl>
            );
        }

        return null;
    };

    const RenderAllItems = () => {
        if (allMenuItems.ids.length) {
            return allMenuItems.ids.map((idSelected: any) => (
                <MenuItem value={idSelected}>{idSelected}</MenuItem>
            ));
        }
        return null;
    };

    const RenderInputsTitles = (label: string, value: string, onChange: any) => {
        const ValueActual = value;
        return (
            <>
                <ContainerOptions marginTop="10px" />
                <TextField
                    fullWidth
                    label={`${label} (${ValueActual.length}/200)`}
                    onChange={onChange}
                    value={value}
                />
            </>
        );
    };

    const EndIcon = () => (
        <Send />
    );

    const RenderIconGoBack = () => (
        <NavigateBefore />
    );

    return (
        <>
            <ContainerOptions>
                <TitleOption width="100%">Criação da Atividade - Ligue as sentenças</TitleOption>
            </ContainerOptions>
            {RenderInputsTitles('Título da atividade', taskConnectPhrases.title, onChangeTitle)}
            {RenderInputsTitles('Subtítulo da atividade', taskConnectPhrases.subtitle, onChangeSubtitle)}
            <FormControl style={adjustWidthSelectedActivities}>
                <InputLabel>Quantidade</InputLabel>
                <Select value={taskConnectPhrases.target} onChange={onChangeTarget}>
                    {RenderAllItems()}
                </Select>
            </FormControl>
            {RenderInputsBody()}
            {RenderSelectedSkipTask()}
            <ContainerOptions marginTop="40px">
                <Button
                    color="primary"
                    startIcon={RenderIconGoBack()}
                    onClick={BackScreenModal}
                    variant="contained"
                >
                    Voltar
                </Button>
                <Button
                    color={validationBackground()}
                    disabled={validationDisabled()}
                    endIcon={EndIcon()}
                    onClick={CreateTask}
                    variant="contained"
                >
                    {taskConnectPhrases?.id ? 'Editar Atividade' : 'Criar Atividade'}
                </Button>
            </ContainerOptions>
        </>
    );
};

export default TaskConnectPhrases;
