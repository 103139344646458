/* eslint-disable no-alert */
// js
import api from './api';

async function ListFinance(
    dateStart: number | null,
    dateEnd: number | null,
    token: string,
): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        let baseURL = `/plans/historic?applicationType=${nameBank}`;
        if (dateStart) {
            baseURL = `${baseURL}&dateStart=${dateStart}`;
        }
        if (dateEnd) {
            baseURL = `${baseURL}&dateEnd=${dateEnd}`;
        }
        const response = await api.get(baseURL, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

export default {
    ListFinance,
};
