import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ContainerPaginator = styled.div`
    display: flex;
    margin-top: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const ButtonPlayer = styled.button`
    background-color: transparent;
    border: none;
    margin-right: 10px;
    &:hover {
        cursor: pointer;
    }
`;

export const ActionsContainer = styled.div``;
