// libraries
import React, { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';

// js
import contants from '../../configs/constants';
import {
    BoxInputs,
    ButtonAction,
    CheckBox,
    Container,
    ContainerCheckBox,
    InputAuth,
    Title,
    TitleInputAuth,
    TitleRemember,
    ImageView,
    InputIconview,
} from './authStyles';
import auth from '../../services/auth';

// Images
import viewOn from '../../assets/images/viewOn.svg';
import viewOff from '../../assets/images/viewOff.svg';
import envelope from '../../assets/images/envelope.svg';

// components (navigation)
import ToNavigation from '../../routes/navigation';

// components
import ModalLoading from '../../components/ModalLoading';

// interfaces
interface FormUserInterface {
    email: string,
    password: string,
}

const AuthPage: React.FC = () => {
    // consts
    const adjustAlert = { alignSelf: 'flex-start', marginTop: 10 };
    const initialFormUser = {
        email: '',
        password: '',
    };

    const [focusPassword, setFocusPassword] = useState(false);
    const [activeCheckBox, setActiveCheckBox] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [activeLoading, setActiveLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [dataUser, setDataUser] = useState<any>(null);
    const [code, setCode] = useState('');

    const [formUser, setFormUser] = useState<FormUserInterface>(initialFormUser);
    const [verifyButton, setVerifyButton] = useState(false);
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // useEffect
    useEffect(() => {
        const validatePassword = formUser.password.length >= 6;
        const validateEmail = re.test(String(formUser.email).toLowerCase());
        const validateButton = validatePassword && validateEmail;
        setVerifyButton(validateButton);
    }, [formUser]);

    useEffect(() => {
        const emailLocalStorage = localStorage.getItem('email') || '';
        if (emailLocalStorage) {
            setFormUser({ ...formUser, email: emailLocalStorage });
            setActiveCheckBox(true);
        }
    }, []);

    // methods
    const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormUser({ ...formUser, email: e.target.value });
    };

    const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormUser({ ...formUser, password: e.target.value });
    };

    const onChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
    };

    const SendCode = async () => {
        if (formUser.email && formUser.password) {
            setActiveLoading(true);
            const sign = await auth.Login(formUser.email, formUser.password, 'adm');
            setActiveLoading(false);
            if (sign) {
                setDataUser(sign);
            }
        } else {
            setMessageAlert('Preencha todos os campos.');
        }
    };

    const Login = async () => {
        setActiveLoading(true);
        const response = await auth.EmitCode(code, dataUser.result.idUser);
        setActiveLoading(false);
        if (response) {
            if (activeCheckBox) {
                localStorage.setItem('email', dataUser.result.email);
            } else {
                localStorage.removeItem('email');
            }
            localStorage.setItem('emailBank', dataUser.result.email);
            localStorage.setItem('nameBank', dataUser.result.applicationType);
            localStorage.setItem('token', dataUser.result.token);
            localStorage.setItem('tokenRefresh', dataUser.result.tokenRefresh);
            localStorage.setItem('idUser', dataUser.result.idUser);
            localStorage.setItem('existUser', 'true');
            setRedirect(true);
        }
    };

    const EnabledFocusEmail = () => {
        setFocusPassword(false);
    };

    const EnabledFocusPassword = () => {
        setFocusPassword(true);
    };

    const KeyUpInputs = (value: React.KeyboardEvent) => {
        const { key } = value;
        if (key === 'Enter' && !focusPassword) {
            return setFocusPassword(true);
        }
        if (key === 'Enter' && focusPassword) {
            return SendCode();
        }
        return null;
    };

    const [verification, setVerification] = useState(false);

    const ViewPassword = () => {
        setVerification(!verification);
    };
    // renders
    return (
        <Container>
            <ToNavigation pathRoute="/category" redirect={redirect} />
            <ModalLoading activeLoading={activeLoading} />
            {dataUser ? (
                <BoxInputs>
                    <Title>Confirme o código</Title>
                    <TitleInputAuth>Código</TitleInputAuth>
                    <InputIconview>
                        <InputAuth
                            id="code"
                            autoFocus
                            onChange={onChangeCode}
                            placeholder="Digite o código"
                            type="number"
                            value={code}
                        />
                        {/* <ImageView src={envelope} /> */}
                    </InputIconview>
                    <ButtonAction
                        onClick={Login}
                        type="button"
                        backgroundColor={verifyButton}
                        enabledPointer={verifyButton}
                    >
                        Entrar
                    </ButtonAction>
                </BoxInputs>
            ) : (
                <BoxInputs>
                    <Title>{`Login (${contants.nameEnvironment})`}</Title>
                    <TitleInputAuth>E-mail</TitleInputAuth>
                    <InputIconview>
                        <InputAuth
                            id="email"
                            autoFocus
                            onChange={onChangeEmail}
                            placeholder="Digite o e-mail"
                            type="email"
                            value={formUser.email}
                            onFocus={EnabledFocusEmail}
                            onKeyUp={KeyUpInputs}
                        />
                        <ImageView src={envelope} />
                    </InputIconview>
                    <TitleInputAuth>Senha</TitleInputAuth>
                    <InputIconview>
                        <InputAuth
                            id="password"
                            onChange={onChangePassword}
                            placeholder="Digite a senha"
                            type={!verification ? 'password' : 'text'}
                            value={formUser.password}
                            onFocus={EnabledFocusPassword}
                            onKeyUp={KeyUpInputs}
                        />
                        <ImageView onClick={ViewPassword} src={!verification ? viewOff : viewOn} />
                    </InputIconview>
                    {messageAlert !== '' ? (
                        <Alert
                            action={messageAlert}
                            color="error"
                            severity="error"
                            style={adjustAlert}
                        />
                    ) : null}
                    <ContainerCheckBox onClick={() => setActiveCheckBox(!activeCheckBox)}>
                        <CheckBox type="checkbox" checked={activeCheckBox} />
                        <TitleRemember>Lembre de mim</TitleRemember>
                    </ContainerCheckBox>
                    <ButtonAction
                        onClick={SendCode}
                        type="button"
                        backgroundColor={verifyButton}
                        enabledPointer={verifyButton}
                    >
                        Entrar
                    </ButtonAction>
                </BoxInputs>
            )}
        </Container>
    );
};

export default AuthPage;
