/* eslint-disable no-alert */
// js
import api from './api';

async function ListHistories(search: string, token: string): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const baseURL = `/change/histories?applicationType=${nameBank}${
            search && `&searchTxt=${search}`
        }`;
        const response = await api.get(baseURL, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

async function ReverseCondition(
    id: string,
    type: string,
    token: string,
): Promise<void> {
    try {
        const nameBank = localStorage.getItem('nameBank');
        const configs = {
            headers: {
                'x-access-token': token,
            },
        };
        const baseURL = `/change/histories/restore?applicationType=${nameBank}&idHistory=${id}&type=${type}`;
        const response = await api.post(baseURL, {}, configs);
        return response.data;
    } catch (error: any) {
        alert(error.response.data?.message || 'Erro de servidor');
    }
}

export { ListHistories, ReverseCondition };
